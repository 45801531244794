import { toast } from "react-toastify";
import {
  AGENCE_LIST_REQUEST,
  AGENCE_LIST_SUCCESS,
  AGENCE_LIST_FAIL,
  //
  AGENCE_ADD_REQUEST,
  AGENCE_ADD_SUCCESS,
  AGENCE_ADD_FAIL,
  //
  AGENCE_DETAIL_REQUEST,
  AGENCE_DETAIL_SUCCESS,
  AGENCE_DETAIL_FAIL,
  //
  AGENCE_UPDATE_REQUEST,
  AGENCE_UPDATE_SUCCESS,
  AGENCE_UPDATE_FAIL,
  //
  AGENCE_DELETE_REQUEST,
  AGENCE_DELETE_SUCCESS,
  AGENCE_DELETE_FAIL,
  //
} from "../constants/agenceConstants";

export const deleteAgenceReducer = (state = {}, action) => {
  switch (action.type) {
    case AGENCE_DELETE_REQUEST:
      return { loadingAgenceDelete: true };
    case AGENCE_DELETE_SUCCESS:
      toast.success("Cette Agence a été supprimer avec succès");
      return {
        loadingAgenceDelete: false,
        successAgenceDelete: true,
      };
    case AGENCE_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingAgenceDelete: false,
        successAgenceDelete: false,
        errorAgenceDelete: action.payload,
      };
    default:
      return state;
  }
};

export const updateAgenceReducer = (state = {}, action) => {
  switch (action.type) {
    case AGENCE_UPDATE_REQUEST:
      return { loadingAgenceUpdate: true };
    case AGENCE_UPDATE_SUCCESS:
      toast.success("Cette Agence a été modifé avec succès");
      return {
        loadingAgenceUpdate: false,
        successAgenceUpdate: true,
      };
    case AGENCE_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingAgenceUpdate: false,
        successAgenceUpdate: false,
        errorAgenceUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const getDetailAgenceReducer = (state = { agence: {} }, action) => {
  switch (action.type) {
    case AGENCE_DETAIL_REQUEST:
      return { loadingAgenceDetail: true };
    case AGENCE_DETAIL_SUCCESS:
      return {
        loadingAgenceDetail: false,
        successAgenceDetail: true,
        agence: action.payload,
      };
    case AGENCE_DETAIL_FAIL:
      return {
        loadingAgenceDetail: false,
        successAgenceDetail: false,
        errorAgenceDetail: action.payload,
      };
    default:
      return state;
  }
};

export const createNewAgenceReducer = (state = {}, action) => {
  switch (action.type) {
    case AGENCE_ADD_REQUEST:
      return { loadingAgenceAdd: true };
    case AGENCE_ADD_SUCCESS:
      toast.success("Cette Agence a été ajouté avec succès");
      return {
        loadingAgenceAdd: false,
        successAgenceAdd: true,
      };
    case AGENCE_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingAgenceAdd: false,
        successAgenceAdd: false,
        errorAgenceAdd: action.payload,
      };
    default:
      return state;
  }
};

export const agenceListReducer = (state = { agences: [] }, action) => {
  switch (action.type) {
    case AGENCE_LIST_REQUEST:
      return { loading: true, agences: [] };
    case AGENCE_LIST_SUCCESS:
      return {
        loading: false,
        agences: action.payload.agences,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case AGENCE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
