import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DefaultLayout from "../../../layouts/DefaultLayout";
import Loader from "../../../components/Loader";
import Alert from "../../../components/Alert";
import {
  deleteDepenseEntretien,
  getListDepenseEntretiens,
} from "../../../redux/actions/designationActions";
import Paginate from "../../../components/Paginate";
import ConfirmationModal from "../../../components/ConfirmationModal";

function DepenseEntretienScreen() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";

  const [depenseId, setDepenseId] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");

  const depenseEntretienDelete = useSelector(
    (state) => state.deleteDepenseEntretien
  );
  const {
    loadingDepenseEntretienDelete,
    errorDepenseEntretienDelete,
    successDepenseEntretienDelete,
  } = depenseEntretienDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listDepenseEntretien = useSelector(
    (state) => state.depenseEntretienList
  );
  const {
    depenseEntretiens,
    loadingDepenseEntretien,
    errorDepenseEntretien,
    successDepenseEntretien,
    pages,
  } = listDepenseEntretien;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListDepenseEntretiens(page));
    }
  }, [navigate, userInfo, page]);

  useEffect(() => {
    if (successDepenseEntretienDelete) {
      dispatch(getListDepenseEntretiens(1));
      setDepenseId("");
      setLoadEvent(false);
      setEventType("");
      setIsDelete(false);
    }
  }, [successDepenseEntretienDelete]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Dépenses</div>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Entretiens</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default  dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black ">
              Gestion des Entretien
            </h4>
            <Link
              to={"/depenses/entretiens/add"}
              className="rounded bg-primary text-white px-5 py-1 flex flex-row text-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Ajouter
            </Link>
          </div>
          {/* search */}
          <div className="flex md:flex-row flex-col">
            <div className="md:py-2 md:flex">
              {/* <InputModel
                label="Filter"
                type="select"
                value={status}
                onChange={async (v) => {
                  setStatus(v.target.value);
                  await dispatch(getEmployesList(status, page)).then(() => {});
                }}
                options={[
                  { value: "all", label: "Tous" },
                  { value: "active", label: "Actif" },
                  { value: "reactive", label: "Archivé" },
                ]}
              /> */}
            </div>
          </div>
          {/* list */}
          {loadingDepenseEntretien ? (
            <Loader />
          ) : errorDepenseEntretien ? (
            <Alert type="error" message={errorDepenseEntretien} />
          ) : (
            <div className="max-w-full overflow-x-auto mt-3">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left dark:bg-meta-4">
                    <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                      N°
                    </th>
                    <th className="min-w-[100px] py-4 px-4 font-bold text-black text-xs w-max ">
                      Voiture
                    </th>
                    <th className="min-w-[100px] py-4 px-4 font-bold text-black text-xs w-max ">
                      Entretien
                    </th>
                    <th className="min-w-[100px] py-4 px-4 font-bold text-black text-xs w-max ">
                      Sous Entretien
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max ">
                      Date
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max ">
                      Montant
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max ">
                      Numéro reglement
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max ">
                      Remarque
                    </th>
                    <th className="py-4 px-4 font-bold text-black text-xs w-max ">
                      Actions
                    </th>
                  </tr>
                </thead>
                {/*  */}
                <tbody>
                  {depenseEntretiens?.map((depenseEntretien, index) => (
                    <tr>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black text-xs w-max ">
                          {depenseEntretien.id}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black text-xs w-max ">
                          {depenseEntretien.car?.marque?.marque_car} -{" "}
                          {depenseEntretien.car?.matricule}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black text-xs w-max ">
                          {depenseEntretien.entretien?.entretien_name ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black text-xs w-max "></p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black text-xs w-max ">
                          {depenseEntretien.date ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black text-xs w-max ">
                          {parseFloat(depenseEntretien.total_amount).toFixed(
                            2
                          ) ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black text-xs w-max ">
                          {depenseEntretien.number_reglement ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black text-xs w-max ">
                          {depenseEntretien.note ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max flex flex-row">
                          {/* delete */}
                          <button
                            className="mx-1 delete-class"
                            onClick={() => {
                              setEventType("delete");
                              setDepenseId(depenseEntretien.id);
                              setIsDelete(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                          {/* edit */}
                          <Link
                            className="mx-1 update-class"
                            to={
                              "/depenses/entretiens/edit/" + depenseEntretien.id
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </Link>
                        </p>
                      </td>
                    </tr>
                  ))}
                  <tr className="h-11"></tr>
                </tbody>
              </table>
              <div className="">
                <Paginate
                  route={"/depenses/entretiens?"}
                  search={""}
                  page={page}
                  pages={pages}
                />
              </div>
            </div>
          )}
        </div>
        <ConfirmationModal
          isOpen={isDelete}
          message={
            eventType === "delete"
              ? "Êtes-vous sûr de vouloir supprimer cette entretien ?"
              : "Êtes-vous sûr de vouloir ?"
          }
          onConfirm={async () => {
            if (eventType === "delete" && depenseId !== "") {
              setLoadEvent(true);
              dispatch(deleteDepenseEntretien(depenseId));
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            }
          }}
          onCancel={() => {
            setIsDelete(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default DepenseEntretienScreen;
