import "./App.css";
import "./axios.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginScreen from "./screens/auth/LoginScreen";
import LogoutScreen from "./screens/auth/LogoutScreen.js";
import DashboardScreen from "./screens/dashboard/DashboardScreen.js";
import ClientScreen from "./screens/client/ClientScreen.js";
import AddClientScreen from "./screens/client/AddClientScreen.js";
import EditClientScreen from "./screens/client/EditClientScreen.js";
import MarquesModelsScreen from "./screens/settings/marques-models/MarquesModelsScreen.js";
import EmployesScreen from "./screens/settings/employes/EmployesScreen.js";
import AddEmployeScreen from "./screens/settings/employes/AddEmployeScreen.js";
import EditEmployeScreen from "./screens/settings/employes/EditEmployeScreen.js";
import UserScreen from "./screens/settings/users/UserScreen.js";
import AddUserScreen from "./screens/settings/users/AddUserScreen.js";
import EditUserScreen from "./screens/settings/users/EditUserScreen.js";
import AgenceScreen from "./screens/agences/AgenceScreen.js";
import AddAgenceScreen from "./screens/agences/AddAgenceScreen.js";
import EditAgenceScreen from "./screens/agences/EditAgenceScreen.js";
import CarScreen from "./screens/car/CarScreen.js";
import AddCarScreen from "./screens/car/AddCarScreen.js";
import EditCarScreen from "./screens/car/EditCarScreen.js";
import ReservationScreen from "./screens/reservation/ReservationScreen.js";
import AddReservationScreen from "./screens/reservation/AddReservationScreen.js";
import EditReservationScreen from "./screens/reservation/EditReservationScreen.js";
import ContratScreen from "./screens/contrats/ContratScreen.js";
import AddContratScreen from "./screens/contrats/AddContratScreen.js";
import EditContratScreen from "./screens/contrats/EditContratScreen.js";
import DesignationScreen from "./screens/settings/designations/DesignationScreen.js";
import DepenseChargeScreen from "./screens/depenses/charges/DepenseChargeScreen.js";
import AddDepenseChargeScreen from "./screens/depenses/charges/AddDepenseChargeScreen.js";
import EditDepenseChargeScreen from "./screens/depenses/charges/EditDepenseChargeScreen.js";
import DepenseEntretienScreen from "./screens/depenses/entretiens/DepenseEntretienScreen.js";
import AddDepenseEntretienScreen from "./screens/depenses/entretiens/AddDepenseEntretienScreen.js";
import EditDepenseEntretienScreen from "./screens/depenses/entretiens/EditDepenseEntretienScreen.js";
import DepenseEmployeScreen from "./screens/depenses/employes/DepenseEmployeScreen.js";
import AddDepenseEmployeScreen from "./screens/depenses/employes/AddDepenseEmployeScreen.js";
import EditDepenseEmployeScreen from "./screens/depenses/employes/EditDepenseEmployeScreen.js";
import ProfileScreen from "./screens/profile/ProfileScreen.js";
import ContratClientScreen from "./screens/client/ContratClientScreen.js";
import PaymentContratScreen from "./screens/contrats/payment/PaymentContratScreen.js";
import AddPaymentContratScreen from "./screens/contrats/payment/AddPaymentContratScreen.js";
import EditPaymentContratScreen from "./screens/contrats/payment/EditPaymentContratScreen.js";
import FactureScreen from "./screens/factures/FactureScreen.js";
import AddReturnContratScreen from "./screens/contrats/return/AddReturnContratScreen.js";
import SearchContratScreen from "./screens/contrats/SearchContratScreen.js";
import RaportScreen from "./screens/raport/RaportScreen.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginScreen />,
  },
  {
    path: "/dashboard",
    element: <DashboardScreen />,
  },
  // Rapport
  {
    path: "/rapports",
    element: <RaportScreen />,
  },

  // client
  {
    path: "/clients",
    element: <ClientScreen />,
  },
  {
    path: "/clients/add",
    element: <AddClientScreen />,
  },
  {
    path: "/clients/edit/:id",
    element: <EditClientScreen />,
  },
  {
    path: "/clients/contrat/:id",
    element: <ContratClientScreen />,
  },
  // marque and modele
  {
    path: "/settings/marques-modeles",
    element: <MarquesModelsScreen />,
  },
  // employes
  {
    path: "/settings/employes",
    element: <EmployesScreen />,
  },
  {
    path: "/settings/employes/add",
    element: <AddEmployeScreen />,
  },
  {
    path: "/settings/employes/edit/:id",
    element: <EditEmployeScreen />,
  },
  // users
  {
    path: "/settings/users",
    element: <UserScreen />,
  },
  {
    path: "/settings/users/add",
    element: <AddUserScreen />,
  },
  {
    path: "/settings/users/edit/:id",
    element: <EditUserScreen />,
  },
  // designation
  {
    path: "/settings/designations",
    element: <DesignationScreen />,
  },
  // agence
  {
    path: "/agences",
    element: <AgenceScreen />,
  },
  {
    path: "/agences/add",
    element: <AddAgenceScreen />,
  },
  {
    path: "/agences/edit/:id",
    element: <EditAgenceScreen />,
  },
  // car
  {
    path: "/cars",
    element: <CarScreen />,
  },
  {
    path: "/cars/add",
    element: <AddCarScreen />,
  },
  {
    path: "/cars/edit/:id",
    element: <EditCarScreen />,
  },
  //
  {
    path: "/reservations",
    element: <ReservationScreen />,
  },
  {
    path: "/reservations/add",
    element: <AddReservationScreen />,
  },
  {
    path: "/reservations/edit/:id",
    element: <EditReservationScreen />,
  },
  // contrat
  {
    path: "/contrats",
    element: <ContratScreen />,
  },
  {
    path: "/contrats/add",
    element: <AddContratScreen />,
  },
  {
    path: "/contrats/edit/:id",
    element: <EditContratScreen />,
  },
  {
    path: "/factures",
    element: <FactureScreen />,
  },

  // pyment contrat
  {
    path: "/contrats/payments/:id",
    element: <PaymentContratScreen />,
  },
  {
    path: "/contrats/payments/:id/add",
    element: <AddPaymentContratScreen />,
  },
  {
    path: "/contrats/payments/edit/:id",
    element: <EditPaymentContratScreen />,
  },
  //
  {
    path: "/contrats/return/:id/add",
    element: <AddReturnContratScreen />,
  },

  {
    path: "/contrats/search/:code",
    element: <SearchContratScreen />,
  },
  // depense
  {
    path: "/depenses/charges",
    element: <DepenseChargeScreen />,
  },
  {
    path: "/depenses/charges/add",
    element: <AddDepenseChargeScreen />,
  },
  {
    path: "/depenses/charges/edit/:id",
    element: <EditDepenseChargeScreen />,
  },
  {
    path: "/depenses/entretiens",
    element: <DepenseEntretienScreen />,
  },
  {
    path: "/depenses/entretiens/add",
    element: <AddDepenseEntretienScreen />,
  },
  {
    path: "/depenses/entretiens/edit/:id",
    element: <EditDepenseEntretienScreen />,
  },
  {
    path: "/depenses/employes",
    element: <DepenseEmployeScreen />,
  },
  {
    path: "/depenses/employes/add",
    element: <AddDepenseEmployeScreen />,
  },
  {
    path: "/depenses/employes/edit/:id",
    element: <EditDepenseEmployeScreen />,
  },
  //
  {
    path: "/profile",
    element: <ProfileScreen />,
  },
  {
    path: "/logout",
    element: <LogoutScreen />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
