import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import CountrySelector from "../../components/Selector";
import { COUNTRIES } from "../../constants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addNewClient } from "../../redux/actions/clientActions";
import LayoutSection from "../../components/LayoutSection";
import { getListAgences } from "../../redux/actions/agenceActions";
import { getMarqueList } from "../../redux/actions/marqueActions";
import { getModelList } from "../../redux/actions/modelActions";
import InputModel from "../../components/InputModel";
import {
  addNewCar,
  detailCar,
  updateCar,
} from "../../redux/actions/carActions";
import ConfirmationModal from "../../components/ConfirmationModal";

function EditCarScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();
  //
  const [agenceSelect, setAgenceSelect] = useState("");
  const [agenceSelectError, setAgenceSelectError] = useState("");
  const [creditSelect, setCreditSelect] = useState("");
  const [creditSelectError, setCreditSelectError] = useState("");
  const [interet, setInteret] = useState(0);
  const [interetError, setInteretError] = useState("");

  const [marqueSelect, setMarqueSelect] = useState("");
  const [marqueSelectError, setMarqueSelectError] = useState("");
  const [modelSelect, setModelSelect] = useState("");
  const [modelSelectError, setModelSelectError] = useState("");
  const [cvCar, setCvCar] = useState(0);
  const [cvCarError, setCvCarError] = useState("");

  const [matricule, setMatricule] = useState("");
  const [matriculeError, setMatriculeError] = useState("");
  const [wwMatricule, setWwMatricule] = useState("");
  const [wwMatriculeError, setWwMatriculeError] = useState("");
  const [priceDay, setPriceDay] = useState(0);
  const [priceDayError, setPriceDayError] = useState("");

  const [carburantSelect, setCarburantSelect] = useState("");
  const [carburantSelectError, setCarburantSelectError] = useState("");
  const [transmissionSelect, setTransmissionSelect] = useState("");
  const [transmissionSelectError, setTransmissionSelectError] = useState("");
  const [climatiseurSelect, setClimatiseurSelect] = useState("");
  const [climatiseurSelectError, setClimatiseurSelectError] = useState("");

  const [gpsSelect, setGpsSelect] = useState("");
  const [gpsSelectError, setGpsSelectError] = useState("");
  const [codeRadio, setCodeRadio] = useState("");
  const [codeRadioError, setCodeRadioError] = useState("");
  const [color, setColor] = useState("");
  const [colorError, setColorError] = useState("");

  const [gpsLocationSelect, setGpsLocationSelect] = useState("");
  const [gpsLocationSelectError, setGpsLocationSelectError] = useState("");
  const [gpsCode, setGpsCode] = useState("");
  const [gpsCodeError, setGpsCodeError] = useState("");

  const [purchaseDate, setPurchaseDate] = useState("");
  const [purchaseDateError, setPurchaseDateError] = useState("");
  const [pricePurchase, setPricePurchase] = useState(0);
  const [pricePurchaseError, setPricePurchaseError] = useState("");

  const [saleDate, setSaleDate] = useState("");
  const [saleDateError, setSaleDateError] = useState("");
  const [priceSale, setPriceSale] = useState(0);
  const [priceSaleError, setPriceSaleError] = useState("");

  const [numberKm, setNumberKm] = useState(0);
  const [numberKmError, setNumberKmError] = useState("");
  const [vidange, setVidange] = useState("");
  const [vidangeError, setVidangeError] = useState("");
  const [nextVidange, setNextVidange] = useState(0);
  const [nextVidangeError, setNextVidangeError] = useState("");

  const [startCartgris, setStartCartgris] = useState("");
  const [startCartgrisError, setStartCartgrisError] = useState("");
  const [alertCartgris, setAlertCartgris] = useState("");
  const [alertCartgrisError, setAlertCartgrisError] = useState("");
  const [endCartgris, setEndCartgris] = useState("");
  const [endCartgrisError, setEndCartgrisError] = useState("");

  const [startAssurance, setStartAssurance] = useState("");
  const [startAssuranceError, setStartAssuranceError] = useState("");
  const [alertAssurance, setAlertAssurance] = useState("");
  const [alertAssuranceError, setAlertAssuranceError] = useState("");
  const [endAssurance, setEndAssurance] = useState("");
  const [endAssuranceError, setEndAssuranceError] = useState("");

  const [startVisiteTechnique, setStartVisiteTechnique] = useState("");
  const [startVisiteTechniqueError, setStartVisiteTechniqueError] =
    useState("");
  const [alertVisiteTechnique, setAlertVisiteTechnique] = useState("");
  const [alertVisiteTechniqueError, setAlertVisiteTechniqueError] =
    useState("");
  const [endVisiteTechnique, setEndVisiteTechnique] = useState("");
  const [endVisiteTechniqueError, setEndVisiteTechniqueError] = useState("");

  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const [isAddCar, setIsAddCar] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");

  //

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listAgence = useSelector((state) => state.agenceList);
  const { agences } = listAgence;

  const carUpdate = useSelector((state) => state.updateCar);
  const { loadingCarUpdate, errorCarUpdate, successCarUpdate } = carUpdate;

  const carDetail = useSelector((state) => state.detailCar);
  const { loading, error, success, car } = carDetail;

  const listMarque = useSelector((state) => state.marqueList);
  const { marques, loadingMarque, errorMarque } = listMarque;

  const listModel = useSelector((state) => state.modelList);
  const { models, loadingModel, errorModel } = listModel;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListAgences("0"));
      dispatch(getMarqueList());
      dispatch(getModelList(""));
      dispatch(detailCar(id));
    }
  }, [navigate, userInfo, dispatch, id]);

  useEffect(() => {
    if (car !== undefined && car !== null) {
      if (car.agence !== null) {
        console.log(car?.agence?.id);
        setAgenceSelect(car?.agence?.id);
      }
      if (car.is_credit) {
        setCreditSelect(true);
      } else {
        setCreditSelect(false);
      }

      setInteret(car.interet);

      if (car.marque !== null) {
        setMarqueSelect(car?.marque?.id);
      }
      if (car.model !== null) {
        setModelSelect(car?.model?.id);
      }

      setCvCar(car.cv_car);

      setMatricule(car.matricule);

      setWwMatricule(car.ww_matricule);

      setPriceDay(car.price_day);

      setCarburantSelect(car.carburant === "Essence" ? 1 : 2);
      setTransmissionSelect(car.transmission === "Manuelle" ? 1 : 2);

      setClimatiseurSelect(car.is_climatiseur);

      setGpsSelect(car.is_gps);

      setCodeRadio(car.code_radio);

      setColor(car.color);

      setGpsLocationSelect(car.is_gpsloc);
      setGpsCode(car.code_gps);

      setPurchaseDate(car.purchase_date);
      setPricePurchase(car.purchase_price);

      setSaleDate(car.sale_date);
      setPriceSale(car.sale_price);

      setNumberKm(car.number_km);

      setVidange(car.vidange);

      setNextVidange(car.next_vidange);

      setStartCartgris(car.start_cartgris);

      setAlertCartgris(car.alert_cartgris);
      setEndCartgris(car.end_cartgris);

      setStartAssurance(car.start_assurance);
      setAlertAssurance(car.alert_assurance);
      setEndAssurance(car.end_assurance);

      setStartVisiteTechnique(car.start_visitetechnique);
      setAlertVisiteTechnique(car.alert_visitetechnique);
      setEndVisiteTechnique(car.end_visitetechnique);

      setNote(car.note);
    }
  }, [car]);

  useEffect(() => {
    if (successCarUpdate) {
      setAgenceSelect("");
      setAgenceSelectError("");
      setCreditSelect("");
      setCreditSelectError("");
      setInteret(0);
      setInteretError("");

      setMarqueSelect("");
      setMarqueSelectError("");
      setModelSelect("");
      setModelSelectError("");
      setCvCar(0);
      setCvCarError("");

      setMatricule("");
      setMatriculeError("");
      setWwMatricule("");
      setWwMatriculeError("");
      setPriceDay(0);
      setPriceDayError("");

      setCarburantSelect("");
      setCarburantSelectError("");
      setTransmissionSelect("");
      setTransmissionSelectError("");
      setClimatiseurSelect("");
      setClimatiseurSelectError("");

      setGpsSelect("");
      setGpsSelectError("");
      setCodeRadio("");
      setCodeRadioError("");
      setColor("");
      setColorError("");

      setGpsLocationSelect("");
      setGpsLocationSelectError("");
      setGpsCode("");
      setGpsCodeError("");

      setPurchaseDate("");
      setPurchaseDateError("");
      setPricePurchase(0);
      setPricePurchaseError("");

      setSaleDate("");
      setSaleDateError("");
      setPriceSale(0);
      setPriceSaleError("");

      setNumberKm(0);
      setNumberKmError("");
      setVidange("");
      setVidangeError("");
      setNextVidange("");
      setNextVidangeError("");

      setStartCartgris("");
      setStartCartgrisError("");
      setAlertCartgris("");
      setAlertCartgrisError("");
      setEndCartgris("");
      setEndCartgrisError("");

      setStartAssurance("");
      setStartAssuranceError("");
      setAlertAssurance("");
      setAlertAssuranceError("");
      setEndAssurance("");
      setEndAssuranceError("");

      setStartVisiteTechnique("");
      setStartVisiteTechniqueError("");
      setAlertVisiteTechnique("");
      setAlertVisiteTechniqueError("");
      setEndVisiteTechnique("");
      setEndVisiteTechniqueError("");

      setNote("");
      setNoteError("");

      dispatch(getListAgences("0"));
      dispatch(getMarqueList());
      dispatch(getModelList(""));
      dispatch(detailCar(id));
      setIsAddCar(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successCarUpdate]);
  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/cars/">
            <div className="">Voitures</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Modifié</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Modifié la voiture
            </h4>
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col ">
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection title="Information général">
                {/* agence , credit , interet */}
                <div className="md:py-2 md:flex">
                  {/* <InputModel
                    label="Agence"
                    type="select"
                    placeholder="Agence"
                    value={agenceSelect}
                    onChange={(v) => setAgenceSelect(v.target.value)}
                    error={agenceSelectError}
                    options={agences?.map((agence) => ({
                      value: agence.id,
                      label: agence.name,
                    }))}
                  /> */}
                  <InputModel
                    label="Credit"
                    type="select"
                    placeholder="Credit"
                    value={creditSelect}
                    onChange={(v) => setCreditSelect(v.target.value)}
                    error={creditSelectError}
                    options={[
                      { value: true, label: "Oui" },
                      { value: false, label: "Non" },
                    ]}
                  />
                  <InputModel
                    label="Interet"
                    type="number"
                    placeholder=""
                    value={interet}
                    onChange={(v) => setInteret(v.target.value)}
                    error={interetError}
                  />
                </div>
                {/* marque model cv */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Marque"
                    type="select"
                    placeholder=""
                    value={marqueSelect}
                    onChange={(v) => {
                      setMarqueSelect(v.target.value);
                      setModelSelect("");
                      dispatch(getModelList(v.target.value));
                    }}
                    error={marqueSelectError}
                    options={marques?.map((marque) => ({
                      value: marque.id,
                      label: marque.marque_car,
                    }))}
                  />
                  <InputModel
                    label="Modele"
                    type="select"
                    placeholder=""
                    value={modelSelect}
                    onChange={(v) => {
                      setModelSelectError("");
                      if (marqueSelect === "") {
                        setModelSelect("");
                        setModelSelectError("sélectionner la marque");
                        toast.error(
                          "Veuillez d'abord sélectionner la marque de la voiture"
                        );
                      } else {
                        setModelSelect(v.target.value);
                      }
                    }}
                    error={modelSelectError}
                    options={models?.map((model) => ({
                      value: model.id,
                      label: model.model_car,
                    }))}
                  />
                  <InputModel
                    label="CV"
                    type="number"
                    placeholder=""
                    value={cvCar}
                    onChange={(v) => {
                      setCvCarError("");
                      setCvCar(v.target.value);
                      const isNotInt = !Number.isInteger(
                        parseFloat(v.target.value)
                      );
                      const hasE = v.target.value.toLowerCase().includes("e");

                      if ((isNotInt && v.target.value !== "") || hasE) {
                        setCvCarError("Cette valeur doit être un entier.");
                      }
                    }}
                    error={cvCarError}
                  />
                </div>
                {/* matricule , ww, price */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Matricule"
                    type="text"
                    placeholder=""
                    value={matricule}
                    onChange={(v) => setMatricule(v.target.value)}
                    error={matriculeError}
                  />
                  <InputModel
                    label="Matricule WW"
                    type="text"
                    placeholder=""
                    value={wwMatricule}
                    onChange={(v) => setWwMatricule(v.target.value)}
                    error={wwMatriculeError}
                  />
                  <InputModel
                    label="Prix/Jour"
                    type="number"
                    placeholder=""
                    value={priceDay}
                    isPrice={true}
                    onChange={(v) => setPriceDay(v.target.value)}
                    error={priceDayError}
                  />
                </div>
                {/* carb, trans clima */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Carburant"
                    type="select"
                    placeholder=""
                    value={carburantSelect}
                    onChange={(v) => setCarburantSelect(v.target.value)}
                    error={carburantSelectError}
                    options={[
                      { value: 1, label: "Essence" },
                      { value: 2, label: "Diesel" },
                    ]}
                  />
                  <InputModel
                    label="Transmission"
                    type="select"
                    placeholder=""
                    value={transmissionSelect}
                    onChange={(v) => setTransmissionSelect(v.target.value)}
                    error={transmissionSelectError}
                    options={[
                      { value: 1, label: "Manuelle" },
                      { value: 2, label: "Automatique" },
                    ]}
                  />
                  <InputModel
                    label="Climatiseur"
                    type="select"
                    placeholder=""
                    value={climatiseurSelect}
                    onChange={(v) => setClimatiseurSelect(v.target.value)}
                    error={climatiseurSelectError}
                    options={[
                      { value: true, label: "Oui" },
                      { value: false, label: "Non" },
                    ]}
                  />
                </div>
                {/*  gps, code radio, color */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Gps"
                    type="select"
                    placeholder=""
                    value={gpsSelect}
                    onChange={(v) => setGpsSelect(v.target.value)}
                    error={gpsSelectError}
                    options={[
                      { value: true, label: "Oui" },
                      { value: false, label: "Non" },
                    ]}
                  />
                  <InputModel
                    label="Code radio"
                    type="text"
                    placeholder=""
                    value={codeRadio}
                    onChange={(v) => setCodeRadio(v.target.value)}
                    error={codeRadioError}
                  />
                  <InputModel
                    label="Couleur"
                    type="select"
                    placeholder=""
                    value={color}
                    onChange={(v) => setColor(v.target.value)}
                    error={colorError}
                    options={[
                      { value: "#A9EA9E", label: "Vert" },
                      { value: "#5793B1", label: "Blue" },
                      { value: "#FFDB46", label: "Jaune" },
                      { value: "#FB5754", label: "Rouge" },
                      { value: "#fff", label: "Blanc" },
                      { value: "#D4D3D3", label: "Gris" },
                      { value: "#614E1A", label: "Bronze" },
                      { value: "#5A3A22", label: "Chocolat" },
                    ]}
                  />
                </div>
                {/*  gps, code gps */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="GPS de localisation"
                    type="select"
                    placeholder=""
                    value={gpsLocationSelect}
                    onChange={(v) => setGpsLocationSelect(v.target.value)}
                    error={gpsLocationSelectError}
                    options={[
                      { value: true, label: "Oui" },
                      { value: false, label: "Non" },
                    ]}
                  />
                  <InputModel
                    label="Numéro GPS"
                    type="text"
                    placeholder=""
                    value={gpsCode}
                    onChange={(v) => setGpsCode(v.target.value)}
                    error={gpsCodeError}
                  />
                </div>
                {/* price achat , date */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Date D'achat"
                    type="date"
                    placeholder=""
                    value={purchaseDate}
                    onChange={(v) => setPurchaseDate(v.target.value)}
                    error={purchaseDateError}
                  />

                  <InputModel
                    label="Prix D'achat"
                    type="number"
                    isPrice={true}
                    placeholder=""
                    value={pricePurchase}
                    onChange={(v) => setPricePurchase(v.target.value)}
                    error={pricePurchaseError}
                  />
                </div>
                {/* price sale , date */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Date Vente"
                    type="date"
                    placeholder=""
                    value={saleDate}
                    onChange={(v) => setSaleDate(v.target.value)}
                    error={saleDateError}
                  />

                  <InputModel
                    label="Prix Vente"
                    type="number"
                    isPrice={true}
                    placeholder=""
                    value={priceSale}
                    onChange={(v) => setPriceSale(v.target.value)}
                    error={priceSaleError}
                  />
                </div>
                {/*  */}
              </LayoutSection>
            </div>
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection title="Documents voiture">
                {/* km videnge */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Km actuelle"
                    type="number"
                    placeholder=""
                    isPrice={true}
                    value={numberKm}
                    onChange={(v) => setNumberKm(v.target.value)}
                    error={numberKmError}
                  />

                  <InputModel
                    label="Vidange"
                    type="select"
                    placeholder=""
                    value={vidange}
                    onChange={(v) => setVidange(v.target.value)}
                    error={vidangeError}
                    options={[
                      { value: 5000, label: "5000" },
                      { value: 10000, label: "10000" },
                      { value: 15000, label: "15000" },
                      { value: 20000, label: "20000" },
                      { value: 25000, label: "25000" },
                    ]}
                  />
                  <InputModel
                    label="Prochain vidange"
                    type="number"
                    placeholder=""
                    isPrice={true}
                    value={nextVidange}
                    onChange={(v) => setNextVidange(v.target.value)}
                    error={nextVidangeError}
                  />
                </div>

                {/* cart gris */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Carte gris du"
                    type="date"
                    placeholder=""
                    value={startCartgris}
                    onChange={(v) => setStartCartgris(v.target.value)}
                    error={startCartgrisError}
                  />

                  <InputModel
                    label="Alert carte"
                    type="select"
                    placeholder=""
                    value={alertCartgris}
                    onChange={(v) => setAlertCartgris(v.target.value)}
                    error={alertCartgrisError}
                    options={[
                      { value: 10, label: "10 Jours" },
                      { value: 15, label: "15 Jours" },
                      { value: 30, label: "30 Jours" },
                      { value: 0, label: "Définitive" },
                    ]}
                  />
                  <InputModel
                    label="Carte grise au"
                    type="date"
                    placeholder=""
                    isPrice={true}
                    value={endCartgris}
                    onChange={(v) => setEndCartgris(v.target.value)}
                    error={endCartgrisError}
                  />
                </div>
                {/* assurance */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Assurance du"
                    type="date"
                    placeholder=""
                    value={startAssurance}
                    onChange={(v) => setStartAssurance(v.target.value)}
                    error={startAssuranceError}
                  />

                  <InputModel
                    label="Alert Assurance"
                    type="select"
                    placeholder=""
                    value={alertAssurance}
                    onChange={(v) => setAlertAssurance(v.target.value)}
                    error={alertAssuranceError}
                    options={[
                      { value: 12, label: "12 Mois" },
                      { value: 0, label: "la fin d'année" },
                    ]}
                  />
                  <InputModel
                    label="Assurance au"
                    type="date"
                    placeholder=""
                    isPrice={true}
                    value={endAssurance}
                    onChange={(v) => setEndAssurance(v.target.value)}
                    error={endAssuranceError}
                  />
                </div>
                {/* assurance */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Visite technique du"
                    type="date"
                    placeholder=""
                    value={startVisiteTechnique}
                    onChange={(v) => setStartVisiteTechnique(v.target.value)}
                    error={startVisiteTechniqueError}
                  />

                  <InputModel
                    label="Alert Visite technique"
                    type="select"
                    placeholder=""
                    value={alertVisiteTechnique}
                    onChange={(v) => setAlertVisiteTechnique(v.target.value)}
                    error={alertVisiteTechniqueError}
                    options={[
                      { value: 1, label: "1 Mois" },
                      { value: 6, label: "6 Mois" },
                      { value: 12, label: "12 Mois" },
                    ]}
                  />
                  <InputModel
                    label="Visite technique au"
                    type="date"
                    placeholder=""
                    isPrice={true}
                    value={endVisiteTechnique}
                    onChange={(v) => setEndVisiteTechnique(v.target.value)}
                    error={endVisiteTechniqueError}
                  />
                </div>
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Remarque"
                    type="textarea"
                    placeholder=""
                    value={note}
                    onChange={(v) => setNote(v.target.value)}
                    error={noteError}
                  />
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventType("cancel");
                setIsAddCar(true);
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Annuler
            </button>
            <button
              onClick={async () => {
                var check = true;
                setAgenceSelectError("");
                setCreditSelectError("");
                setInteretError("");

                setMarqueSelectError("");
                setModelSelectError("");
                setCvCarError("");

                setMatriculeError("");
                setWwMatriculeError("");
                setPriceDayError("");

                setCarburantSelectError("");
                setTransmissionSelectError("");
                setClimatiseurSelectError("");

                setGpsSelectError("");
                setCodeRadioError("");
                setColorError("");

                setGpsLocationSelectError("");
                setGpsCodeError("");

                setPurchaseDateError("");
                setPricePurchaseError("");

                setSaleDateError("");
                setPriceSaleError("");

                setNumberKmError("");
                setVidangeError("");
                setNextVidangeError("");

                setStartCartgrisError("");
                setAlertCartgrisError("");
                setEndCartgrisError("");

                setStartAssuranceError("");
                setAlertAssuranceError("");
                setEndAssuranceError("");

                setStartVisiteTechniqueError("");
                setAlertVisiteTechniqueError("");
                setEndVisiteTechniqueError("");

                setNoteError("");
                // line 1
                if (creditSelect === "") {
                  setCreditSelectError("Ce champ est requis.");
                  check = false;
                }
                if (interet === "") {
                  setInteret(0);
                }
                // line 2
                if (marqueSelect === "") {
                  setMarqueSelectError("Ce champ est requis.");
                  check = false;
                }
                if (modelSelect === "") {
                  setModelSelectError("Ce champ est requis.");
                  check = false;
                }
                if (cvCar === "" || cvCar === 0) {
                  setCvCarError("Ce champ est requis.");
                  setCvCar(0);
                  check = false;
                } else {
                  const isCarNotInt = !Number.isInteger(parseFloat(cvCar));
                  if (isCarNotInt) {
                    setCvCarError("Cette valeur doit être un entier.");
                    check = false;
                  }
                }
                // line 3
                if (priceDay === "") {
                  setPriceDay(0);
                }
                // line 4
                if (carburantSelect === "") {
                  setCarburantSelectError("Ce champ est requis.");
                  check = false;
                }
                if (transmissionSelect === "") {
                  setTransmissionSelectError("Ce champ est requis.");
                  check = false;
                }
                if (climatiseurSelect === "") {
                  setClimatiseurSelectError("Ce champ est requis.");
                  check = false;
                }
                // line 5
                if (gpsSelect === "") {
                  setGpsSelectError("Ce champ est requis.");
                  check = false;
                }
                // line 6
                if (gpsLocationSelect === "") {
                  setGpsLocationSelectError("Ce champ est requis.");
                  check = false;
                }
                if (vidange === "") {
                  setVidangeError("Ce champ est requis.");
                  check = false;
                }

                if (check) {
                  setEventType("add");
                  setIsAddCar(true);
                } else {
                  toast.error(
                    "Certains champs sont obligatoires veuillez vérifier"
                  );
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                />
              </svg>
              Modifié
            </button>
          </div>
        </div>
        <ConfirmationModal
          isOpen={isAddCar}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Êtes-vous sûr de vouloir modifié cette voiture ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setAgenceSelect("");
              setAgenceSelectError("");
              setCreditSelect("");
              setCreditSelectError("");
              setInteret(0);
              setInteretError("");

              setMarqueSelect("");
              setMarqueSelectError("");
              setModelSelect("");
              setModelSelectError("");
              setCvCar(0);
              setCvCarError("");

              setMatricule("");
              setMatriculeError("");
              setWwMatricule("");
              setWwMatriculeError("");
              setPriceDay(0);
              setPriceDayError("");

              setCarburantSelect("");
              setCarburantSelectError("");
              setTransmissionSelect("");
              setTransmissionSelectError("");
              setClimatiseurSelect("");
              setClimatiseurSelectError("");

              setGpsSelect("");
              setGpsSelectError("");
              setCodeRadio("");
              setCodeRadioError("");
              setColor("");
              setColorError("");

              setGpsLocationSelect("");
              setGpsLocationSelectError("");
              setGpsCode("");
              setGpsCodeError("");

              setPurchaseDate("");
              setPurchaseDateError("");
              setPricePurchase(0);
              setPricePurchaseError("");

              setSaleDate("");
              setSaleDateError("");
              setPriceSale(0);
              setPriceSaleError("");

              setNumberKm(0);
              setNumberKmError("");
              setVidange("");
              setVidangeError("");
              setNextVidange("");
              setNextVidangeError("");

              setStartCartgris("");
              setStartCartgrisError("");
              setAlertCartgris("");
              setAlertCartgrisError("");
              setEndCartgris("");
              setEndCartgrisError("");

              setStartAssurance("");
              setStartAssuranceError("");
              setAlertAssurance("");
              setAlertAssuranceError("");
              setEndAssurance("");
              setEndAssuranceError("");

              setStartVisiteTechnique("");
              setStartVisiteTechniqueError("");
              setAlertVisiteTechnique("");
              setAlertVisiteTechniqueError("");
              setEndVisiteTechnique("");
              setEndVisiteTechniqueError("");

              setNote("");
              setNoteError("");

              setIsAddCar(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              console.log(purchaseDate);
              await dispatch(
                updateCar(id, {
                  agence: agenceSelect === null ? "" : agenceSelect,
                  is_credit: creditSelect ?? "",
                  interet: interet ?? "",
                  marque: marqueSelect ?? "",
                  model: modelSelect ?? "",
                  cv_car: cvCar ?? "",
                  matricule: matricule ?? "",
                  ww_matricule: wwMatricule ?? "",
                  price_day: priceDay ?? "",
                  carburant: carburantSelect ?? "",
                  transmission: transmissionSelect ?? "",
                  is_climatiseur: climatiseurSelect ?? "",
                  is_gps: gpsSelect ?? "",
                  code_radio: codeRadio ?? "",
                  color: color ?? "",
                  is_gpsloc: gpsLocationSelect ?? "",
                  code_gps: gpsCode ?? "",
                  purchase_date: purchaseDate === null ? "" : purchaseDate,
                  purchase_price: pricePurchase ?? "",
                  sale_date: saleDate === null ? "" : saleDate,
                  sale_price: priceSale ?? "",
                  number_km: numberKm ?? "",
                  vidange: vidange ?? "",
                  next_vidange: nextVidange ?? "",
                  start_cartgris: startCartgris === null ? "" : startCartgris,
                  alert_cartgris: alertCartgris === null ? "" : alertCartgris,
                  end_cartgris: endCartgris === null ? "" : endCartgris,
                  start_assurance:
                    startAssurance === null ? "" : startAssurance,
                  alert_assurance:
                    alertAssurance === null ? "" : alertAssurance,
                  end_assurance: endAssurance === null ? "" : endAssurance,
                  start_visitetechnique:
                    startVisiteTechnique === null ? "" : startVisiteTechnique,
                  alert_visitetechnique:
                    alertVisiteTechnique === null ? "" : alertVisiteTechnique,
                  end_visitetechnique:
                    endVisiteTechnique === null ? "" : endVisiteTechnique,
                  note: note ?? "",
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAddCar(false);
            }
          }}
          onCancel={() => {
            setIsAddCar(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />

        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default EditCarScreen;
