import { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import axios from "axios";

const DefaultLayout = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // const check = async()=>{
  //     const userInfoFromStorage = localStorage.getItem('userInfoTayssir')
  //       ? JSON.parse(localStorage.getItem('userInfoTayssir'))
  //       : null
  //     if (userInfoFromStorage != null) {
  //       const config = {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${userInfoFromStorage.access}`,
  //         },
  //       }
  //       try {
  //         axios.get(`/api/users/check-login-admin/`, config).then((data) => {

  //         }).catch((er) => {
  //           console.log(er);
  //           window.location.replace("/");
  //         })
  //       } catch (error) {

  //       }

  //     } else {
  //       window.location.replace("/");
  //     }
  // }

  // useEffect( () => {

  //   }, [])

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex h-screen overflow-hidden">
        {/* <!-- ===== Sidebar Start ===== --> */}
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={() => setSidebarOpen(!sidebarOpen)}
        />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          {/* <!-- ===== Header Start ===== --> */}
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={() => setSidebarOpen(!sidebarOpen)}
          />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main>
            <div className="mx-auto max-w-screen-2xl p-2 md:p-4 2xl:p-8">
              {props.children}
            </div>
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
  );
};

export default DefaultLayout;
