import axios from "../../axios";
import {
  RESERVATION_LIST_REQUEST,
  RESERVATION_LIST_SUCCESS,
  RESERVATION_LIST_FAIL,
  //
  RESERVATION_ADD_REQUEST,
  RESERVATION_ADD_SUCCESS,
  RESERVATION_ADD_FAIL,
  //
  RESERVATION_DETAIL_REQUEST,
  RESERVATION_DETAIL_SUCCESS,
  RESERVATION_DETAIL_FAIL,
  //
  RESERVATION_UPDATE_REQUEST,
  RESERVATION_UPDATE_SUCCESS,
  RESERVATION_UPDATE_FAIL,
  //
  RESERVATION_DELETE_REQUEST,
  RESERVATION_DELETE_SUCCESS,
  RESERVATION_DELETE_FAIL,
  //
} from "../constants/reservationConstants";

// delete reservation
export const deleteReservation = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESERVATION_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(`/reservations/delete/${id}/`, config);

    dispatch({
      type: RESERVATION_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: RESERVATION_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// update reservation
export const updateReservation =
  (id, reservation) => async (dispatch, getState) => {
    try {
      dispatch({
        type: RESERVATION_UPDATE_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const { data } = await axios.put(
        `/reservations/update/${id}/`,
        reservation,
        config
      );

      dispatch({
        type: RESERVATION_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: RESERVATION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// detail reservation
export const detailReservation = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESERVATION_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    //
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    //
    const { data } = await axios.get(`/reservations/detail/${id}/`, config);

    dispatch({
      type: RESERVATION_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: RESERVATION_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// add new client
export const addNewReservation =
  (reservation) => async (dispatch, getState) => {
    try {
      dispatch({
        type: RESERVATION_ADD_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.post(
        `/reservations/add/`,
        reservation,
        config
      );

      dispatch({
        type: RESERVATION_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: RESERVATION_ADD_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// get list cars
export const getListReservations = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESERVATION_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/reservations/?page=${page}`, config);

    dispatch({
      type: RESERVATION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: RESERVATION_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
