export const CONTRAT_LIST_REQUEST = "CONTRAT_LIST_REQUEST";
export const CONTRAT_LIST_SUCCESS = "CONTRAT_LIST_SUCCESS";
export const CONTRAT_LIST_FAIL = "CONTRAT_LIST_FAIL";

export const CONTRAT_ADD_REQUEST = "CONTRAT_ADD_REQUEST";
export const CONTRAT_ADD_SUCCESS = "CONTRAT_ADD_SUCCESS";
export const CONTRAT_ADD_FAIL = "CONTRAT_ADD_FAIL";

export const CONTRAT_DETAIL_REQUEST = "CONTRAT_DETAIL_REQUEST";
export const CONTRAT_DETAIL_SUCCESS = "CONTRAT_DETAIL_SUCCESS";
export const CONTRAT_DETAIL_FAIL = "CONTRAT_DETAIL_FAIL";

export const CONTRAT_UPDATE_REQUEST = "CONTRAT_UPDATE_REQUEST";
export const CONTRAT_UPDATE_SUCCESS = "CONTRAT_UPDATE_SUCCESS";
export const CONTRAT_UPDATE_FAIL = "CONTRAT_UPDATE_FAIL";

export const CONTRAT_CLIENT_LIST_REQUEST = "CONTRAT_CLIENT_LIST_REQUEST";
export const CONTRAT_CLIENT_LIST_SUCCESS = "CONTRAT_CLIENT_LIST_SUCCESS";
export const CONTRAT_CLIENT_LIST_FAIL = "CONTRAT_CLIENT_LIST_FAIL";

export const CONTRAT_PAYMENT_LIST_REQUEST = "CONTRAT_PAYMENT_LIST_REQUEST";
export const CONTRAT_PAYMENT_LIST_SUCCESS = "CONTRAT_PAYMENT_LIST_SUCCESS";
export const CONTRAT_PAYMENT_LIST_FAIL = "CONTRAT_PAYMENT_LIST_FAIL";

export const CONTRAT_PAYMENT_ADD_REQUEST = "CONTRAT_PAYMENT_ADD_REQUEST";
export const CONTRAT_PAYMENT_ADD_SUCCESS = "CONTRAT_PAYMENT_ADD_SUCCESS";
export const CONTRAT_PAYMENT_ADD_FAIL = "CONTRAT_PAYMENT_ADD_FAIL";

export const CONTRAT_PAYMENT_DETAIL_REQUEST = "CONTRAT_PAYMENT_DETAIL_REQUEST";
export const CONTRAT_PAYMENT_DETAIL_SUCCESS = "CONTRAT_PAYMENT_DETAIL_SUCCESS";
export const CONTRAT_PAYMENT_DETAIL_FAIL = "CONTRAT_PAYMENT_DETAIL_FAIL";

export const CONTRAT_PAYMENT_UPDATE_REQUEST = "CONTRAT_PAYMENT_UPDATE_REQUEST";
export const CONTRAT_PAYMENT_UPDATE_SUCCESS = "CONTRAT_PAYMENT_UPDATE_SUCCESS";
export const CONTRAT_PAYMENT_UPDATE_FAIL = "CONTRAT_PAYMENT_UPDATE_FAIL";

export const CONTRAT_PAYMENT_DELETE_REQUEST = "CONTRAT_PAYMENT_DELETE_REQUEST";
export const CONTRAT_PAYMENT_DELETE_SUCCESS = "CONTRAT_PAYMENT_DELETE_SUCCESS";
export const CONTRAT_PAYMENT_DELETE_FAIL = "CONTRAT_PAYMENT_DELETE_FAIL";

export const CONTRAT_RETURN_ADD_REQUEST = "CONTRAT_RETURN_ADD_REQUEST";
export const CONTRAT_RETURN_ADD_SUCCESS = "CONTRAT_RETURN_ADD_SUCCESS";
export const CONTRAT_RETURN_ADD_FAIL = "CONTRAT_RETURN_ADD_FAIL";

export const CONTRAT_BACK_LIST_REQUEST = "CONTRAT_BACK_LIST_REQUEST";
export const CONTRAT_BACK_LIST_SUCCESS = "CONTRAT_BACK_LIST_SUCCESS";
export const CONTRAT_BACK_LIST_FAIL = "CONTRAT_BACK_LIST_FAIL";

export const CONTRAT_FACTURES_LIST_REQUEST = "CONTRAT_FACTURES_LIST_REQUEST";
export const CONTRAT_FACTURES_LIST_SUCCESS = "CONTRAT_FACTURES_LIST_SUCCESS";
export const CONTRAT_FACTURES_LIST_FAIL = "CONTRAT_FACTURES_LIST_FAIL";

export const SEARCH_CONTRAT_LIST_REQUEST = "SEARCH_CONTRAT_LIST_REQUEST";
export const SEARCH_CONTRAT_LIST_SUCCESS = "SEARCH_CONTRAT_LIST_SUCCESS";
export const SEARCH_CONTRAT_LIST_FAIL = "SEARCH_CONTRAT_LIST_FAIL";

export const CONTRAT_RETURN_VALID_REQUEST = "CONTRAT_RETURN_VALID_REQUEST";
export const CONTRAT_RETURN_VALID_SUCCESS = "CONTRAT_RETURN_VALID_SUCCESS";
export const CONTRAT_RETURN_VALID_FAIL = "CONTRAT_RETURN_VALID_FAIL";

export const CONTRAT_DELETE_REQUEST = "CONTRAT_DELETE_REQUEST";
export const CONTRAT_DELETE_SUCCESS = "CONTRAT_DELETE_SUCCESS";
export const CONTRAT_DELETE_FAIL = "CONTRAT_DELETE_FAIL";
