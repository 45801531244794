import axios from "../../axios";
import {
  CHARGE_LIST_REQUEST,
  CHARGE_LIST_SUCCESS,
  CHARGE_LIST_FAIL,
  //
  CHARGE_ADD_REQUEST,
  CHARGE_ADD_SUCCESS,
  CHARGE_ADD_FAIL,
  //
  CHARGE_DELETE_REQUEST,
  CHARGE_DELETE_SUCCESS,
  CHARGE_DELETE_FAIL,
  //
  CHARGE_UPDATE_REQUEST,
  CHARGE_UPDATE_SUCCESS,
  CHARGE_UPDATE_FAIL,
  //
  ENTRETIEN_LIST_REQUEST,
  ENTRETIEN_LIST_SUCCESS,
  ENTRETIEN_LIST_FAIL,
  //
  ENTRETIEN_DELETE_REQUEST,
  ENTRETIEN_DELETE_SUCCESS,
  ENTRETIEN_DELETE_FAIL,
  //
  ENTRETIEN_ADD_REQUEST,
  ENTRETIEN_ADD_SUCCESS,
  ENTRETIEN_ADD_FAIL,
  //
  ENTRETIEN_UPDATE_REQUEST,
  ENTRETIEN_UPDATE_SUCCESS,
  ENTRETIEN_UPDATE_FAIL,
  //
  DEPENSE_CHARGE_LIST_REQUEST,
  DEPENSE_CHARGE_LIST_SUCCESS,
  DEPENSE_CHARGE_LIST_FAIL,
  //
  DEPENSE_CHARGE_ADD_REQUEST,
  DEPENSE_CHARGE_ADD_SUCCESS,
  DEPENSE_CHARGE_ADD_FAIL,
  //
  DEPENSE_CHARGE_DETAIL_REQUEST,
  DEPENSE_CHARGE_DETAIL_SUCCESS,
  DEPENSE_CHARGE_DETAIL_FAIL,
  //
  DEPENSE_CHARGE_UPDATE_REQUEST,
  DEPENSE_CHARGE_UPDATE_SUCCESS,
  DEPENSE_CHARGE_UPDATE_FAIL,
  //
  DEPENSE_CHARGE_DELETE_REQUEST,
  DEPENSE_CHARGE_DELETE_SUCCESS,
  DEPENSE_CHARGE_DELETE_FAIL,
  //
  DEPENSE_ENTRETIEN_LIST_REQUEST,
  DEPENSE_ENTRETIEN_LIST_SUCCESS,
  DEPENSE_ENTRETIEN_LIST_FAIL,
  //
  DEPENSE_ENTRETIEN_ADD_REQUEST,
  DEPENSE_ENTRETIEN_ADD_SUCCESS,
  DEPENSE_ENTRETIEN_ADD_FAIL,
  //
  DEPENSE_ENTRETIEN_DETAIL_REQUEST,
  DEPENSE_ENTRETIEN_DETAIL_SUCCESS,
  DEPENSE_ENTRETIEN_DETAIL_FAIL,
  //
  DEPENSE_ENTRETIEN_UPDATE_REQUEST,
  DEPENSE_ENTRETIEN_UPDATE_SUCCESS,
  DEPENSE_ENTRETIEN_UPDATE_FAIL,
  //
  DEPENSE_EMPLOYE_LIST_REQUEST,
  DEPENSE_EMPLOYE_LIST_SUCCESS,
  DEPENSE_EMPLOYE_LIST_FAIL,
  //
  DEPENSE_EMPLOYE_ADD_REQUEST,
  DEPENSE_EMPLOYE_ADD_SUCCESS,
  DEPENSE_EMPLOYE_ADD_FAIL,
  //
  DEPENSE_EMPLOYE_DETAIL_REQUEST,
  DEPENSE_EMPLOYE_DETAIL_SUCCESS,
  DEPENSE_EMPLOYE_DETAIL_FAIL,
  //
  DEPENSE_EMPLOYE_UPDATE_REQUEST,
  DEPENSE_EMPLOYE_UPDATE_SUCCESS,
  DEPENSE_EMPLOYE_UPDATE_FAIL,
  //
  DEPENSE_EMPLOYE_DELETE_REQUEST,
  DEPENSE_EMPLOYE_DELETE_SUCCESS,
  DEPENSE_EMPLOYE_DELETE_FAIL,
  //
  DEPENSE_ENTRETIEN_DELETE_SUCCESS,
  DEPENSE_ENTRETIEN_DELETE_FAIL,
  DEPENSE_ENTRETIEN_DELETE_REQUEST,
  //
} from "../constants/designationConstants";

// delete employe charge
export const deleteDepenseEmploye = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEPENSE_EMPLOYE_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(
      `/depenses/employes/delete/${id}/`,
      config
    );

    dispatch({
      type: DEPENSE_EMPLOYE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: DEPENSE_EMPLOYE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
// update detail employe
export const updateDepenseEmploye =
  (id, employe) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DEPENSE_EMPLOYE_UPDATE_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.put(
        `/depenses/employes/update/${id}/`,
        employe,
        config
      );

      dispatch({
        type: DEPENSE_EMPLOYE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: DEPENSE_EMPLOYE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// get detail employe
export const getDetailDepenseEmploye = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEPENSE_EMPLOYE_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    //
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    //
    const { data } = await axios.get(
      `/depenses/employes/detail/${id}/`,
      config
    );

    dispatch({
      type: DEPENSE_EMPLOYE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: DEPENSE_EMPLOYE_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// add new charge employes
export const addNewDepenseEmploye = (charge) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEPENSE_EMPLOYE_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(
      `/depenses/employes/add/`,
      charge,
      config
    );

    dispatch({
      type: DEPENSE_EMPLOYE_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: DEPENSE_EMPLOYE_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list depense entretien
export const getListDepenseEmployes = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEPENSE_EMPLOYE_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(
      `/depenses/employes/?page=${page}`,
      config
    );

    dispatch({
      type: DEPENSE_EMPLOYE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: DEPENSE_EMPLOYE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// delete depense entretien
export const deleteDepenseEntretien = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEPENSE_ENTRETIEN_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    //
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    //
    const { data } = await axios.delete(
      `/depenses/entretiens/delete/${id}/`,
      config
    );

    dispatch({
      type: DEPENSE_ENTRETIEN_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: DEPENSE_ENTRETIEN_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// update detail entretien
export const updateDepenseEntretien =
  (id, entretien) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DEPENSE_ENTRETIEN_UPDATE_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.put(
        `/depenses/entretiens/update/${id}/`,
        entretien,
        config
      );

      dispatch({
        type: DEPENSE_ENTRETIEN_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: DEPENSE_ENTRETIEN_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// get detail entretien
export const getDetailDepenseEntretien = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEPENSE_ENTRETIEN_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    //
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    //
    const { data } = await axios.get(
      `/depenses/entretiens/detail/${id}/`,
      config
    );

    dispatch({
      type: DEPENSE_ENTRETIEN_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: DEPENSE_ENTRETIEN_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// add new depense entretien
export const addNewDepenseEntretien =
  (entretien) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DEPENSE_ENTRETIEN_ADD_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.post(
        `/depenses/entretiens/add/`,
        entretien,
        config
      );

      dispatch({
        type: DEPENSE_ENTRETIEN_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: DEPENSE_ENTRETIEN_ADD_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// get list depense entretien
export const getListDepenseEntretiens =
  (page) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DEPENSE_ENTRETIEN_LIST_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/depenses/entretiens/?page=${page}`,
        config
      );

      dispatch({
        type: DEPENSE_ENTRETIEN_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: DEPENSE_ENTRETIEN_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// update detail charge
export const updateDepenseCharge =
  (id, charge) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DEPENSE_CHARGE_UPDATE_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.put(
        `/depenses/charges/update/${id}/`,
        charge,
        config
      );

      dispatch({
        type: DEPENSE_CHARGE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: DEPENSE_CHARGE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };
// delete depense charge
export const deleteDepenseCharge = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEPENSE_CHARGE_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    //
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    //
    const { data } = await axios.delete(
      `/depenses/charges/delete/${id}/`,
      config
    );

    dispatch({
      type: DEPENSE_CHARGE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: DEPENSE_CHARGE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get detail charge
export const getDetailDepenseCharge = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEPENSE_CHARGE_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    //
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    //
    const { data } = await axios.get(`/depenses/charges/detail/${id}/`, config);

    dispatch({
      type: DEPENSE_CHARGE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: DEPENSE_CHARGE_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// add new depense charge
export const addNewDepenseCharge = (charge) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEPENSE_CHARGE_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(`/depenses/charges/add/`, charge, config);

    dispatch({
      type: DEPENSE_CHARGE_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: DEPENSE_CHARGE_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// depense charge
export const getListDepenseCharges = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEPENSE_CHARGE_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/depenses/charges/?page=${page}`, config);

    dispatch({
      type: DEPENSE_CHARGE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: DEPENSE_CHARGE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// update entretien
export const updateEntretien =
  (id, entretien) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ENTRETIEN_UPDATE_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.put(
        `/designations/entretiens/${id}/update/`,
        entretien,
        config
      );

      dispatch({
        type: ENTRETIEN_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: ENTRETIEN_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// add entretien
export const addNewEntretien = (entretien) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ENTRETIEN_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(
      `/designations/entretiens/add/`,
      entretien,
      config
    );

    dispatch({
      type: ENTRETIEN_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: ENTRETIEN_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// delete entretien
export const deleteEntretien = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ENTRETIEN_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(
      `/designations/entretiens/${id}/delete/`,
      config
    );

    dispatch({
      type: ENTRETIEN_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: ENTRETIEN_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list entretien
export const getListEntretiens = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ENTRETIEN_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/designations/entretiens/`, config);

    dispatch({
      type: ENTRETIEN_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: ENTRETIEN_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// update charge
export const updateCharge = (id, charge) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHARGE_UPDATE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(
      `/designations/charges/${id}/update/`,
      charge,
      config
    );

    dispatch({
      type: CHARGE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CHARGE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// delete charge
export const deleteCharge = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHARGE_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(
      `/designations/charges/${id}/delete/`,
      config
    );

    dispatch({
      type: CHARGE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CHARGE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// add new charge
export const addNewCharge = (charge) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHARGE_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(
      `/designations/charges/add/`,
      charge,
      config
    );

    dispatch({
      type: CHARGE_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CHARGE_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list Charges
export const getListCharges = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHARGE_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/designations/charges/`, config);

    dispatch({
      type: CHARGE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CHARGE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
