import React, { useEffect, useRef, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import LayoutSection from "../../../components/LayoutSection";
import InputModel from "../../../components/InputModel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { toast } from "react-toastify";
import {
  addNewEmploye,
  detailEmploye,
  updateEmploye,
} from "../../../redux/actions/employeActions";
import { baseURLFile } from "../../../constants";

function EditEmployeScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();
  //
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [cinNumber, setCinNumber] = useState("");
  const [cinNumberError, setCinNumberError] = useState("");
  const [dateBirth, setDateBirth] = useState("");
  const [dateBirthError, setDateBirthError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [fonction, setFonction] = useState("");
  const [fonctionError, setFonctionError] = useState("");
  const [salaire, setSalaire] = useState("");
  const [salairError, setSalairError] = useState("");

  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const [isAdd, setIsAdd] = useState(false);
  const [eventType, setEventType] = useState("");
  const [loadEvent, setLoadEvent] = useState(false);

  const [cinRecto, setCinRecto] = useState("");
  const [errorCinRecto, setErrorCinRecto] = useState("");
  const [cinVerso, setCinVerso] = useState("");
  const [errorCinVerso, setErrorCinVerso] = useState("");

  const [cinRectoImage, setCinRectoImage] = useState("");

  const [cinVersoImage, setCinVersoImage] = useState("");

  const fileInputRefRecto = useRef(null);
  const fileInputRefVerso = useRef(null);

  //

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const employeDetail = useSelector((state) => state.detailEmploye);
  const { loading, error, success, employe } = employeDetail;

  const employeUpdate = useSelector((state) => state.updateEmploye);
  const { loadingEmployeUpdate, errorEmployeUpdate, successEmployeUpdate } =
    employeUpdate;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(detailEmploye(id));
    }
  }, [navigate, userInfo, dispatch, id]);

  useEffect(() => {
    if (employe !== undefined && employe !== null) {
      setFirstName(employe.first_name);
      setLastName(employe.last_name);

      setCinNumber(employe.cin_number);
      setDateBirth(employe.date_birth);

      setPhone(employe.phone);
      setEmail(employe.email);

      setFonction(employe.function);
      setSalaire(employe.salaire);

      setAddress(employe.address);
      setNote(employe.note);

      if (employe.cin_recto) {
        setCinRectoImage(employe.cin_recto);
      } else {
        setCinRectoImage("");
      }
      if (employe.cin_verso) {
        setCinVersoImage(employe.cin_verso);
      } else {
        setCinVersoImage("");
      }
    }
  }, [employe]);

  useEffect(() => {
    if (successEmployeUpdate) {
      setFirstName("");
      setFirstNameError("");
      setLastName("");
      setLastNameError("");

      setCinNumber("");
      setCinNumberError("");
      setDateBirth("");
      setDateBirthError("");

      setPhone("");
      setPhoneError("");
      setEmail("");
      setEmailError("");

      setFonction("");
      setFonctionError("");
      setSalaire("");
      setSalairError("");

      setAddress("");
      setAddressError("");
      setNote("");
      setNoteError("");
      dispatch(detailEmploye(id));
      setIsAdd(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successEmployeUpdate]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/settings/employes/">
            <div className="">Employés</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Nouveau</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Ajouter un nouveau employé
            </h4>
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col ">
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection title="Informations personnelles">
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Nom"
                    type="text"
                    placeholder=""
                    value={firstName}
                    onChange={(v) => setFirstName(v.target.value)}
                    error={firstNameError}
                  />
                  <InputModel
                    label="Prénom"
                    type="text"
                    placeholder=""
                    value={lastName}
                    onChange={(v) => setLastName(v.target.value)}
                    error={lastNameError}
                  />
                </div>
                {/*  */}
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="N° CIN"
                    type="text"
                    placeholder=""
                    value={cinNumber}
                    onChange={(v) => setCinNumber(v.target.value)}
                    error={cinNumberError}
                  />
                  <InputModel
                    label="Date naissance"
                    type="date"
                    placeholder=""
                    value={dateBirth}
                    onChange={(v) => {
                      setDateBirthError("");
                      setDateBirth(v.target.value);
                      if (v.target.value !== "") {
                        const providedDate = new Date(v.target.value);
                        const currentDate = new Date();

                        // Calculate the difference in milliseconds
                        const timeDifference = currentDate - providedDate;

                        // Convert milliseconds to years
                        const yearsDifference =
                          timeDifference / (1000 * 3600 * 24 * 365);

                        // Return the rounded difference
                        if (Math.floor(yearsDifference) < 14) {
                          setDateBirthError(
                            "L'employé doit avoir plus de 14 ans"
                          );
                        }
                      }
                    }}
                    error={dateBirthError}
                  />
                </div>
                {/*  */}
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Téléphone"
                    type="text"
                    placeholder=""
                    value={phone}
                    onChange={(v) => setPhone(v.target.value)}
                    error={phoneError}
                  />
                  <InputModel
                    label="Email"
                    type="text"
                    placeholder=""
                    value={email}
                    onChange={(v) => setEmail(v.target.value)}
                    error={emailError}
                  />
                </div>
                {/*  */}
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Fonction"
                    type="text"
                    placeholder=""
                    value={fonction}
                    onChange={(v) => setFonction(v.target.value)}
                    error={fonctionError}
                  />
                  <InputModel
                    label="Salaire"
                    type="number"
                    isPrice={true}
                    placeholder=""
                    value={salaire}
                    onChange={(v) => setSalaire(v.target.value)}
                    error={salairError}
                  />
                </div>
                {/*  */}
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Adresse"
                    type="textarea"
                    placeholder=""
                    value={address}
                    onChange={(v) => setAddress(v.target.value)}
                    error={addressError}
                  />
                  <InputModel
                    label="Remarque"
                    type="textarea"
                    placeholder=""
                    value={note}
                    onChange={(v) => setNote(v.target.value)}
                    error={noteError}
                  />
                </div>
              </LayoutSection>
            </div>
            {/*  */}
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection title="Photos et documents">
                <p className="text-[#898989] text-[14px] px-[5px] bg-[#FFFFFF] line-clamp-1">
                  Cin Info
                </p>
                <hr />
                <div className="flex flex-row md:my-2 md:mb-2 mb-5 ">
                  <div className=" mx-1">
                    <p className="text-[#898989] text-[14px] px-[5px] bg-[#FFFFFF] line-clamp-1">
                      Photo de face
                    </p>
                    <img
                      onClick={() => {
                        if (fileInputRefRecto.current) {
                          fileInputRefRecto.current.click();
                        }
                      }}
                      className="border w-full min-h-[1rem] max-h-[10rem] cursor-pointer update-class"
                      alt="Photo de face"
                      src={
                        cinRecto !== ""
                          ? URL.createObjectURL(cinRecto)
                          : cinRectoImage !== ""
                          ? baseURLFile + cinRectoImage
                          : "https://bvcar.rent1auto.com/web/img/default.png"
                      }
                    />
                    <div className="hidden">
                      <InputModel
                        refr={fileInputRefRecto}
                        label="Photo de face"
                        type="file"
                        placeholder=""
                        accept="image/*"
                        onChange={(v) => {
                          if (v.target.value) {
                            setCinRecto(v.target.files[0]);
                          }
                        }}
                        error={errorCinRecto}
                      />
                    </div>
                  </div>
                  <div className="  mx-1">
                    <p className="text-[#898989] text-[14px] px-[5px] bg-[#FFFFFF] line-clamp-1">
                      Photo de fond
                    </p>
                    <img
                      onClick={() => {
                        if (fileInputRefVerso.current) {
                          fileInputRefVerso.current.click();
                        }
                      }}
                      className="border w-full min-h-[1rem] max-h-[10rem] cursor-pointer update-class"
                      alt="Photo de fond"
                      src={
                        cinVerso !== ""
                          ? URL.createObjectURL(cinVerso)
                          : cinVersoImage !== ""
                          ? baseURLFile + cinVersoImage
                          : "https://bvcar.rent1auto.com/web/img/default.png"
                      }
                    />
                    <div className="hidden">
                      <InputModel
                        label="Photo de fond"
                        type="file"
                        refr={fileInputRefVerso}
                        placeholder=""
                        accept="image/*"
                        onChange={(v) => {
                          if (v.target.value) {
                            setCinVerso(v.target.files[0]);
                          }
                        }}
                        error={errorCinVerso}
                      />
                    </div>
                  </div>
                </div>
                {/*  */}
              </LayoutSection>
            </div>
          </div>
          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventType("cancel");
                setIsAdd(true);
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Annuler
            </button>
            <button
              onClick={async () => {
                var check = true;

                setFirstNameError("");
                setLastNameError("");

                setCinNumberError("");
                setDateBirthError("");

                setPhoneError("");
                setEmailError("");

                setFonctionError("");
                setSalairError("");

                setAddressError("");
                setNoteError("");

                if (firstName === "") {
                  setFirstNameError("Ce champ est requis.");
                  check = false;
                }
                if (lastName === "") {
                  setLastNameError("Ce champ est requis.");
                  check = false;
                }
                if (cinNumber === "") {
                  setCinNumberError("Ce champ est requis.");
                  check = false;
                }
                if (dateBirth === "") {
                  setDateBirthError("Ce champ est requis.");
                  check = false;
                } else {
                  const providedDate = new Date(dateBirth);
                  const currentDate = new Date();

                  // Calculate the difference in milliseconds
                  const timeDifference = currentDate - providedDate;

                  // Convert milliseconds to years
                  const yearsDifference =
                    timeDifference / (1000 * 3600 * 24 * 365);

                  // Return the rounded difference
                  if (Math.floor(yearsDifference) < 14) {
                    setDateBirthError("L'employé doit avoir plus de 14 ans");
                    check = false;
                  }
                }
                if (phone === "") {
                  setPhoneError("Ce champ est requis.");
                  check = false;
                }
                if (email === "") {
                  setEmailError("Ce champ est requis.");
                  check = false;
                }

                if (fonction === "") {
                  setFonctionError("Ce champ est requis.");
                  check = false;
                }
                if (salaire === "" || salaire <= 0) {
                  setSalairError("Ce champ est requis.");
                  check = false;
                }

                if (check) {
                  setEventType("add");
                  setIsAdd(true);
                } else {
                  toast.error(
                    "Certains champs sont obligatoires veuillez vérifier"
                  );
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                />
              </svg>
              Modifié
            </button>
          </div>
        </div>
        <ConfirmationModal
          isOpen={isAdd}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Êtes-vous sûr de vouloir Modifé cette Employé ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setFirstName("");
              setFirstNameError("");
              setLastName("");
              setLastNameError("");

              setCinNumber("");
              setCinNumberError("");
              setDateBirth("");
              setDateBirthError("");

              setPhone("");
              setPhoneError("");
              setEmail("");
              setEmailError("");

              setFonction("");
              setFonctionError("");
              setSalaire("");
              setSalairError("");

              setAddress("");
              setAddressError("");
              setNote("");
              setNoteError("");
              setCinRecto("");
              setCinVerso("");
              dispatch(detailEmploye(id));
              setIsAdd(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                updateEmploye(id, {
                  first_name: firstName,
                  last_name: lastName,
                  date_birth: dateBirth,
                  cin_number: cinNumber,
                  cin_recto: cinRecto,
                  cin_verso: cinVerso,
                  gsm_phone: phone,
                  phone: phone,
                  email: email,
                  note: note,
                  address: address,
                  salaire: salaire,
                  function: fonction,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAdd(false);
            }
          }}
          onCancel={() => {
            setIsAdd(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default EditEmployeScreen;
