import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import CountrySelector from "../../components/Selector";
import { COUNTRIES } from "../../constants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addNewClient, clientList } from "../../redux/actions/clientActions";
import LayoutSection from "../../components/LayoutSection";
import { getListAgences } from "../../redux/actions/agenceActions";
import { getMarqueList } from "../../redux/actions/marqueActions";
import { getModelList } from "../../redux/actions/modelActions";
import InputModel from "../../components/InputModel";
import { addNewCar, getListCars } from "../../redux/actions/carActions";
import ConfirmationModal from "../../components/ConfirmationModal";
import { addNewReservation } from "../../redux/actions/reservationActions";
import { addNewContrats } from "../../redux/actions/contratActions";

function AddContratScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  //
  const [clientSelect, setClientSelect] = useState("");
  const [clientSelectError, setClientSelectError] = useState("");
  const [conducteurSelect, setConducteurSelect] = useState("");
  const [conducteurSelectError, setConducteurSelectError] = useState("");
  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const [carSelect, setCarSelect] = useState("");
  const [carSelectError, setCarSelectError] = useState("");
  const [priceDay, setPriceDay] = useState(0);
  const [priceDayError, setPriceDayError] = useState("");
  const [departKm, setDepartKm] = useState(0);
  const [departKmError, setDepartKmError] = useState("");
  const [carburantSelect, setCarburantSelect] = useState(0);
  const [carburantSelectError, setCarburantSelectError] = useState("");
  const [franchise, setFranchise] = useState(0);
  const [franchiseError, setFranchiseError] = useState("");

  const [startDate, setStartDate] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [nbrDays, setNbrDays] = useState(0);
  const [nbrDaysError, setNbrDaysError] = useState("");
  const [deliveryPlace, setDeliveryPlace] = useState("");
  const [deliveryPlaceError, setDeliveryPlaceError] = useState("");
  const [returnPlace, setReturnPlace] = useState("");
  const [returnPlaceError, setReturnPlaceError] = useState("");
  const [prolongement, setProlongement] = useState(0);
  const [prolongementError, setProlongementError] = useState("");

  //

  const [avanceType, setAvanceType] = useState("");
  const [avanceTypeError, setAvanceTypeError] = useState("");
  const [avanceMontant, setAvanceMontant] = useState(0);
  const [avanceMontantError, setAvanceMontantError] = useState("");

  const [totalCar, setTotalCar] = useState(0);
  const [montantTotal, setMontantTotal] = useState(0);

  //

  const [isAddCar, setIsAddCar] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");

  const [isWithCar, setIsWithCar] = useState(true);
  const [carModel, setCarModel] = useState("");
  const [carModelError, setCarModelError] = useState("");
  const [carCarburent, setCarCarburent] = useState("");
  const [carCarburentError, setCarCarburentError] = useState("");
  const [carTransmission, setCarTransmission] = useState("");
  const [carTransmissionError, setCarTransmissionError] = useState("");
  const [carClimatiseur, setCarClimatiseur] = useState("");
  const [carClimatiseurError, setCarClimatiseurError] = useState("");

  //

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const listClient = useSelector((state) => state.clientList);
  const { clients } = listClient;

  const listCar = useSelector((state) => state.carList);
  const { cars } = listCar;

  const listAgence = useSelector((state) => state.agenceList);
  const { agences } = listAgence;

  const addContrat = useSelector((state) => state.createNewContrat);
  const { loadingContratAdd, errorContratAdd, successContratAdd } = addContrat;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListCars("0"));
      dispatch(clientList("0", "", "", "", "", "", "", ""));
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (successContratAdd) {
      setClientSelect("");
      setClientSelectError("");
      setConducteurSelect("");
      setConducteurSelectError("");
      setNote("");
      setNoteError("");

      setCarSelect("");
      setCarSelectError("");
      setPriceDay(0);
      setPriceDayError("");
      setDepartKm(0);
      setDepartKmError("");
      setCarburantSelect(0);
      setCarburantSelectError("");
      setFranchise(0);
      setFranchiseError("");

      setStartDate("");
      setStartDateError("");
      setEndDate("");
      setEndDateError("");
      setNbrDays(0);
      setNbrDaysError("");
      setDeliveryPlace("");
      setDeliveryPlaceError("");
      setReturnPlace("");
      setReturnPlaceError("");
      setProlongement(0);
      setProlongementError("");

      setAvanceType("");
      setAvanceTypeError("");
      setAvanceMontant(0);
      setAvanceMontantError("");

      setTotalCar(0);
      setMontantTotal(0);

      setIsWithCar(true);
      setCarModel("");
      setCarModelError("");
      setCarCarburent("");
      setCarCarburentError("");
      setCarTransmission("");
      setCarTransmissionError("");
      setCarClimatiseur("");
      setCarClimatiseurError("");

      setIsAddCar(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successContratAdd]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/contrats/">
            <div className="">Contrat</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Nouveau</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Ajouter un nouveau contrat
            </h4>
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col ">
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection title="Client">
                {/* client */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Client"
                    type="select"
                    placeholder="Client"
                    value={clientSelect}
                    onChange={(v) => setClientSelect(v.target.value)}
                    error={clientSelectError}
                    options={clients?.map((client) => ({
                      value: client.id,
                      label:
                        (client.first_name ?? "---") +
                        " " +
                        (client.last_name ?? ""),
                    }))}
                  />
                </div>
                {/* 2m conducteur */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="2eme Conducteur"
                    type="select"
                    placeholder="2eme Conducteur"
                    value={conducteurSelect}
                    onChange={(v) => setConducteurSelect(v.target.value)}
                    error={conducteurSelectError}
                    options={clients?.map((client) => ({
                      value: client.id,
                      label:
                        (client.first_name ?? "---") +
                        " " +
                        (client.last_name ?? ""),
                    }))}
                  />
                </div>
                {/* note */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Note contrat"
                    type="textarea"
                    placeholder=""
                    value={note}
                    onChange={(v) => {
                      setNote(v.target.value);
                    }}
                    error={noteError}
                  />
                </div>
              </LayoutSection>
            </div>
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection title="Voiture">
                {/*  */}
                <div
                  onClick={() => {
                    setIsWithCar(!isWithCar);
                    setCarSelect("");
                    setPriceDay(0);
                    setCarModel("");
                    setCarCarburent("");
                    setCarTransmission("");
                    setCarClimatiseur("");
                  }}
                  className="py-3 flex cursor-pointer"
                >
                  <input type="checkbox" checked={!isWithCar} />
                  <p className="px-2">
                    Sans voiture
                    {/* {isWithCar === true ? "true" : "false"} */}
                  </p>
                </div>
                {/*  */}
                {isWithCar ? (
                  <>
                    <div className="md:py-2 md:flex">
                      <InputModel
                        label="Voiture"
                        type="select"
                        placeholder="Voiture"
                        value={carSelect}
                        onChange={(v) => {
                          setCarSelect(v.target.value);
                          setPriceDay(0);
                        }}
                        error={carSelectError}
                        options={cars?.map((car) => ({
                          value: car.id,
                          label:
                            (car.marque.marque_car ?? "---") +
                            " " +
                            (car.model.model_car ?? "") +
                            (car.agence ? " (" + car.agence.name + ") " : ""),
                        }))}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="md:py-2 md:flex">
                      <InputModel
                        label="Voiture"
                        type="select"
                        placeholder="Voiture"
                        value={carModel}
                        onChange={(v) => {
                          setCarModel(v.target.value);
                          setPriceDay(0);
                        }}
                        error={carModelError}
                        options={[
                          "Dacia Logan",
                          "Dacia Duster",
                          "Dacia Sandero",
                          "Renault Clio 4",
                          "Renault Megane",
                          "Hyundai I30",
                          "Hyundai I10",
                          "Kia Picanto",
                          "Kia Ceed",
                          "Peugeot 301",
                          "Toyota Auris",
                          "Toyota Rav 4",
                          "Toyota Yaris",
                          "VW Polo",
                          "VW Touareg",
                          "VW Golf 7",
                          "Seat Ibiza",
                          "Seat Leon",
                          "Opel Astra",
                          "Opel Corsa",
                          "VW Touran",
                          "VW Tigan",
                          "Nissan Land cruiser",
                          "Citroën C3",
                          "Citroën C4",
                          "Fiat Punto",
                          "Fiat Bravo",
                          "Skoda Octavia",
                          "Renault Symbol",
                          "Hyundai Santa fe",
                          "Hyundai I40",
                          "Dacia Lodgy",
                          "Renault Captur",
                          "Renault Kadjar",
                          "Peugeot 308",
                          "Kia Optima",
                          "Kia Sportage",
                          "Opel Insignia",
                          "Fiat 500",
                          "Fiat 500L",
                          "Fiat Panda",
                          "Citroën DS3",
                          "Honda Civic",
                          "Honda Fit",
                          "Honda CRV",
                          "Honda Jazz",
                          "Chevrolet Camaro",
                          "Chevrolet Spark",
                          "Chevrolet Cruse",
                          "Chevrolet Captiva",
                          "Jeep CHerokee",
                          "Jeep Grand Cherokee",
                          "Kia Rio",
                          "AUDI Q5",
                          "AUDI Q7",
                          "AUDI A6",
                          "AUDI A5",
                          "Suzuki Splash",
                          "Suzuki Swift",
                          "Suzuki Sx4",
                          "Mercedes CLA 220",
                          "AUDI TT",
                          "Renault Kangoo",
                          "Hyundai Elantra",
                          "Hyundai Accent",
                          "Hyundai I20",
                          "Range rover Evoque",
                          "Renault Clio 5",
                          "Seat Ateca",
                          "Dacia Streetway",
                          "Alpharomeo Joletta",
                        ].map((car, index) => ({
                          value: car,
                          label: car,
                        }))}
                      />
                      <InputModel
                        label="Carburant"
                        type="select"
                        placeholder="Carburant"
                        value={carCarburent}
                        onChange={(v) => {
                          setCarCarburent(v.target.value);
                        }}
                        error={carCarburentError}
                        options={["Essence", "Diesel"].map(
                          (carburant, index) => ({
                            value: carburant,
                            label: carburant,
                          })
                        )}
                      />
                    </div>

                    <div className="md:py-2 md:flex">
                      <InputModel
                        label="Transmission"
                        type="select"
                        placeholder="Transmission"
                        value={carTransmission}
                        onChange={(v) => {
                          setCarTransmission(v.target.value);
                        }}
                        error={carTransmissionError}
                        options={["Manuelle", "Automatique"].map(
                          (transmission, index) => ({
                            value: transmission,
                            label: transmission,
                          })
                        )}
                      />
                      <InputModel
                        label="Climatiseur"
                        type="select"
                        placeholder="Climatiseur"
                        value={carClimatiseur}
                        onChange={(v) => {
                          setCarClimatiseur(v.target.value);
                        }}
                        error={carClimatiseurError}
                        options={["Oui", "Non"].map((climatiseur, index) => ({
                          value: climatiseur,
                          label: climatiseur,
                        }))}
                      />
                    </div>
                  </>
                )}

                {/* Départ and price */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Départ"
                    type="number"
                    placeholder=""
                    value={departKm}
                    onChange={(v) => {
                      setDepartKm(v.target.value);
                    }}
                    error={departKmError}
                  />
                  <InputModel
                    label="Prix/Jour"
                    type="number"
                    isPrice={true}
                    placeholder=""
                    value={priceDay}
                    disabled={carSelect === "" && isWithCar}
                    onChange={(v) => {
                      setPriceDay(v.target.value);

                      if (
                        isNaN(parseFloat(v.target.value)) ||
                        v.target.value === "" ||
                        v.target.value === 0
                      ) {
                        setPriceDayError("Ce champ est requis.");
                        // setPriceDay(0);
                      } else if (carSelect === "" && isWithCar) {
                        setCarSelectError("Ce champ est requis.");
                        setPriceDay(0);
                      }
                    }}
                    error={priceDayError}
                  />
                </div>
                {/* carburant and franchise */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Carburant"
                    type="select"
                    placeholder="Carburant"
                    value={carburantSelect}
                    onChange={(v) => {
                      setCarburantSelect(v.target.value);
                    }}
                    error={carburantSelectError}
                    options={[
                      { value: "1", label: "1" },
                      { value: "0.75", label: "3/4" },
                      { value: "0.5", label: "1/2" },
                      { value: "0.25", label: "1/4" },
                      { value: "0", label: "0" },
                    ]}
                  />
                  <InputModel
                    label="Franchise"
                    type="number"
                    isPrice={true}
                    placeholder=""
                    value={franchise}
                    onChange={(v) => {
                      setFranchise(v.target.value);
                    }}
                    error={franchiseError}
                  />
                </div>
                {/*  */}
              </LayoutSection>
            </div>
          </div>
          <div className="flex md:flex-row flex-col ">
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection title="Location">
                <div className="md:py-2 md:flex items-center ">
                  <div className="md:w-2/3 ">
                    <div className="md:py-2 ">
                      <InputModel
                        label="Date début"
                        type="datetime-local"
                        placeholder="Date début"
                        value={startDate}
                        onChange={(v) => {
                          setStartDate(v.target.value);
                          if (v.target.value !== "") {
                            //
                            const selectedDateTime = new Date(v.target.value);
                            const nextDay = new Date(selectedDateTime);
                            nextDay.setDate(selectedDateTime.getDate() + 1);

                            // Formatting the date to match the input's format (yyyy-mm-ddThh:mm)
                            const formattedNextDay = nextDay
                              .toISOString()
                              .slice(0, 16);

                            setEndDate(formattedNextDay);
                            setNbrDays(1);
                            if (!isNaN(parseFloat(priceDay))) {
                              setTotalCar(
                                (parseFloat(priceDay) * 1).toFixed(2)
                              );
                            }
                          } else {
                            setEndDate("");
                            setNbrDays(0);
                          }
                        }}
                        error={startDateError}
                      />
                    </div>
                    <div className="md:py-2 ">
                      <InputModel
                        label="Date fin"
                        type="datetime-local"
                        placeholder="Date fin"
                        value={endDate}
                        disabled={startDate === ""}
                        onChange={(v) => {
                          setStartDateError("");
                          setEndDateError("");

                          if (startDate === "") {
                            setStartDateError("Ce champ est requis.");
                            setNbrDays(0);
                          } else if (v.target.value === "") {
                            setEndDateError("Ce champ est requis.");
                            const selectedDateTime = new Date(startDate);
                            const nextDay = new Date(selectedDateTime);
                            nextDay.setDate(selectedDateTime.getDate() + 1);

                            // Formatting the date to match the input's format (yyyy-mm-ddThh:mm)
                            const formattedNextDay = nextDay
                              .toISOString()
                              .slice(0, 16);

                            setEndDate(formattedNextDay);
                            setNbrDays(1);
                          } else {
                            setEndDate(v.target.value);

                            const start = new Date(startDate);
                            const end = new Date(v.target.value);

                            // Calculate the difference in milliseconds
                            const differenceInMs = Math.abs(end - start);

                            // Convert milliseconds to days
                            const differenceInDays = Math.ceil(
                              differenceInMs / (1000 * 60 * 60 * 24)
                            );

                            setNbrDays(differenceInDays);
                            if (!isNaN(parseFloat(priceDay))) {
                              setTotalCar(
                                (
                                  parseFloat(priceDay) *
                                  parseInt(differenceInDays)
                                ).toFixed(2)
                              );
                            }
                          }
                        }}
                        error={endDateError}
                      />
                    </div>
                  </div>
                  <InputModel
                    label="NJ"
                    type="number"
                    placeholder="NJ"
                    disabled={startDate === ""}
                    value={nbrDays}
                    onChange={(v) => {
                      setNbrDaysError("");
                      setStartDateError("");
                      if (startDate === "") {
                        setStartDateError("Ce champ est requis.");
                        setNbrDays(0);
                      } else {
                        setNbrDays(v.target.value);
                        const isNotInt = !Number.isInteger(
                          parseFloat(v.target.value)
                        );
                        const hasE = v.target.value.toLowerCase().includes("e");

                        if ((isNotInt && v.target.value !== "") || hasE) {
                          setNbrDaysError("Cette valeur doit être un entier.");
                        } else {
                          const selectedDateTime = new Date(startDate);
                          const nextDay = new Date(selectedDateTime);
                          nextDay.setDate(
                            selectedDateTime.getDate() +
                              parseInt(v.target.value)
                          );

                          // Formatting the date to match the input's format (yyyy-mm-ddThh:mm)
                          const formattedNextDay = nextDay
                            .toISOString()
                            .slice(0, 16);

                          setEndDate(formattedNextDay);
                          if (!isNaN(parseFloat(priceDay))) {
                            setTotalCar(
                              (
                                parseFloat(priceDay) * parseInt(v.target.value)
                              ).toFixed(2)
                            );
                          }
                        }
                      }
                    }}
                    error={nbrDaysError}
                  />
                </div>
                {/* delivery */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Lieu de livraison"
                    type="select"
                    placeholder="Lieu de livraison"
                    value={deliveryPlace}
                    onChange={(v) => setDeliveryPlace(v.target.value)}
                    error={deliveryPlaceError}
                    options={[
                      { value: "Agence Nador", label: "Agence Nador" },
                      {
                        value: "Aeroport Casablanca",
                        label: "Aeroport Casablanca",
                      },
                      { value: "Aeroport Rabat", label: "Aeroport Rabat" },
                      { value: "Aeroport Tanger", label: "Aeroport Tanger" },
                      {
                        value: "Aeroport Marrakech",
                        label: "Aeroport Marrakech",
                      },
                      { value: "Aeroport Agadir", label: "Aeroport Agadir" },
                      { value: "Aeroport Fes", label: "Aeroport Fes" },
                      {
                        value: "Meknes centre ville",
                        label: "Meknes centre ville",
                      },
                      { value: "Aeroport Oujda", label: "Aeroport Oujda" },
                      { value: "Fes centre ville", label: "Fes centre ville" },
                      {
                        value: "Agadir centre ville",
                        label: "Agadir centre ville",
                      },
                      {
                        value: "Marrakech centre ville",
                        label: "Marrakech centre ville",
                      },
                      {
                        value: "Tanger centre ville",
                        label: "Tanger centre ville",
                      },
                      {
                        value: "Eljadida centre ville",
                        label: "Eljadida centre ville",
                      },
                      {
                        value: "Mohamedia centre Ville",
                        label: "Mohamedia centre Ville",
                      },
                    ]}
                  />
                  <InputModel
                    label="Lieu de retour"
                    type="select"
                    placeholder="Lieu de retour"
                    value={returnPlace}
                    onChange={(v) => setReturnPlace(v.target.value)}
                    error={returnPlaceError}
                    options={[
                      { value: "Agence Nador", label: "Agence Nador" },
                      {
                        value: "Aeroport Casablanca",
                        label: "Aeroport Casablanca",
                      },
                      { value: "Aeroport Rabat", label: "Aeroport Rabat" },
                      { value: "Aeroport Tanger", label: "Aeroport Tanger" },
                      {
                        value: "Aeroport Marrakech",
                        label: "Aeroport Marrakech",
                      },
                      { value: "Aeroport Agadir", label: "Aeroport Agadir" },
                      { value: "Aeroport Fes", label: "Aeroport Fes" },
                      {
                        value: "Meknes centre ville",
                        label: "Meknes centre ville",
                      },
                      { value: "Aeroport Oujda", label: "Aeroport Oujda" },
                      { value: "Fes centre ville", label: "Fes centre ville" },
                      {
                        value: "Agadir centre ville",
                        label: "Agadir centre ville",
                      },
                      {
                        value: "Marrakech centre ville",
                        label: "Marrakech centre ville",
                      },
                      {
                        value: "Tanger centre ville",
                        label: "Tanger centre ville",
                      },
                      {
                        value: "Eljadida centre ville",
                        label: "Eljadida centre ville",
                      },
                      {
                        value: "Mohamedia centre Ville",
                        label: "Mohamedia centre Ville",
                      },
                    ]}
                  />
                </div>
                {/*  */}
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Prolongement"
                    type="select"
                    placeholder="Prolongement"
                    value={prolongement}
                    onChange={(v) => setProlongement(v.target.value)}
                    error={prolongementError}
                    options={[
                      { value: 0, label: "Interdit" },
                      {
                        value: 24,
                        label: "24 heurs",
                      },
                      { value: 48, label: "48 heurs" },
                      {
                        value: 72,
                        label: "72 heurs",
                      },
                    ]}
                  />
                </div>

                {/*  */}
              </LayoutSection>
            </div>
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection title="Paiement & Facturation">
                {/*  */}
                <div className="md:py-2 md:flex mt-3">
                  <InputModel
                    label="Type"
                    type="select"
                    placeholder=""
                    value={avanceType}
                    onChange={(v) => setAvanceType(v.target.value)}
                    error={avanceTypeError}
                    options={[
                      { value: "Espece", label: "Espece" },
                      { value: "Cheque", label: "Cheque" },
                      { value: "Carte de credit", label: "Carte de credit" },
                      { value: "Virement", label: "Virement" },
                      {
                        value: "Paiement international",
                        label: "Paiement international",
                      },
                    ]}
                  />
                  <InputModel
                    label="Montant"
                    type="number"
                    placeholder=""
                    isPrice={true}
                    disabled={avanceType === ""}
                    value={avanceMontant}
                    onChange={(v) => setAvanceMontant(v.target.value)}
                    error={avanceMontantError}
                  />
                </div>
                {/*  */}
                <div className="md:py-2 md:flex mt-3">
                  <InputModel
                    label="Prix voiture"
                    type="number"
                    placeholder=""
                    isPrice={true}
                    disabled={true}
                    value={priceDay}
                    onChange={(v) => setPriceDay(v.target.value)}
                    error={""}
                  />
                  <InputModel
                    label="Nombre de jours"
                    type="number"
                    placeholder=""
                    isPrice={true}
                    disabled={true}
                    value={nbrDays}
                    onChange={(v) => setNbrDays(v.target.value)}
                    error={""}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Montant total"
                    type="number"
                    placeholder=""
                    isPrice={true}
                    disabled={true}
                    value={(parseFloat(priceDay) * parseInt(nbrDays)).toFixed(
                      2
                    )}
                    onChange={(v) => setTotalCar(v.target.value)}
                    error={""}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Montant Restant"
                    type="number"
                    placeholder=""
                    isPrice={true}
                    disabled={true}
                    value={(
                      parseFloat(totalCar) - parseFloat(avanceMontant)
                    ).toFixed(2)}
                    onChange={(v) => {}}
                    error={""}
                  />
                </div>
                {/*  */}
              </LayoutSection>
            </div>
          </div>
          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventType("cancel");
                setIsAddCar(true);
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Annuler
            </button>
            <button
              onClick={async () => {
                var check = true;

                setClientSelectError("");
                setConducteurSelectError("");
                setNoteError("");
                setCarSelectError("");
                setPriceDayError("");
                setDepartKmError("");
                setCarburantSelectError("");
                setFranchiseError("");
                setStartDateError("");
                setEndDateError("");
                setNbrDaysError("");
                setDeliveryPlaceError("");
                setReturnPlaceError("");
                setProlongementError("");
                setAvanceTypeError("");
                setAvanceMontantError("");

                setCarModelError("");
                setCarCarburentError("");
                setCarTransmissionError("");
                setCarClimatiseurError("");

                if (clientSelect === "") {
                  setClientSelectError("Ce champ est requis.");
                  check = false;
                }
                if (isWithCar) {
                  if (carSelect === "") {
                    setCarSelectError("Ce champ est requis.");
                    check = false;
                  }
                } else {
                  if (carModel === "") {
                    setCarModelError("Ce champ est requis.");
                    check = false;
                  }

                  if (carCarburent === "") {
                    setCarCarburentError("Ce champ est requis.");
                    check = false;
                  }
                  if (carTransmission === "") {
                    setCarTransmissionError("Ce champ est requis.");
                    check = false;
                  }
                  if (carClimatiseur === "") {
                    setCarClimatiseurError("Ce champ est requis.");
                    check = false;
                  }
                }

                if (priceDay === "" || priceDay === 0) {
                  setPriceDayError("Ce champ est requis.");
                  check = false;
                }

                if (startDate === "") {
                  setStartDateError("Ce champ est requis.");
                  check = false;
                }
                if (endDate === "") {
                  setEndDateError("Ce champ est requis.");
                  check = false;
                }

                if (deliveryPlace === "") {
                  setDeliveryPlaceError("Ce champ est requis.");
                  check = false;
                }
                if (returnPlace === "") {
                  setReturnPlaceError("Ce champ est requis.");
                  check = false;
                }

                if (avanceMontant !== "" && totalCar !== "") {
                  if (parseFloat(avanceMontant) > parseFloat(totalCar)) {
                    setAvanceMontantError("Ce champ est requis.");
                    check = false;
                  }
                }

                if (check) {
                  setEventType("add");
                  setIsAddCar(true);
                } else {
                  toast.error(
                    "Certains champs sont obligatoires veuillez vérifier"
                  );
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Ajouter
            </button>
          </div>
        </div>
        <ConfirmationModal
          isOpen={isAddCar}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Êtes-vous sûr de vouloir ajouter cette Voiture ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setClientSelect("");
              setClientSelectError("");
              setConducteurSelect("");
              setConducteurSelectError("");
              setNote("");
              setNoteError("");

              setCarSelect("");
              setCarSelectError("");
              setPriceDay(0);
              setPriceDayError("");
              setDepartKm(0);
              setDepartKmError("");
              setCarburantSelect(0);
              setCarburantSelectError("");
              setFranchise(0);
              setFranchiseError("");

              setStartDate("");
              setStartDateError("");
              setEndDate("");
              setEndDateError("");
              setNbrDays(0);
              setNbrDaysError("");
              setDeliveryPlace("");
              setDeliveryPlaceError("");
              setReturnPlace("");
              setReturnPlaceError("");
              setProlongement(0);
              setProlongementError("");

              setAvanceType("");
              setAvanceTypeError("");
              setAvanceMontant(0);
              setAvanceMontantError("");

              setTotalCar(0);
              setMontantTotal(0);

              setIsWithCar(true);
              setCarModel("");
              setCarModelError("");
              setCarCarburent("");
              setCarCarburentError("");
              setCarTransmission("");
              setCarTransmissionError("");
              setCarClimatiseur("");
              setCarClimatiseurError("");

              setIsAddCar(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);

              await dispatch(
                addNewContrats({
                  client: clientSelect,
                  car: carSelect,
                  start_date: startDate,
                  end_date: endDate,
                  nbr_day: nbrDays,
                  delivery_place: deliveryPlace,
                  return_place: returnPlace,
                  price_day: priceDay,
                  price_total: totalCar,
                  price_rest: totalCar - avanceMontant,
                  price_avance: avanceMontant,
                  type_avance: avanceType,
                  //
                  client: clientSelect,
                  conducteur: conducteurSelect,
                  car: isWithCar === true ? carSelect : "",
                  depart_km: departKm,
                  price_day: priceDay,
                  carburant: carburantSelect,
                  franchise: franchise,
                  start_date: startDate,
                  end_date: endDate,
                  nbr_day: nbrDays,
                  delivery_place: deliveryPlace,
                  return_place: returnPlace,
                  prolongement: prolongement,
                  price_total: totalCar,
                  price_rest: totalCar - avanceMontant,
                  price_avance: avanceMontant,
                  type_avance: avanceType,
                  type_payment: "Espece",
                  is_withcar: isWithCar === true ? "True" : "False",
                  model_car: isWithCar === true ? "" : carModel,
                  carburant_car: isWithCar === true ? "" : carCarburent,
                  transmition_car: isWithCar === true ? "" : carTransmission,
                  climatiseur_car: isWithCar === true ? "" : carClimatiseur,
                  note: note,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAddCar(false);
            }
          }}
          onCancel={() => {
            setIsAddCar(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />

        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default AddContratScreen;
