import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import LayoutSection from "../../components/LayoutSection";
import InputModel from "../../components/InputModel";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import {
  addNewAgence,
  getDetailAgence,
  updateAgence,
} from "../../redux/actions/agenceActions";
import Alert from "../../components/Alert";

function EditAgenceScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { id } = useParams();

  //
  const [agenceName, setAgenceName] = useState("");
  const [agenceNameError, setAgenceNameError] = useState("");

  const [responsable, setResponsable] = useState("");
  const [responsableError, setResponsableError] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");

  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const [isAdd, setIsAdd] = useState(false);
  const [eventType, setEventType] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  //

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const agenceUpdate = useSelector((state) => state.updateAgence);
  const { loadingAgenceUpdate, errorAgenceUpdate, successAgenceUpdate } =
    agenceUpdate;

  const agenceDetail = useSelector((state) => state.getDetailAgence);
  const {
    loadingAgenceDetail,
    errorAgenceDetail,
    successAgenceDetail,
    agence,
  } = agenceDetail;

  //
  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getDetailAgence(id));
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (agence !== undefined && agence !== null) {
      setAgenceName(agence.name);

      setResponsable(agence.responsable);

      setPhone(agence.phone);

      setEmail(agence.email);

      setAddress(agence.address);

      setNote(agence.note);
    }
  }, [agence]);

  useEffect(() => {
    if (successAgenceUpdate) {
      setAgenceName("");
      setAgenceNameError("");

      setResponsable("");
      setResponsableError("");

      setPhone("");
      setPhoneError("");

      setEmail("");
      setEmailError("");

      setAddress("");
      setAddressError("");

      setNote("");
      setNoteError("");

      dispatch(getDetailAgence(id));

      setIsAdd(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successAgenceUpdate]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/agences/">
            <div className="">Agences</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Nouveau</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Ajouter un nouveau agence
            </h4>
          </div>
          <div>
            {errorAgenceDetail ? (
              <Alert type="error" message={errorAgenceDetail} />
            ) : null}
          </div>
          <div>
            {errorAgenceUpdate ? (
              <Alert type="error" message={errorAgenceUpdate} />
            ) : null}
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col ">
            <div className=" w-full px-1 py-1">
              <LayoutSection title="Informations d'agence">
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Nom d'agence"
                    type="text"
                    placeholder=""
                    value={agenceName}
                    onChange={(v) => setAgenceName(v.target.value)}
                    error={agenceNameError}
                  />
                  <InputModel
                    label="Résponsable"
                    type="text"
                    placeholder=""
                    value={responsable}
                    onChange={(v) => setResponsable(v.target.value)}
                    error={responsableError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Numéro de téléphone"
                    type="text"
                    placeholder=""
                    value={phone}
                    onChange={(v) => setPhone(v.target.value)}
                    error={phoneError}
                  />
                  <InputModel
                    label="Email"
                    type="text"
                    placeholder=""
                    value={email}
                    onChange={(v) => setEmail(v.target.value)}
                    error={emailError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Adresse"
                    type="textarea"
                    placeholder=""
                    value={address}
                    onChange={(v) => setAddress(v.target.value)}
                    error={addressError}
                  />
                  <InputModel
                    label="Remarque"
                    type="textarea"
                    placeholder=""
                    value={note}
                    onChange={(v) => setNote(v.target.value)}
                    error={noteError}
                  />
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventType("cancel");
                setIsAdd(true);
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Annuler
            </button>
            <button
              onClick={async () => {
                var check = true;
                setAgenceNameError("");
                setResponsableError("");
                setPhoneError("");
                setEmailError("");
                setAddressError("");
                setNoteError("");

                if (agenceName === "") {
                  setAgenceNameError("Ce champ est requis.");
                  check = false;
                }
                if (responsable === "") {
                  setResponsableError("Ce champ est requis.");
                  check = false;
                }
                if (phone === "") {
                  setPhoneError("Ce champ est requis.");
                  check = false;
                }
                if (email === "") {
                  setEmailError("Ce champ est requis.");
                  check = false;
                }
                if (address === "") {
                  setAddressError("Ce champ est requis.");
                  check = false;
                }

                if (check) {
                  setEventType("update");
                  setIsAdd(true);
                } else {
                  toast.error(
                    "Certains champs sont obligatoires veuillez vérifier"
                  );
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                />
              </svg>
              Modifié
            </button>
          </div>
        </div>
        <ConfirmationModal
          isOpen={isAdd}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Êtes-vous sûr de vouloir modifé cette Agence ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setAgenceName("");
              setAgenceNameError("");

              setResponsable("");
              setResponsableError("");

              setPhone("");
              setPhoneError("");

              setEmail("");
              setEmailError("");

              setAddress("");
              setAddressError("");

              setNote("");
              setNoteError("");

              dispatch(getDetailAgence(id));

              setIsAdd(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                updateAgence(id, {
                  agence_name: agenceName,
                  responsable: responsable,
                  phone: phone,
                  email: email,
                  address: address,
                  note: note,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAdd(false);
            }
          }}
          onCancel={() => {
            setIsAdd(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default EditAgenceScreen;
