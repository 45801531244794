import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function LogoutScreen() {
  useEffect(() => {
    localStorage.removeItem("userInfoTayssir");
    document.location.href = "/";
  }, []);
  return <div></div>;
}

export default LogoutScreen;
