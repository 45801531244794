import { toast } from "react-toastify";

import {
  DASH_LIST_REQUEST,
  DASH_LIST_SUCCESS,
  DASH_LIST_FAIL,

  //
} from "../constants/dashConstants";

export const getDashDataReducer = (
  state = {
    alert: 0,
    notvalid: 0,
    valid: 0,
    desponible: 0,
    notdesponible: 0,
    assurances: [],
    cartgris: [],
    visitetechniques: [],
  },
  action
) => {
  switch (action.type) {
    case DASH_LIST_REQUEST:
      return {
        loadingDashData: true,
        alert: 0,
        notvalid: 0,
        valid: 0,
        desponible: 0,
        notdesponible: 0,
        assurances: [],
        cartgris: [],
        visitetechniques: [],
      };
    case DASH_LIST_SUCCESS:
      return {
        loadingDashData: false,
        alert: action.payload.alert,
        notvalid: action.payload.notvalid,
        valid: action.payload.valid,
        desponible: action.payload.desponible,
        notdesponible: action.payload.notdesponible,
        assurances: action.payload.assurances,
        cartgris: action.payload.cartgris,
        visitetechniques: action.payload.visitetechniques,
      };
    case DASH_LIST_FAIL:
      return {
        loadingDashData: false,
        errorDashData: action.payload,
        alert: 0,
        notvalid: 0,
        valid: 0,
        desponible: 0,
        notdesponible: 0,
        assurances: [],
        cartgris: [],
        visitetechniques: [],
      };
    default:
      return state;
  }
};
