import axios from "../../axios";
import {
  CAR_LIST_REQUEST,
  CAR_LIST_SUCCESS,
  CAR_LIST_FAIL,
  //
  CAR_ADD_REQUEST,
  CAR_ADD_SUCCESS,
  CAR_ADD_FAIL,
  //
  CAR_DETAIL_REQUEST,
  CAR_DETAIL_SUCCESS,
  CAR_DETAIL_FAIL,
  //
  CAR_UPDATE_REQUEST,
  CAR_UPDATE_SUCCESS,
  CAR_UPDATE_FAIL,
  //
  CAR_DELETE_REQUEST,
  CAR_DELETE_SUCCESS,
  CAR_DELETE_FAIL,
} from "../constants/carConstants";

// delete car
export const deleteCar = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(`/cars/delete/${id}/`, config);

    dispatch({
      type: CAR_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CAR_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// update car
export const updateCar = (id, car) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_UPDATE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.put(`/cars/update/${id}/`, car, config);

    dispatch({
      type: CAR_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CAR_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// detail car
export const detailCar = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/cars/detail/${id}/`, config);

    dispatch({
      type: CAR_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CAR_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// add new client
export const addNewCar = (car) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(`/cars/add/`, car, config);

    dispatch({
      type: CAR_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CAR_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list cars
export const getListCars = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAR_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/cars/?page=${page}`, config);

    dispatch({
      type: CAR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CAR_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
