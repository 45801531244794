import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DefaultLayout from "../../../layouts/DefaultLayout";
import InputModel from "../../../components/InputModel";
import LayoutSection from "../../../components/LayoutSection";
import {
  addNewCharge,
  addNewEntretien,
  deleteCharge,
  deleteEntretien,
  getListCharges,
  getListEntretiens,
  updateCharge,
  updateEntretien,
} from "../../../redux/actions/designationActions";
import Loader from "../../../components/Loader";
import Alert from "../../../components/Alert";
import { WithContext as ReactTags } from "react-tag-input";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../components/ConfirmationModal";

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

function DesignationScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isAddModelCharge, setIsAddModelCharge] = useState(false);
  const [isAddModelEntretien, setIsAddModelEntretien] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");
  const [isConfirmCharge, setIsConfirmCharge] = useState(false);
  const [isConfirmEntretien, setIsConfirmEntretien] = useState(false);

  const [chargeId, setChargeId] = useState("");
  const [entretienId, setEntretienId] = useState("");

  const [chargeName, setChargeName] = useState("");
  const [chargeNameError, setChargeNameError] = useState("");

  const [entretienName, setEntretienName] = useState("");
  const [entretienNameError, setEntretienNameError] = useState("");

  const [forLocation, setForLocation] = useState(false);

  const [chargeTageName, setChargeTageName] = useState("");
  const [chargeTageNameError, setChargeTageNameError] = useState("");

  const [entretienTageName, setEntretienTageName] = useState("");
  const [entretienTageNameError, setEntretienTageNameError] = useState("");

  const [chargeTages, setChargeTages] = useState([]);
  const [chargeTagesList, setChargeTagesList] = useState([]);

  const [entretienTages, setEntretienTages] = useState([]);
  const [entretienTagesList, setEntretienTagesList] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listCharge = useSelector((state) => state.chargeList);
  const { charges, loadingCharge, errorCharge, successCharge } = listCharge;

  const addCharge = useSelector((state) => state.createNewCharge);
  const { loadingChargeAdd, errorChargeAdd, successChargeAdd } = addCharge;

  const chargeDelete = useSelector((state) => state.deleteCharge);
  const { loadingChargeDelete, errorChargeDelete, successChargeDelete } =
    chargeDelete;

  const chargeUpdate = useSelector((state) => state.updateCharge);
  const { loadingChargeUpdate, errorChargeUpdate, successChargeUpdate } =
    chargeUpdate;

  //
  const listEntretien = useSelector((state) => state.entretienList);
  const { entretiens, loadingEntretien, errorEntretien, successEntretien } =
    listEntretien;

  const entretienDelete = useSelector((state) => state.deleteEntretien);
  const {
    loadingEntretienDelete,
    errorEntretienDelete,
    successEntretienDelete,
  } = entretienDelete;

  const addEntretien = useSelector((state) => state.createNewEntretien);
  const { loadingEntretienAdd, errorEntretienAdd, successEntretienAdd } =
    addEntretien;

  const entretienUpdate = useSelector((state) => state.updateEntretien);
  const {
    loadingEntretienUpdate,
    errorEntretienUpdate,
    successEntretienUpdate,
  } = entretienUpdate;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListCharges());
      dispatch(getListEntretiens());
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (successEntretienUpdate) {
      dispatch(getListEntretiens());
      setEntretienId("");
      setEventType("");
      setLoadEvent(false);
      setIsConfirmEntretien(false);
      setIsAddModelEntretien(false);
      setEntretienName("");
      setEntretienNameError("");
      setEntretienTageName("");
      setEntretienTages([]);
      setEntretienTagesList([]);
    }
    if (successEntretienAdd) {
      dispatch(getListCharges());
      setEntretienId("");
      setEventType("");
      setLoadEvent(false);
      setIsConfirmEntretien(false);
      setIsAddModelEntretien(false);
      setEntretienName("");
      setEntretienNameError("");
      setEntretienTageName("");
      setEntretienTages([]);
      setEntretienTagesList([]);
    }

    if (successEntretienDelete) {
      dispatch(getListEntretiens());
      setEntretienId("");
      setEventType("");
      setLoadEvent(false);
      setIsConfirmEntretien(false);
      setIsAddModelEntretien(false);
      setEntretienName("");
      setEntretienNameError("");
      setEntretienTageName("");
      setEntretienTages([]);
      setEntretienTagesList([]);
    }

    if (successChargeAdd) {
      dispatch(getListCharges());
      setChargeId("");
      setEventType("");
      setLoadEvent(false);
      setIsConfirmCharge(false);
      setIsAddModelCharge(false);
      setChargeName("");
      setChargeNameError("");
      setChargeTageName("");
      setChargeTages([]);
      setChargeTagesList([]);
    }
    if (successChargeDelete) {
      dispatch(getListCharges());
      setChargeId("");
      setEventType("");
      setLoadEvent(false);
      setIsConfirmCharge(false);
      setIsAddModelCharge(false);
      setChargeName("");
      setChargeNameError("");
      setChargeTageName("");
      setChargeTages([]);
      setChargeTagesList([]);
    }

    if (successChargeUpdate) {
      dispatch(getListCharges());
      setChargeId("");
      setEventType("");
      setLoadEvent(false);
      setIsConfirmCharge(false);
      setIsAddModelCharge(false);
      setChargeName("");
      setChargeNameError("");
      setChargeTageName("");
      setChargeTages([]);
      setChargeTagesList([]);
    }
  }, [
    successChargeDelete,
    successChargeUpdate,
    successChargeAdd,
    successEntretienDelete,
    successEntretienAdd,
    successEntretienUpdate,
  ]);

  const suggestions = [].map((item) => {
    return {};
  });

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Paramétrages</div>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Désignation</div>
        </div>

        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black  ">
              Gestion des Désignations
            </h4>
          </div>
          {/* search */}

          {/* list */}
          <div className="w-full mt-3">
            <div className="flex md:flex-row flex-col">
              <div className="md:w-1/2 w-full  md:mr-1">
                <LayoutSection title="Charges">
                  <div className="my-1 mx-2 text-right">
                    <div
                      onClick={() => {
                        setLoadEvent(false);
                        setEventType("add");
                        setIsAddModelCharge(true);
                        setChargeName("");
                        setChargeNameError("");
                        setChargeTageName("");
                        setChargeTageNameError("");
                        setChargeTages([]);
                      }}
                      className="text-xs cursor-pointer text-primary font-bold hover:text-danger"
                    >
                      Ajouter une nouvelle
                    </div>
                    {/* model add new charge */}
                    {isAddModelCharge ? (
                      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#00000040] z-99999">
                        <div className="bg-white p-6 rounded shadow-md  md:w-1/3 mx-3  text-left">
                          <h3 className="text-base font-bold mb-4 w-full text-left">
                            {eventType == "add"
                              ? "Ajouter un nouveau charge"
                              : "Modification de type charge N°" + chargeId}
                          </h3>
                          <hr />
                          <div className="my-4">
                            <div>
                              <div className="md:py-2 md:flex ">
                                <InputModel
                                  label="Nom de charge"
                                  type="text"
                                  placeholder=""
                                  value={chargeName}
                                  onChange={(v) =>
                                    setChargeName(v.target.value)
                                  }
                                  error={chargeNameError}
                                />
                              </div>
                              <div className="md:py-2 flex ">
                                <input
                                  type={"checkbox"}
                                  checked={forLocation}
                                  className="mx-1"
                                  onChange={(v) => setForLocation(!forLocation)}
                                />
                                <div
                                  onClick={() => setForLocation(!forLocation)}
                                  className="text-sm mx-1 cursor-pointer"
                                >
                                  De location ?{" "}
                                </div>
                              </div>
                              <div className="md:py-2  ">
                                <div className="mx-1 mb-3 flex flex-wrap">
                                  {eventType === "update"
                                    ? chargeTagesList?.map((tag, index) => (
                                        <div
                                          onClick={() => {
                                            setChargeTagesList(
                                              chargeTagesList.filter(
                                                (tag, i) => i !== index
                                              )
                                            );
                                          }}
                                          className="mx-1 my-1 bg-primary text-white font-bold text-xs px-3 py-1 rounded cursor-pointer flex"
                                        >
                                          <div>{tag.sub_name}</div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            className="w-2 h-2"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M6 18 18 6M6 6l12 12"
                                            />
                                          </svg>
                                        </div>
                                      ))
                                    : null}
                                  {chargeTages?.map((tag, index) => (
                                    <div
                                      onClick={() => {
                                        setChargeTages(
                                          chargeTages.filter(
                                            (tag, i) => i !== index
                                          )
                                        );
                                      }}
                                      className="mx-1 my-1 bg-primary text-white font-bold text-xs px-3 py-1 rounded cursor-pointer flex"
                                    >
                                      <div>{tag}</div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        className="w-2 h-2"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M6 18 18 6M6 6l12 12"
                                        />
                                      </svg>
                                    </div>
                                  ))}
                                </div>
                                <div className="flex flex-row  items-center">
                                  <InputModel
                                    label="Sous charge"
                                    type="text"
                                    placeholder=""
                                    value={chargeTageName}
                                    onChange={(v) =>
                                      setChargeTageName(v.target.value)
                                    }
                                    error={chargeTageNameError}
                                  />
                                  <div>
                                    <svg
                                      onClick={() => {
                                        setChargeNameError("");
                                        if (chargeTageName === "") {
                                          setChargeTageNameError(
                                            "Ce champ est requis."
                                          );
                                        } else {
                                          if (chargeTageName.trim() !== "") {
                                            setChargeTages([
                                              ...chargeTages,
                                              chargeTageName.trim(),
                                            ]);
                                            setChargeTageName("");
                                          }
                                        }
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6 cursor-pointer"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-end">
                            <button
                              className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                              onClick={async () => {
                                var icheck = true;
                                if (chargeName === "") {
                                  setChargeNameError("Ce champ est requis.");
                                  icheck = false;
                                }
                                if (icheck) {
                                  setLoadEvent(true);
                                  if (eventType === "add") {
                                    await dispatch(
                                      addNewCharge({
                                        designation_name: chargeName,
                                        for_location: forLocation
                                          ? "True"
                                          : "False",
                                        tages: JSON.stringify(chargeTages),
                                      })
                                    ).then(() => {});
                                    await dispatch(getListCharges()).then(
                                      () => {}
                                    );
                                  } else if (
                                    eventType === "update" &&
                                    chargeId !== ""
                                  ) {
                                    await dispatch(
                                      updateCharge(chargeId, {
                                        designation_name: chargeName,
                                        for_location: forLocation
                                          ? "True"
                                          : "False",
                                        tages: JSON.stringify(chargeTages),
                                        tageslast:
                                          JSON.stringify(chargeTagesList),
                                      })
                                    ).then(() => {});
                                    await dispatch(getListCharges()).then(
                                      () => {}
                                    );
                                  }
                                  setLoadEvent(false);
                                  setIsAddModelCharge(false);
                                } else {
                                  toast.error(
                                    "Certains champs sont obligatoires veuillez vérifier"
                                  );
                                }
                              }}
                              disabled={loadEvent}
                            >
                              {" "}
                              {loadEvent ? (
                                <div role="status">
                                  <svg
                                    aria-hidden="true"
                                    className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-danger"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                "Confirm"
                              )}{" "}
                            </button>
                            <button
                              className="bg-danger hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                              onClick={() => {
                                setIsAddModelCharge(false);
                                setLoadEvent(false);
                              }}
                              disabled={loadEvent}
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* table */}
                    {loadingCharge ? (
                      <Loader />
                    ) : errorCharge ? (
                      <Alert type="error" message={errorCharge} />
                    ) : (
                      <table className="w-full table-auto overflow-x-auto mt-2">
                        <thead>
                          <tr className="bg-gray-2 text-left ">
                            <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                              Charge
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max ">
                              Sous Charge
                            </th>
                            <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {charges?.map((charge, id) => (
                            <tr>
                              <td className="min-w-[30px] border-b border-[#eee] py-2 px-4 ">
                                <p className="text-black  text-xs w-max  flex ">
                                  {charge.designation_name}
                                  {charge.for_location ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-4 h-4 mx-1"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                                      />
                                    </svg>
                                  ) : null}
                                </p>
                              </td>
                              <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                                <div className=" flex flex-wrap ">
                                  {charge.items?.map((item, index) => (
                                    <p className="mx-1 my-1 bg-primary text-white font-bold text-[9px] px-2 py-0.5 rounded cursor-pointer flex ">
                                      {item.sub_name}
                                    </p>
                                  ))}
                                </div>
                              </td>
                              <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max flex flex-row  ">
                                  {/* edit */}
                                  <div
                                    className="mx-1 update-class cursor-pointer"
                                    onClick={() => {
                                      setChargeId(charge.id);
                                      setEventType("update");
                                      setChargeName(charge.designation_name);
                                      setForLocation(charge.for_location);
                                      setChargeTagesList(charge.items);

                                      setIsAddModelCharge(true);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                      />
                                    </svg>
                                  </div>
                                  <div
                                    onClick={() => {
                                      setChargeId(charge.id);
                                      setEventType("delete");
                                      setIsConfirmCharge(true);
                                    }}
                                    className="mx-1 delete-class cursor-pointer"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                      />
                                    </svg>
                                  </div>
                                </p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </LayoutSection>
              </div>
              <div className="md:w-1/2 w-full  md:ml-1">
                <LayoutSection title="Entretiens">
                  <div className="my-1 mx-2 text-right">
                    <div
                      onClick={() => {
                        setLoadEvent(false);
                        setEventType("add");
                        setIsAddModelEntretien(true);
                        setEntretienName("");
                        setEntretienNameError("");
                        setEntretienTageName("");
                        setEntretienTageNameError("");
                        setEntretienTages([]);
                      }}
                      className="text-xs cursor-pointer text-primary font-bold hover:text-danger"
                    >
                      Ajouter une nouvelle
                    </div>
                    {/* model add new charge */}
                    {isAddModelEntretien ? (
                      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#00000040] z-99999">
                        <div className="bg-white p-6 rounded shadow-md  md:w-1/3 mx-3  text-left">
                          <h3 className="text-base font-bold mb-4 w-full text-left">
                            {eventType == "add"
                              ? "Ajouter un nouveau entretien"
                              : "Modification de type entretien N°" +
                                entretienId}
                          </h3>
                          <hr />
                          <div className="my-4">
                            <div>
                              <div className="md:py-2 md:flex ">
                                <InputModel
                                  label="Nom de entretien"
                                  type="text"
                                  placeholder=""
                                  value={entretienName}
                                  onChange={(v) =>
                                    setEntretienName(v.target.value)
                                  }
                                  error={entretienNameError}
                                />
                              </div>
                              <div className="md:py-2  ">
                                <div className="mx-1 mb-3 flex flex-wrap">
                                  {eventType === "update"
                                    ? entretienTagesList?.map((tag, index) => (
                                        <div
                                          onClick={() => {
                                            setEntretienTagesList(
                                              entretienTagesList.filter(
                                                (tag, i) => i !== index
                                              )
                                            );
                                          }}
                                          className="mx-1 my-1 bg-primary text-white font-bold text-xs px-3 py-1 rounded cursor-pointer flex"
                                        >
                                          <div>{tag.sub_name}</div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            className="w-2 h-2"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M6 18 18 6M6 6l12 12"
                                            />
                                          </svg>
                                        </div>
                                      ))
                                    : null}
                                  {entretienTages?.map((tag, index) => (
                                    <div
                                      onClick={() => {
                                        setEntretienTages(
                                          entretienTages.filter(
                                            (tag, i) => i !== index
                                          )
                                        );
                                      }}
                                      className="mx-1 my-1 bg-primary text-white font-bold text-xs px-3 py-1 rounded cursor-pointer flex"
                                    >
                                      <div>{tag}</div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        className="w-2 h-2"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M6 18 18 6M6 6l12 12"
                                        />
                                      </svg>
                                    </div>
                                  ))}
                                </div>
                                <div className="flex flex-row  items-center">
                                  <InputModel
                                    label="Sous Entretien"
                                    type="text"
                                    placeholder=""
                                    value={entretienTageName}
                                    onChange={(v) =>
                                      setEntretienTageName(v.target.value)
                                    }
                                    error={entretienTageNameError}
                                  />
                                  <div>
                                    <svg
                                      onClick={() => {
                                        setEntretienNameError("");
                                        if (entretienTageName === "") {
                                          setEntretienTageNameError(
                                            "Ce champ est requis."
                                          );
                                        } else {
                                          if (entretienTageName.trim() !== "") {
                                            setEntretienTages([
                                              ...entretienTages,
                                              entretienTageName.trim(),
                                            ]);
                                            setEntretienTageName("");
                                          }
                                        }
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6 cursor-pointer"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-end">
                            <button
                              className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                              onClick={async () => {
                                var icheck = true;
                                if (entretienName === "") {
                                  setEntretienNameError("Ce champ est requis.");
                                  icheck = false;
                                }
                                if (icheck) {
                                  setLoadEvent(true);
                                  if (eventType === "add") {
                                    await dispatch(
                                      addNewEntretien({
                                        entretien_name: entretienName,

                                        tages: JSON.stringify(entretienTages),
                                      })
                                    ).then(() => {});
                                    await dispatch(getListEntretiens()).then(
                                      () => {}
                                    );
                                  } else if (
                                    eventType === "update" &&
                                    entretienId !== ""
                                  ) {
                                    await dispatch(
                                      updateEntretien(entretienId, {
                                        entretien_name: entretienName,
                                        tages: JSON.stringify(entretienTages),
                                        tageslast:
                                          JSON.stringify(entretienTagesList),
                                      })
                                    ).then(() => {});
                                    await dispatch(getListEntretiens()).then(
                                      () => {}
                                    );
                                  }
                                  setLoadEvent(false);
                                  setIsAddModelEntretien(false);
                                } else {
                                  toast.error(
                                    "Certains champs sont obligatoires veuillez vérifier"
                                  );
                                }
                              }}
                              disabled={loadEvent}
                            >
                              {" "}
                              {loadEvent ? (
                                <div role="status">
                                  <svg
                                    aria-hidden="true"
                                    className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-danger"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                "Confirm"
                              )}{" "}
                            </button>
                            <button
                              className="bg-danger hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                              onClick={() => {
                                setIsAddModelEntretien(false);
                                setLoadEvent(false);
                              }}
                              disabled={loadEvent}
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* table */}
                    {loadingEntretien ? (
                      <Loader />
                    ) : errorEntretien ? (
                      <Alert type="error" message={errorEntretien} />
                    ) : (
                      <table className="w-full table-auto overflow-x-auto mt-2">
                        <thead>
                          <tr className="bg-gray-2 text-left ">
                            <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                              Entretien
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max ">
                              Sous Entretien
                            </th>
                            <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {entretiens?.map((entretien, id) => (
                            <tr>
                              <td className="min-w-[30px] border-b border-[#eee] py-2 px-4 ">
                                <p className="text-black  text-xs w-max  flex ">
                                  {entretien.entretien_name}
                                </p>
                              </td>
                              <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                                <div className=" flex flex-wrap ">
                                  {entretien.items?.map((item, index) => (
                                    <p className="mx-1 my-1 bg-primary text-white font-bold text-[9px] px-2 py-0.5 rounded cursor-pointer flex ">
                                      {item.sub_name}
                                    </p>
                                  ))}
                                </div>
                              </td>
                              <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max flex flex-row  ">
                                  {/* edit */}
                                  <div
                                    className="mx-1 update-class cursor-pointer"
                                    onClick={() => {
                                      setEntretienId(entretien.id);
                                      setEventType("update");
                                      setEntretienName(
                                        entretien.entretien_name
                                      );
                                      setEntretienTagesList(entretien.items);

                                      setIsAddModelEntretien(true);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                      />
                                    </svg>
                                  </div>
                                  <div
                                    onClick={() => {
                                      setEntretienId(entretien.id);
                                      setEventType("delete");
                                      setIsConfirmEntretien(true);
                                    }}
                                    className="mx-1 delete-class cursor-pointer"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                      />
                                    </svg>
                                  </div>
                                </p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </LayoutSection>
              </div>
            </div>
          </div>
        </div>
        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
      {/* confirmation charge */}
      <ConfirmationModal
        isOpen={isConfirmCharge}
        message={
          eventType === "delete"
            ? "Êtes-vous sûr de vouloir supprimer cet Type de Charge?"
            : eventType === "update"
            ? "Êtes-vous sûr de vouloir modifé cet Type de Charge?"
            : "Êtes-vous sûr de vouloir ajouter cet Type de Charge?"
        }
        onConfirm={async () => {
          if (eventType === "delete") {
            if (chargeId !== "") {
              setLoadEvent(true);
              await dispatch(deleteCharge(chargeId)).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsConfirmCharge(false);
            }
          }
        }}
        onCancel={() => {
          setIsConfirmCharge(false);
          setEventType("");
          setLoadEvent(false);
        }}
        loadEvent={loadEvent}
      />
      {/* confirmation entretien */}
      <ConfirmationModal
        isOpen={isConfirmEntretien}
        message={
          eventType === "delete"
            ? "Êtes-vous sûr de vouloir supprimer cet Type de Entretien?"
            : eventType === "update"
            ? "Êtes-vous sûr de vouloir modifé cet Type de Entretien?"
            : "Êtes-vous sûr de vouloir ajouter cet Type de Entretien?"
        }
        onConfirm={async () => {
          if (eventType === "delete") {
            if (entretienId !== "") {
              setLoadEvent(true);
              await dispatch(deleteEntretien(entretienId)).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsConfirmEntretien(false);
            }
          }
        }}
        onCancel={() => {
          setIsConfirmEntretien(false);
          setEventType("");
          setLoadEvent(false);
        }}
        loadEvent={loadEvent}
      />
    </DefaultLayout>
  );
}

export default DesignationScreen;
