import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addNewMarque,
  deleteMarque,
  getMarqueList,
} from "../../../redux/actions/marqueActions";
import DefaultLayout from "../../../layouts/DefaultLayout";
import LayoutSection from "../../../components/LayoutSection";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import Alert from "../../../components/Alert";
import {
  addNewModele,
  deleteModele,
  getModelList,
} from "../../../redux/actions/modelActions";
import InputModel from "../../../components/InputModel";

function MarquesModelsScreen() {
  const navigate = useNavigate();
  const location = useLocation();

  const [marqueName, setMarqueName] = useState("");
  const [idMarque, setIdMarque] = useState("");
  const [isAddMarque, setIsAddMarque] = useState(false);
  const [isDeleteMarque, setIsDeleteMarque] = useState(false);

  const [loadEvent, setLoadEvent] = useState(false);
  const [errorMarqueName, setErrorMarqueName] = useState("");

  const [marqueId, setMarqueId] = useState("");
  const [errorMarqueId, setErrorMarqueId] = useState("");
  const [idModele, setIdModele] = useState("");
  const [modelName, setModelName] = useState("");
  const [errorModelName, setErrorModelName] = useState("");
  const [isAddModel, setIsAddModel] = useState(false);
  const [isDeleteModele, setIsDeleteModele] = useState(false);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listMarque = useSelector((state) => state.marqueList);
  const { marques, loadingMarque, errorMarque } = listMarque;

  const marqueAdd = useSelector((state) => state.addNewMarque);
  const { loadingMarqueAdd, errorMarqueAdd, successMarqueAdd } = marqueAdd;

  const marqueDelete = useSelector((state) => state.deleteMarque);
  const { loadingMarqueDelete, errorMarqueDelete, successMarqueDelete } =
    marqueDelete;

  const listModel = useSelector((state) => state.modelList);
  const { models, loadingModel, errorModel } = listModel;

  const modelDelete = useSelector((state) => state.deleteModel);
  const { loadingModelDelete, errorModelDelete, successModelDelete } =
    modelDelete;

  const modelAdd = useSelector((state) => state.addNewModele);
  const { loadingModelAdd, errorModelAdd, successModelAdd } = modelAdd;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getMarqueList());
      dispatch(getModelList(""));
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (successMarqueAdd) {
      setMarqueName("");
      setErrorMarqueName("");
      setLoadEvent(false);
      setIsAddMarque(false);
    }
    if (successMarqueDelete) {
      setIdMarque("");

      setLoadEvent(false);
      setIsDeleteMarque(false);
    }

    if (successModelDelete) {
      setIdModele("");

      setLoadEvent(false);
      setIsDeleteModele(false);
    }

    if (successModelAdd) {
      setMarqueId("");
      setErrorMarqueId("");
      setModelName("");
      setErrorModelName("");
      setLoadEvent(false);
      setIsAddModel(false);
    }
  }, [
    successMarqueAdd,
    successMarqueDelete,
    successModelDelete,
    successModelAdd,
  ]);

  //
  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Paramétrages</div>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Marques et modeles</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black  ">
              Gestion des Marques et modeles
            </h4>
          </div>
          {/* search */}

          {/* list */}

          <div className="w-full mt-3">
            <div className="flex md:flex-row flex-col">
              <div className="md:w-1/2 w-full  md:mr-1">
                <LayoutSection title="Marques de voiture">
                  <div className="flex md:flex-row flex-col  my-2 justify-center ">
                    <div className="md:flex-1 md:mr-1 md:mb-0 mb-1 md:mx-2 ">
                      <div className="md:py-2 md:flex ">
                        <InputModel
                          label="Marque"
                          type="text"
                          placeholder="ex: Peugeot, Mercedes..."
                          value={marqueName}
                          onChange={(v) => setMarqueName(v.target.value)}
                          error={errorMarqueName}
                        />
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        setErrorMarqueName("");
                        if (marqueName === "") {
                          setErrorMarqueName("Ce champ est requis.");
                          toast.error(
                            "Veuillez ajouter la marque de la voiture"
                          );
                        } else {
                          setIsAddMarque(true);
                        }
                      }}
                      className="bg-danger text-white text-sm font-bold px-2 rounded  "
                    >
                      Ajouter
                    </button>
                  </div>
                  <ConfirmationModal
                    isOpen={isAddMarque}
                    message="Êtes-vous sûr de vouloir ajouter cette Marque ?"
                    onConfirm={async () => {
                      setLoadEvent(true);
                      await dispatch(addNewMarque(marqueName)).then(() => {});
                      await dispatch(getMarqueList()).then(() => {});
                      setLoadEvent(false);
                    }}
                    onCancel={() => {
                      setIsAddMarque(false);
                      setLoadEvent(false);
                    }}
                    loadEvent={loadEvent}
                  />
                  {loadingMarque ? (
                    <Loader />
                  ) : errorMarque ? (
                    <Alert type="error" message={errorMarque} />
                  ) : (
                    <table className="w-full table-auto overflow-x-auto">
                      <thead>
                        <tr className="bg-gray-2 text-left dark:bg-meta-4">
                          <th className="min-w-[60px] py-2 px-4 font-medium text-black   xl:pl-11">
                            N°
                          </th>
                          <th className=" py-2 px-4 font-medium text-black  ">
                            Nom
                          </th>

                          <th className="py-2 px-4 font-medium text-black  ">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      {/*  */}

                      <tbody>
                        {marques?.map((marque, id) => (
                          <tr>
                            <td className="border-b border-[#eee] py-2 px-4 dark:border-strokedark">
                              <p className="text-black  ">{marque.id}</p>
                            </td>
                            <td className="border-b border-[#eee] py-2 px-4 dark:border-strokedark">
                              <p className="text-black  ">
                                {marque.marque_car}
                              </p>
                            </td>
                            <td className="border-b border-[#eee] py-2 px-4 dark:border-strokedark">
                              <p className="text-black  flex flex-row">
                                <button
                                  onClick={() =>
                                    dispatch(getModelList(marque.id))
                                  }
                                  className="bg-primary text-xs px-1 mx-1 text-white font-bold rounded py-1"
                                >
                                  Afficher les modeles
                                </button>

                                {/*  */}

                                {/*  */}
                                {/* <button>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 mx-1"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                    />
                                  </svg>
                                </button> */}

                                <button
                                  className="mx-1 delete-class"
                                  onClick={() => {
                                    setIsDeleteMarque(true);
                                    setIdMarque(marque.id);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                  </svg>
                                </button>
                              </p>
                            </td>
                          </tr>
                        ))}
                        <tr className="h-11"></tr>
                      </tbody>
                    </table>
                  )}
                  {/*  */}
                  <ConfirmationModal
                    isOpen={isDeleteMarque}
                    message="Êtes-vous sûr de vouloir supprimé cette Marque?"
                    onConfirm={async () => {
                      if (idMarque !== "") {
                        setLoadEvent(true);
                        await dispatch(deleteMarque(idMarque)).then(() => {});
                        await dispatch(getMarqueList()).then(() => {});
                        setLoadEvent(false);
                      } else {
                        setIsDeleteMarque(false);
                        setLoadEvent(false);
                      }
                    }}
                    onCancel={() => {
                      setIsDeleteMarque(false);
                      setLoadEvent(false);
                    }}
                    loadEvent={loadEvent}
                  />
                </LayoutSection>
              </div>
              <div className="md:w-1/2 w-full  md:ml-1">
                <LayoutSection title="Modeles de voiture">
                  <div className="flex md:flex-row flex-col  my-2 justify-center ">
                    <div className="md:flex-1 md:mr-1 md:mb-0 mb-1 md:mx-2 ">
                      <div className={`border rounded-md mt-1`}>
                        <select
                          value={marqueId}
                          onChange={(v) => setMarqueId(v.target.value)}
                          className="p-2 w-full outline-none focus:border-primary bg-transparent"
                        >
                          <option value="">Marque</option>
                          {marques?.map((marque, index) => (
                            <option value={marque.id}>
                              {marque.marque_car}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errorMarqueId && (
                        <p className="text-xs italic text-danger">
                          {errorMarqueId}
                        </p>
                      )}
                    </div>
                    <div className="md:flex-1 md:mr-1 md:mb-0 mb-1 md:mx-2 ">
                      <div className={`border rounded-md mt-1`}>
                        <input
                          type="text"
                          value={modelName}
                          placeholder="Modele (ex:Clio, duster..)"
                          className="p-2 w-full outline-none focus:border-primary bg-transparent"
                          onChange={(v) => setModelName(v.target.value)}
                        />
                      </div>
                      {errorModelName && (
                        <p className="text-xs italic text-danger">
                          {errorModelName}
                        </p>
                      )}
                    </div>
                    <button
                      onClick={() => {
                        setErrorMarqueId("");
                        setErrorModelName("");
                        if (modelName === "" || marqueId === "") {
                          if (marqueId === "") {
                            setErrorMarqueId("Ce champ est requis.");
                            toast.error(
                              "Veuillez sélectionner la marque de la voiture"
                            );
                          }
                          if (modelName === "") {
                            setErrorModelName("Ce champ est requis.");
                            toast.error(
                              "Veuillez ajouter la modele de la voiture"
                            );
                          }
                        } else {
                          setIsAddModel(true);
                        }
                      }}
                      className="bg-danger text-white font-bold px-2 rounded  "
                    >
                      Ajouter
                    </button>
                  </div>
                  <ConfirmationModal
                    isOpen={isAddModel}
                    message="Êtes-vous sûr de vouloir ajouter cette Modele?"
                    onConfirm={async () => {
                      setLoadEvent(true);
                      await dispatch(addNewModele(marqueId, modelName)).then(
                        () => {}
                      );
                      await dispatch(getModelList("")).then(() => {});
                      setLoadEvent(false);
                    }}
                    onCancel={() => {
                      setIsAddModel(false);
                      setLoadEvent(false);
                    }}
                    loadEvent={loadEvent}
                  />
                  {loadingModel ? (
                    <Loader />
                  ) : errorModel ? (
                    <Alert type="error" message={errorModel} />
                  ) : (
                    <table className="w-full table-auto overflow-x-auto">
                      <thead>
                        <tr className="bg-gray-2 text-left dark:bg-meta-4">
                          <th className="min-w-[60px] py-2 px-4 font-medium text-black   xl:pl-11">
                            N°
                          </th>
                          <th className=" py-2 px-4 font-medium text-black  ">
                            Marque
                          </th>
                          <th className=" py-2 px-4 font-medium text-black  ">
                            Modele
                          </th>

                          <th className="py-2 px-4 font-medium text-black  ">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      {/*  */}

                      <tbody>
                        {models?.map((model, id) => (
                          <tr>
                            <td className="border-b border-[#eee] py-2 px-4 dark:border-strokedark">
                              <p className="text-black  ">{model.id}</p>
                            </td>

                            <td className="border-b border-[#eee] py-2 px-4 dark:border-strokedark">
                              <p className="text-black  ">
                                {model.marque_car.marque_car ?? "---"}
                              </p>
                            </td>
                            <td className="border-b border-[#eee] py-2 px-4 dark:border-strokedark">
                              <p className="text-black  ">{model.model_car}</p>
                            </td>
                            <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                              <p className="text-black  text-xs w-max flex flex-row">
                                {/* <button>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 mx-1"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                    />
                                  </svg>
                                </button> */}
                                <button
                                  className="mx-1 delete-class"
                                  onClick={() => {
                                    setIsDeleteModele(true);
                                    setIdModele(model.id);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                  </svg>
                                </button>
                              </p>
                            </td>
                          </tr>
                        ))}
                        <tr className="h-11"></tr>
                      </tbody>
                    </table>
                  )}
                  {/*  */}
                  <ConfirmationModal
                    isOpen={isDeleteModele}
                    message="Êtes-vous sûr de vouloir supprimé cette Modele?"
                    onConfirm={async () => {
                      if (idModele !== "") {
                        setLoadEvent(true);
                        await dispatch(deleteModele(idModele)).then(() => {});
                        await dispatch(getModelList("")).then(() => {});
                        setLoadEvent(false);
                      } else {
                        setIsDeleteModele(false);
                        setLoadEvent(false);
                      }
                    }}
                    onCancel={() => {
                      setIsDeleteModele(false);
                      setLoadEvent(false);
                    }}
                    loadEvent={loadEvent}
                  />
                </LayoutSection>
              </div>
            </div>
          </div>
        </div>
        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default MarquesModelsScreen;
