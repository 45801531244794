import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../../layouts/DefaultLayout";
import Loader from "../../../components/Loader";
import Alert from "../../../components/Alert";
import Paginate from "../../../components/Paginate";
import {
  addContratPayments,
  detailContrat,
  getDetailContratPayment,
  getListContratPayments,
  updateContratPayments,
} from "../../../redux/actions/contratActions";
import { baseURLFile } from "../../../constants";
import LayoutSection from "../../../components/LayoutSection";
import InputModel from "../../../components/InputModel";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../components/ConfirmationModal";

function EditPaymentContratScreen() {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  let { id } = useParams();

  //
  const [amountType, setAmountType] = useState("");
  const [amountTypeError, setAmountTypeError] = useState("");

  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState("");

  const [operationDate, setOperationDate] = useState("");
  const [operationDateError, setOperationDateError] = useState("");

  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const [isAddCar, setIsAddCar] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");

  //
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const detailPaymentContrat = useSelector(
    (state) => state.getDetailContratPayment
  );
  const {
    loadingContratPaymentDetail,
    successContratPaymentDetail,
    paymentDetail,
    errorContratPaymentDetail,
  } = detailPaymentContrat;

  const contratPaymentUpdate = useSelector(
    (state) => state.updateDetailContratPayment
  );
  const {
    loadingContratPaymentUpdate,
    successContratPaymentUpdate,
    errorContratPaymentUpdate,
  } = contratPaymentUpdate;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getDetailContratPayment(id));
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (paymentDetail !== undefined && paymentDetail !== null) {
      setAmountType(paymentDetail.type_payment);
      setAmount(paymentDetail.price_amount);
      setOperationDate(paymentDetail.operation_date);
      setNote(paymentDetail.note);
    }
  }, [paymentDetail]);

  useEffect(() => {
    if (successContratPaymentUpdate) {
      setAmountType("");
      setAmountTypeError("");

      setAmount(0);
      setAmountError("");

      setOperationDate("");
      setOperationDateError("");

      setNote("");
      setNoteError("");
      dispatch(getDetailContratPayment(id));
      setIsAddCar(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successContratPaymentUpdate]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>

          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Paiments</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default  dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black  text-xs w-max">
              modifie le Paiment de Contrat
            </h4>
          </div>

          {/* list */}
          {loadingContratPaymentDetail ? (
            <Loader />
          ) : errorContratPaymentDetail ? (
            <Alert type="error" message={errorContratPaymentDetail} />
          ) : (
            <>
              <div className="flex md:flex-row flex-col ">
                <div className="w-full px-1 py-1">
                  <LayoutSection title="Information de paiement">
                    <div className="md:py-2 md:flex">
                      <InputModel
                        label="Mode de paiement"
                        type="select"
                        placeholder=""
                        value={amountType}
                        onChange={(v) => setAmountType(v.target.value)}
                        error={amountTypeError}
                        options={[
                          { value: "Espece", label: "Espece" },
                          { value: "Cheque", label: "Cheque" },
                          {
                            value: "Carte de credit",
                            label: "Carte de credit",
                          },
                          { value: "Virement", label: "Virement" },
                          {
                            value: "Paiement international",
                            label: "Paiement international",
                          },
                        ]}
                      />
                    </div>
                    <div className="md:py-2 md:flex">
                      <InputModel
                        label="Montant"
                        type="number"
                        isPrice={true}
                        placeholder=""
                        value={amount}
                        isMax={true}
                        max={
                          parseFloat(paymentDetail.contrat?.price_total) -
                          parseFloat(paymentDetail.contrat?.price_avance) +
                          parseFloat(paymentDetail.price_amount)
                        }
                        onChange={(v) => {
                          setAmountError("");
                          setAmount(v.target.value);
                          if (v.target.value !== "") {
                            if (
                              parseFloat(v.target.value) >
                              parseFloat(paymentDetail.contrat?.price_total) -
                                parseFloat(
                                  paymentDetail.contrat?.price_avance
                                ) +
                                parseFloat(paymentDetail.price_amount)
                            ) {
                              setAmountError(
                                "Le montant maximum est : " +
                                  (
                                    parseFloat(
                                      paymentDetail.contrat?.price_total
                                    ) -
                                    parseFloat(
                                      paymentDetail.contrat?.price_avance
                                    ) +
                                    parseFloat(paymentDetail.price_amount)
                                  ).toFixed(2)
                              );
                            }
                          }
                        }}
                        error={amountError}
                      />
                    </div>
                    <div className="md:py-2 md:flex">
                      <InputModel
                        label="Date"
                        type="date"
                        isPrice={true}
                        placeholder=""
                        value={operationDate}
                        onChange={(v) => setOperationDate(v.target.value)}
                        error={operationDateError}
                      />
                    </div>

                    {/* note */}
                    <div className="md:py-2 md:flex">
                      <InputModel
                        label="Note contrat"
                        type="textarea"
                        placeholder=""
                        value={note}
                        onChange={(v) => {
                          setNote(v.target.value);
                        }}
                        error={noteError}
                      />
                    </div>
                  </LayoutSection>
                </div>
              </div>
              <div className="flex md:flex-row flex-col "></div>
              <div className="my-2 flex flex-row items-center justify-end">
                <button
                  onClick={() => {
                    setEventType("cancel");
                    setIsAddCar(true);
                  }}
                  className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
                >
                  Annuler
                </button>
                <button
                  onClick={async () => {
                    var check = true;

                    // setAmountType("");
                    setAmountTypeError("");

                    // setAmount(0);
                    setAmountError("");

                    // setOperationDate("");
                    setOperationDateError("");

                    // setNote("");
                    setNoteError("");

                    if (amountType === "") {
                      setAmountTypeError("Ce champ est requis.");
                      check = false;
                    }

                    if (
                      amount === "" ||
                      isNaN(parseFloat(amount)) ||
                      amount === 0
                    ) {
                      setAmountError("Ce champ est requis.");
                      check = false;
                    } else {
                      if (
                        parseFloat(amount) >
                        parseFloat(paymentDetail.contrat?.price_total) -
                          parseFloat(paymentDetail.contrat?.price_avance) +
                          parseFloat(paymentDetail.price_amount)
                      ) {
                        setAmountError(
                          "Le montant maximum est : " +
                            (
                              parseFloat(paymentDetail.contrat?.price_total) -
                              parseFloat(paymentDetail.contrat?.price_avance) +
                              parseFloat(paymentDetail.price_amount)
                            ).toFixed(2)
                        );
                        check = false;
                      }
                    }

                    if (operationDate === "") {
                      setOperationDateError("Ce champ est requis.");
                      check = false;
                    }

                    if (check) {
                      setEventType("update");
                      setIsAddCar(true);
                    } else {
                      toast.error(
                        "Certains champs sont obligatoires veuillez vérifier"
                      );
                    }
                  }}
                  className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                    />
                  </svg>
                  Modifié
                </button>
              </div>
            </>
          )}
        </div>

        {/* buttom dash */}
        <ConfirmationModal
          isOpen={isAddCar}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Êtes-vous sûr de vouloir modifie cette paiement ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setAmountType("");
              setAmountTypeError("");

              setAmount(0);
              setAmountError("");

              setOperationDate("");
              setOperationDateError("");

              setNote("");
              setNoteError("");
              dispatch(getDetailContratPayment(id));
              setIsAddCar(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                updateContratPayments(id, {
                  price_amount: amount,
                  type_payment: amountType,
                  operation_date: operationDate,
                  note: note,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAddCar(false);
            }
          }}
          onCancel={() => {
            setIsAddCar(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default EditPaymentContratScreen;
