import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../../layouts/DefaultLayout";
import Loader from "../../../components/Loader";
import Alert from "../../../components/Alert";
import Paginate from "../../../components/Paginate";
import {
  addContratPayments,
  addReturnContrat,
  detailContrat,
  getListContratPayments,
} from "../../../redux/actions/contratActions";
import { baseURLFile } from "../../../constants";
import LayoutSection from "../../../components/LayoutSection";
import InputModel from "../../../components/InputModel";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../components/ConfirmationModal";

function AddReturnContratScreen() {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  let { id } = useParams();

  //
  const [departKm, setDepartKm] = useState(0);
  const [departKmError, setDepartKmError] = useState("");

  const [returnKm, setReturnKm] = useState(0);
  const [returnKmError, setReturnKmError] = useState("");

  const [parcourKm, setParcourKm] = useState(0);
  const [parcourKmError, setParcourKmError] = useState("");

  const [deliveryPlace, setDeliveryPlace] = useState("");
  const [deliveryPlaceError, setDeliveryPlaceError] = useState("");

  const [returnPlace, setReturnPlace] = useState("");
  const [returnPlaceError, setReturnPlaceError] = useState("");

  const [carburantDepart, setCarburantDepart] = useState(0);
  const [carburantDepartError, setCarburantDepartError] = useState("");

  const [carburantReturn, setCarburantReturn] = useState(0);
  const [carburantReturnError, setCarburantReturnError] = useState("");

  //

  const [isAdd, setIsAdd] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");

  //
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const contratDetail = useSelector((state) => state.detailContrat);
  const { loading, success, contrat, error } = contratDetail;

  const contratReturnAdd = useSelector((state) => state.addReturnContrat);
  const {
    loadingContratAddReturn,
    successContratAddReturn,
    errorContratAddReturn,
  } = contratReturnAdd;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(detailContrat(id));
    }
  }, [navigate, userInfo, dispatch, id]);

  useEffect(() => {
    if (contrat !== undefined && contrat !== null) {
      setDepartKm(contrat.depart_km);
      setCarburantDepart(contrat.carburant);
      setCarburantReturn(contrat.carburant_return);
      setDeliveryPlace(contrat.delivery_place);
      setReturnPlace(contrat.return_place);
      setReturnKm(contrat.return_km);
      setParcourKm(contrat.parcour_km);
    }
  }, [contrat]);

  useEffect(() => {
    if (successContratAddReturn) {
      setDepartKm(0);
      setDepartKmError("");

      setReturnKm(0);
      setReturnKmError("");

      setParcourKm(0);
      setParcourKmError("");

      setDeliveryPlace("");
      setDeliveryPlaceError("");

      setReturnPlace("");
      setReturnPlaceError("");

      setCarburantDepart(0);
      setCarburantDepartError("");

      setCarburantReturn(0);
      setCarburantReturnError("");

      dispatch(detailContrat(id));

      setIsAdd(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successContratAddReturn, dispatch, id]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href={"/contrats/edit/" + id} className="">
            Contrat {id}
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Retour</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default  dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black  text-xs w-max">
              Retour de Contrat N° {id}{" "}
              {contrat && contrat !== undefined
                ? " - créé le " + contrat.created_at
                : ""}
            </h4>
          </div>

          {/* list */}
          {loading ? (
            <Loader />
          ) : error ? (
            <Alert type="error" message={error} />
          ) : (
            <>
              <div className="flex md:flex-row flex-col ">
                <div className="w-full px-1 py-1">
                  <LayoutSection title="Information de paiement">
                    {/* depart km and return km */}
                    <div className="md:py-2 md:flex">
                      <InputModel
                        label="Depart Km"
                        type="number"
                        placeholder=""
                        disabled={true}
                        value={departKm}
                        onChange={(v) => {}}
                        error={departKmError}
                      />
                      <InputModel
                        label="Km Retour"
                        type="number"
                        placeholder=""
                        value={returnKm}
                        onChange={(v) => {
                          setReturnKmError("");
                          setReturnKm(v.target.value);
                          if (
                            isNaN(parseFloat(v.target.value)) ||
                            v.target.value === "" ||
                            v.target.value === 0
                          ) {
                            setReturnKmError("Ce champ est requis.");
                            // setPriceDay(0);
                          } else if (parseFloat(v.target.value) <= departKm) {
                            setReturnKmError(
                              "Cette valeur ne doit pas être inférieure à " +
                                departKm
                            );
                          } else {
                            setParcourKm(parseFloat(v.target.value) - departKm);
                          }
                        }}
                        error={returnKmError}
                      />
                    </div>
                    <div className="md:py-2 md:flex">
                      <InputModel
                        label="Parcouru Km"
                        type="number"
                        placeholder=""
                        disabled={true}
                        value={parcourKm}
                        onChange={(v) => {}}
                        error={parcourKmError}
                      />
                    </div>
                    <div className="md:py-2 md:flex">
                      <InputModel
                        label="Lieu de livraison"
                        type="select"
                        placeholder="Lieu de livraison"
                        value={deliveryPlace}
                        disabled={true}
                        onChange={(v) => {}}
                        error={deliveryPlaceError}
                        options={[
                          { value: "Agence Nador", label: "Agence Nador" },
                          {
                            value: "Aeroport Casablanca",
                            label: "Aeroport Casablanca",
                          },
                          { value: "Aeroport Rabat", label: "Aeroport Rabat" },
                          {
                            value: "Aeroport Tanger",
                            label: "Aeroport Tanger",
                          },
                          {
                            value: "Aeroport Marrakech",
                            label: "Aeroport Marrakech",
                          },
                          {
                            value: "Aeroport Agadir",
                            label: "Aeroport Agadir",
                          },
                          { value: "Aeroport Fes", label: "Aeroport Fes" },
                          {
                            value: "Meknes centre ville",
                            label: "Meknes centre ville",
                          },
                          { value: "Aeroport Oujda", label: "Aeroport Oujda" },
                          {
                            value: "Fes centre ville",
                            label: "Fes centre ville",
                          },
                          {
                            value: "Agadir centre ville",
                            label: "Agadir centre ville",
                          },
                          {
                            value: "Marrakech centre ville",
                            label: "Marrakech centre ville",
                          },
                          {
                            value: "Tanger centre ville",
                            label: "Tanger centre ville",
                          },
                          {
                            value: "Eljadida centre ville",
                            label: "Eljadida centre ville",
                          },
                          {
                            value: "Mohamedia centre Ville",
                            label: "Mohamedia centre Ville",
                          },
                        ]}
                      />
                      <InputModel
                        label="Lieu de retour"
                        type="select"
                        placeholder="Lieu de retour"
                        value={returnPlace}
                        onChange={(v) => setReturnPlace(v.target.value)}
                        error={returnPlaceError}
                        options={[
                          { value: "Agence Nador", label: "Agence Nador" },
                          {
                            value: "Aeroport Casablanca",
                            label: "Aeroport Casablanca",
                          },
                          { value: "Aeroport Rabat", label: "Aeroport Rabat" },
                          {
                            value: "Aeroport Tanger",
                            label: "Aeroport Tanger",
                          },
                          {
                            value: "Aeroport Marrakech",
                            label: "Aeroport Marrakech",
                          },
                          {
                            value: "Aeroport Agadir",
                            label: "Aeroport Agadir",
                          },
                          { value: "Aeroport Fes", label: "Aeroport Fes" },
                          {
                            value: "Meknes centre ville",
                            label: "Meknes centre ville",
                          },
                          { value: "Aeroport Oujda", label: "Aeroport Oujda" },
                          {
                            value: "Fes centre ville",
                            label: "Fes centre ville",
                          },
                          {
                            value: "Agadir centre ville",
                            label: "Agadir centre ville",
                          },
                          {
                            value: "Marrakech centre ville",
                            label: "Marrakech centre ville",
                          },
                          {
                            value: "Tanger centre ville",
                            label: "Tanger centre ville",
                          },
                          {
                            value: "Eljadida centre ville",
                            label: "Eljadida centre ville",
                          },
                          {
                            value: "Mohamedia centre Ville",
                            label: "Mohamedia centre Ville",
                          },
                        ]}
                      />
                    </div>
                    <div className="md:py-2 md:flex">
                      <InputModel
                        label="Carburant Depart"
                        type="select"
                        placeholder=""
                        value={carburantDepart}
                        onChange={(v) => {
                          setCarburantDepart(v.target.value);
                        }}
                        disabled={true}
                        error={carburantDepartError}
                        options={[
                          { value: "1", label: "1" },
                          { value: "0.75", label: "3/4" },
                          { value: "0.5", label: "1/2" },
                          { value: "0.25", label: "1/4" },
                          { value: "0", label: "0" },
                        ]}
                      />

                      <InputModel
                        label="Carburant"
                        type="select"
                        placeholder="Carburant"
                        value={carburantReturn}
                        onChange={(v) => {
                          setCarburantReturn(v.target.value);
                        }}
                        error={carburantReturnError}
                        options={[
                          { value: "1", label: "1" },
                          { value: "0.75", label: "3/4" },
                          { value: "0.5", label: "1/2" },
                          { value: "0.25", label: "1/4" },
                          { value: "0", label: "0" },
                        ]}
                      />
                    </div>
                  </LayoutSection>
                </div>
              </div>
              <div className="flex md:flex-row flex-col "></div>
              <div className="my-2 flex flex-row items-center justify-end">
                <button
                  onClick={() => {
                    setEventType("cancel");
                    setIsAdd(true);
                  }}
                  className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
                >
                  Annuler
                </button>
                <button
                  onClick={async () => {
                    var check = true;

                    setDepartKmError("");

                    setReturnKmError("");

                    setParcourKmError("");

                    setDeliveryPlaceError("");

                    setReturnPlaceError("");

                    setCarburantDepartError("");

                    setCarburantReturnError("");

                    if (departKm === "") {
                      setDepartKmError("Ce champ est requis.");
                      check = false;
                    }
                    if (returnKm === "") {
                      setReturnKmError("Ce champ est requis.");
                      check = false;
                    }
                    if (parcourKm === "") {
                      setParcourKmError("Ce champ est requis.");
                      check = false;
                    }

                    if (deliveryPlace === "") {
                      setDeliveryPlaceError("Ce champ est requis.");
                      check = false;
                    }
                    if (returnPlace === "") {
                      setReturnPlaceError("Ce champ est requis.");
                      check = false;
                    }
                    if (carburantDepart === "") {
                      setCarburantDepartError("Ce champ est requis.");
                      check = false;
                    }
                    if (carburantReturn === "") {
                      setCarburantReturnError("Ce champ est requis.");
                      check = false;
                    }

                    if (
                      isNaN(parseFloat(returnKm)) ||
                      returnKm === "" ||
                      returnKm === 0
                    ) {
                      setReturnKmError("Ce champ est requis.");
                      check = false;
                    } else if (
                      isNaN(parseFloat(departKm)) ||
                      departKm === "" ||
                      departKm === 0
                    ) {
                      setDepartKmError("Ce champ est requis.");
                      check = false;
                    } else if (parseFloat(returnKm) <= departKm) {
                      setReturnKmError(
                        "Cette valeur ne doit pas être inférieure à " + departKm
                      );
                      check = false;
                    }

                    if (check) {
                      setEventType("add");
                      setIsAdd(true);
                    } else {
                      toast.error(
                        "Certains champs sont obligatoires veuillez vérifier"
                      );
                    }
                  }}
                  className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                  Confirm
                </button>
              </div>
            </>
          )}
        </div>

        {/* buttom dash */}
        <ConfirmationModal
          isOpen={isAdd}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Êtes-vous sûr de vouloir ajouter cette retour ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setDepartKm(0);
              setDepartKmError("");

              setReturnKm(0);
              setReturnKmError("");

              setParcourKm(0);
              setParcourKmError("");

              setDeliveryPlace("");
              setDeliveryPlaceError("");

              setReturnPlace("");
              setReturnPlaceError("");

              setCarburantDepart(0);
              setCarburantDepartError("");

              setCarburantReturn(0);
              setCarburantReturnError("");

              dispatch(detailContrat(id));

              setIsAdd(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                addReturnContrat(id, {
                  return_km: returnKm,
                  parcour_km: parcourKm,
                  carburant_return: carburantReturn,
                  return_place: returnPlace,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAdd(false);
            }
          }}
          onCancel={() => {
            setIsAdd(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default AddReturnContratScreen;
