import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  createNewUserReducer,
  deleteUserReducer,
  getProfileUserReducer,
  updateProfileUserReducer,
  userLoginReducer,
  usersListReducer,
} from "./reducers/userReducers";
import {
  clientListReducer,
  createNewClientReducer,
  deleteClientReducer,
  detailClientReducer,
  updateClientReducer,
} from "./reducers/clientReducers";
import {
  addNewMarqueReducer,
  deleteMarqueReducer,
  marqueListReducer,
} from "./reducers/marqueReducers";
import {
  addNewModeleReducer,
  deleteModelReducer,
  modelListReducer,
} from "./reducers/modelReducers";
import {
  createNewEmployeReducer,
  deleteEmployeReducer,
  detailEmployeReducer,
  employesListReducer,
  updateEmployeReducer,
} from "./reducers/employeReducers";
import {
  agenceListReducer,
  createNewAgenceReducer,
  deleteAgenceReducer,
  getDetailAgenceReducer,
  updateAgenceReducer,
} from "./reducers/agenceReducers";
import {
  carListReducer,
  createNewCarReducer,
  deleteCarReducer,
  detailCarReducer,
  updateCarReducer,
} from "./reducers/carReducers";
import {
  createNewReservationReducer,
  deleteReservationReducer,
  detailReservationReducer,
  reservationListReducer,
  updateReservationReducer,
} from "./reducers/reservationReducers";
import {
  addContratPaymentReducer,
  addReturnContratReducer,
  backContratListReducer,
  contratClientListReducer,
  contratListReducer,
  contratPaymentListReducer,
  createNewContratReducer,
  deleteContratPaymentReducer,
  deleteContratReducer,
  detailContratReducer,
  facturesContratListReducer,
  getDetailContratPaymentReducer,
  searchContratListReducer,
  updateContratReducer,
  updateDetailContratPaymentReducer,
  validReturnContratReducer,
} from "./reducers/contratReducers";
import {
  chargeListReducer,
  createNewChargeReducer,
  createNewDepenseChargeReducer,
  createNewDepenseEmployeReducer,
  createNewDepenseEntretienReducer,
  createNewEntretienReducer,
  deleteChargeReducer,
  deleteDepenseChargeReducer,
  deleteDepenseEmployeReducer,
  deleteDepenseEntretienReducer,
  deleteEntretienReducer,
  depenseChargeListReducer,
  depenseEmployeListReducer,
  depenseEntretienListReducer,
  entretienListReducer,
  getDetailDepenseChargeReducer,
  getDetailDepenseEmployeReducer,
  getDetailDepenseEntretienReducer,
  updateChargeReducer,
  updateDepenseChargeReducer,
  updateDepenseEmployeReducer,
  updateDepenseEntretienReducer,
  updateEntretienReducer,
} from "./reducers/designationReducers";
import { getDashDataReducer } from "./reducers/dashReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  //
  clientList: clientListReducer,
  createNewClient: createNewClientReducer,
  detailClient: detailClientReducer,
  updateClient: updateClientReducer,
  deleteClient: deleteClientReducer,
  //
  marqueList: marqueListReducer,
  addNewMarque: addNewMarqueReducer,
  deleteMarque: deleteMarqueReducer,
  //
  modelList: modelListReducer,
  deleteModel: deleteModelReducer,
  addNewModele: addNewModeleReducer,
  //
  employesList: employesListReducer,
  createNewEmploye: createNewEmployeReducer,
  detailEmploye: detailEmployeReducer,
  updateEmploye: updateEmployeReducer,
  deleteEmploye: deleteEmployeReducer,
  //
  usersList: usersListReducer,
  createNewUser: createNewUserReducer,
  getProfileUser: getProfileUserReducer,
  updateProfileUser: updateProfileUserReducer,
  deleteUser: deleteUserReducer,
  //
  agenceList: agenceListReducer,
  createNewAgence: createNewAgenceReducer,
  getDetailAgence: getDetailAgenceReducer,
  updateAgence: updateAgenceReducer,
  deleteAgence: deleteAgenceReducer,
  //
  carList: carListReducer,
  createNewCar: createNewCarReducer,
  detailCar: detailCarReducer,
  updateCar: updateCarReducer,
  deleteCar: deleteCarReducer,
  //
  reservationList: reservationListReducer,
  createNewReservation: createNewReservationReducer,
  detailReservation: detailReservationReducer,
  updateReservation: updateReservationReducer,
  deleteReservation: deleteReservationReducer,
  //
  contratList: contratListReducer,
  createNewContrat: createNewContratReducer,
  detailContrat: detailContratReducer,
  updateContrat: updateContratReducer,
  contratClientList: contratClientListReducer,
  contratPaymentList: contratPaymentListReducer,
  addContratPayment: addContratPaymentReducer,
  getDetailContratPayment: getDetailContratPaymentReducer,
  updateDetailContratPayment: updateDetailContratPaymentReducer,
  deleteContratPayment: deleteContratPaymentReducer,
  addReturnContrat: addReturnContratReducer,
  backContratList: backContratListReducer,
  facturesContratList: facturesContratListReducer,
  validReturnContrat: validReturnContratReducer,
  deleteContrat: deleteContratReducer,
  //
  chargeList: chargeListReducer,
  createNewCharge: createNewChargeReducer,
  deleteCharge: deleteChargeReducer,
  updateCharge: updateChargeReducer,
  entretienList: entretienListReducer,
  deleteEntretien: deleteEntretienReducer,
  createNewEntretien: createNewEntretienReducer,
  updateEntretien: updateEntretienReducer,
  deleteDepenseEntretien: deleteDepenseEntretienReducer,
  //
  depenseChargeList: depenseChargeListReducer,
  createNewDepenseCharge: createNewDepenseChargeReducer,
  getDetailDepenseCharge: getDetailDepenseChargeReducer,
  updateDepenseCharge: updateDepenseChargeReducer,
  deleteDepenseCharge: deleteDepenseChargeReducer,
  //
  depenseEntretienList: depenseEntretienListReducer,
  createNewDepenseEntretien: createNewDepenseEntretienReducer,
  getDetailDepenseEntretien: getDetailDepenseEntretienReducer,
  updateDepenseEntretien: updateDepenseEntretienReducer,
  //
  depenseEmployeList: depenseEmployeListReducer,
  createNewDepenseEmploye: createNewDepenseEmployeReducer,
  getDetailDepenseEmploye: getDetailDepenseEmployeReducer,
  updateDepenseEmploye: updateDepenseEmployeReducer,
  deleteDepenseEmploye: deleteDepenseEmployeReducer,
  //
  getDashData: getDashDataReducer,
  searchContratList: searchContratListReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfoTayssir")
  ? JSON.parse(localStorage.getItem("userInfoTayssir"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
