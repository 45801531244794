export const RESERVATION_LIST_REQUEST = "RESERVATION_LIST_REQUEST";
export const RESERVATION_LIST_SUCCESS = "RESERVATION_LIST_SUCCESS";
export const RESERVATION_LIST_FAIL = "RESERVATION_LIST_FAIL";

export const RESERVATION_ADD_REQUEST = "RESERVATION_ADD_REQUEST";
export const RESERVATION_ADD_SUCCESS = "RESERVATION_ADD_SUCCESS";
export const RESERVATION_ADD_FAIL = "RESERVATION_ADD_FAIL";

export const RESERVATION_DETAIL_REQUEST = "RESERVATION_DETAIL_REQUEST";
export const RESERVATION_DETAIL_SUCCESS = "RESERVATION_DETAIL_SUCCESS";
export const RESERVATION_DETAIL_FAIL = "RESERVATION_DETAIL_FAIL";

export const RESERVATION_UPDATE_REQUEST = "RESERVATION_UPDATE_REQUEST";
export const RESERVATION_UPDATE_SUCCESS = "RESERVATION_UPDATE_SUCCESS";
export const RESERVATION_UPDATE_FAIL = "RESERVATION_UPDATE_FAIL";

export const RESERVATION_DELETE_REQUEST = "RESERVATION_DELETE_REQUEST";
export const RESERVATION_DELETE_SUCCESS = "RESERVATION_DELETE_SUCCESS";
export const RESERVATION_DELETE_FAIL = "RESERVATION_DELETE_FAIL";
