import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clientList, deleteClient } from "../../redux/actions/clientActions";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import Paginate from "../../components/Paginate";
import InputModel from "../../components/InputModel";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import ConfirmationModal from "../../components/ConfirmationModal";
import { baseURLFile } from "../../constants";

import sortAsc from "../../images/icon/sort-asc.png";
import sortDesc from "../../images/icon/sort-desc.png";

function ClientScreen() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gsmPhone, setGsmPhone] = useState("");
  const [cinNumber, setCinNunber] = useState("");
  const [permiNumber, setPermiNumber] = useState("");
  const [orderBy, setOrderBy] = useState("-created_at");

  const [isUpdate, setIsUpdate] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");
  const [clientId, setClientId] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listClient = useSelector((state) => state.clientList);
  const { clients, loading, error, pages } = listClient;

  const clientDelete = useSelector((state) => state.deleteClient);
  const { loadingClientDelete, errorClientDelete, successClientDelete } =
    clientDelete;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(
        clientList(
          page,
          code,
          firstName,
          lastName,
          encodeURIComponent(gsmPhone),
          cinNumber,
          permiNumber,
          orderBy
        )
      );
    }
  }, [
    navigate,
    userInfo,
    dispatch,
    page,
    code,
    firstName,
    lastName,
    gsmPhone,
    cinNumber,
    permiNumber,
    orderBy,
  ]);

  useEffect(() => {
    if (successClientDelete) {
      dispatch(
        clientList(
          page,
          code,
          firstName,
          lastName,
          encodeURIComponent(gsmPhone),
          cinNumber,
          permiNumber,
          orderBy
        )
      );
    }
  }, [successClientDelete]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Clients</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default   dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black  text-xs w-max">
              Gestion des clients
            </h4>
            <Link
              to={"/clients/add"}
              className="rounded bg-primary text-white px-5 py-1 flex flex-row text-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Ajouter
            </Link>
          </div>
          {/* search */}
          <div className="flex md:flex-row flex-col">
            <div className="md:w-1/3 w-full px-1 py-1 flex flex-row">
              <div className="md:py-2 md:flex mx-1 w-full">
                <InputModel
                  label="N°"
                  type="text"
                  placeholder=""
                  value={code}
                  onChange={(value) => {
                    setCode(value.target.value);
                  }}
                  error={""}
                />
              </div>
              {/*  */}
              <div className="md:py-2 md:flex mx-1 w-full">
                <InputModel
                  label="Nom"
                  type="text"
                  placeholder=""
                  value={firstName}
                  onChange={(value) => {
                    setFirstName(value.target.value);
                  }}
                  error={""}
                />
              </div>
            </div>
            {/*  */}
            <div className="md:w-1/3 w-full px-1 py-1 flex flex-row">
              <div className="md:py-2 md:flex mx-1 w-full">
                <InputModel
                  label="Prénom"
                  type="text"
                  placeholder=""
                  value={lastName}
                  onChange={(value) => {
                    setLastName(value.target.value);
                  }}
                  error={""}
                />
              </div>

              <div className="md:py-2 md:flex mx-1 w-full">
                <InputModel
                  label="GSM"
                  type="text"
                  placeholder=""
                  value={gsmPhone}
                  onChange={(value) => {
                    setGsmPhone(value.target.value);
                  }}
                  error={""}
                />
              </div>
            </div>
            {/*  */}
            <div className="md:w-1/3 w-full px-1 py-1 flex flex-row">
              <div className="md:py-2 md:flex mx-1 w-full">
                <InputModel
                  label="N° CIN"
                  type="text"
                  placeholder=""
                  value={cinNumber}
                  onChange={(value) => {
                    setCinNunber(value.target.value);
                  }}
                  error={""}
                />
              </div>
              <div className="md:py-2 md:flex mx-1 w-full">
                <InputModel
                  label="N° Permis"
                  type="text"
                  placeholder=""
                  value={permiNumber}
                  onChange={(value) => {
                    setPermiNumber(value.target.value);
                  }}
                  error={""}
                />
              </div>
            </div>
          </div>
          {/* list */}
          {loading ? (
            <Loader />
          ) : error ? (
            <Alert type="error" message={error} />
          ) : (
            <div className="max-w-full overflow-x-auto mt-3">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left  ">
                    <th className="min-w-[30px] py-4 px-4 font-bold text-black  text-xs w-max">
                      <div
                        onClick={() => {
                          if (!orderBy.includes("created_at")) {
                            setOrderBy("-created_at");
                          } else {
                            if (orderBy == "-created_at") {
                              setOrderBy("created_at");
                            } else {
                              setOrderBy("-created_at");
                            }
                          }
                        }}
                        className=" flex flex-row items-center cursor-pointer"
                      >
                        {orderBy.includes("created_at") ? (
                          orderBy.includes("-") ? (
                            <img className="size-5  mr-2" src={sortDesc} />
                          ) : (
                            <img className="size-5   mr-2" src={sortAsc} />
                          )
                        ) : null}
                        <span>N°</span>
                      </div>
                    </th>
                    <th className="min-w-[100px] py-4 px-4 font-bold text-black  text-xs w-max ">
                      <div
                        onClick={() => {
                          if (!orderBy.includes("first_name")) {
                            setOrderBy("-first_name");
                          } else {
                            if (orderBy == "-first_name") {
                              setOrderBy("first_name");
                            } else {
                              setOrderBy("-first_name");
                            }
                          }
                        }}
                        className=" flex flex-row items-center cursor-pointer"
                      >
                        {orderBy.includes("first_name") ? (
                          orderBy.includes("-") ? (
                            <img className="size-5  mr-2" src={sortDesc} />
                          ) : (
                            <img className="size-5   mr-2" src={sortAsc} />
                          )
                        ) : null}
                        <span>Nom</span>
                      </div>
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max  ">
                      <div
                        onClick={() => {
                          if (!orderBy.includes("last_name")) {
                            setOrderBy("-last_name");
                          } else {
                            if (orderBy == "-last_name") {
                              setOrderBy("last_name");
                            } else {
                              setOrderBy("-last_name");
                            }
                          }
                        }}
                        className=" flex flex-row items-center cursor-pointer"
                      >
                        {orderBy.includes("last_name") ? (
                          orderBy.includes("-") ? (
                            <img className="size-5  mr-2" src={sortDesc} />
                          ) : (
                            <img className="size-5   mr-2" src={sortAsc} />
                          )
                        ) : null}
                        <span>Prénom</span>
                      </div>
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max  ">
                      <div className=" flex flex-row items-center cursor-pointer">
                        <span>CIN</span>
                      </div>
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max ">
                      Permis
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max ">
                      Contrat
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max ">
                      GSM
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max ">
                      Email
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max ">
                      Né le
                    </th>
                    <th className="py-4 px-4 font-bold text-black  text-xs w-max">
                      Actions
                    </th>
                  </tr>
                </thead>
                {/*  */}
                <tbody>
                  {clients?.map((client, id) => (
                    <tr>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4    ">
                        <p className="text-black  text-xs w-max">{client.id}</p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {client.first_name ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {client.last_name ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {client.cin_number ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {client.permi_number ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">0</p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {client.gsm_phone ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {client.email ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {client.date_birth ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max flex flex-row">
                          {/* edit */}
                          <Link
                            className="mx-1 update-class"
                            to={"/clients/edit/" + client.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </Link>
                          {/* delete */}
                          <button
                            className="mx-1 delete-class"
                            onClick={() => {
                              setEventType("delete");
                              setClientId(client.id);
                              setIsUpdate(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                          {/* list contrat */}
                          <Link
                            className="mx-1 contrat-class"
                            to={"/clients/contrat/" + client.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-body rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                              />
                            </svg>
                          </Link>
                          {/* declaration */}
                          <Link
                            rel="noopener"
                            target="_blank"
                            className="mx-1 declaration-class"
                            to={baseURLFile + "/api/clients/4/declaration/"}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-body rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                              />
                            </svg>
                          </Link>
                        </p>
                      </td>
                    </tr>
                  ))}
                  <tr className="h-11"></tr>
                </tbody>
              </table>
              <div className="">
                <Paginate
                  route={"/clients?"}
                  search={""}
                  page={page}
                  pages={pages}
                />
              </div>
            </div>
          )}
        </div>
        {/* buttom dash */}
        <ConfirmationModal
          isOpen={isUpdate}
          message={
            eventType === "delete"
              ? "Êtes-vous sûr de vouloir supprimer ce client ?"
              : "Êtes-vous sûr de vouloir ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setIsUpdate(false);
              setEventType("");
              setLoadEvent(false);
            } else if (eventType === "delete" && clientId !== "") {
              setLoadEvent(true);
              dispatch(deleteClient(clientId));
              setIsUpdate(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setIsUpdate(false);
              setEventType("");
              setLoadEvent(false);
            }
          }}
          onCancel={() => {
            setIsUpdate(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default ClientScreen;
