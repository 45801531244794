import axios from "../../axios";
import {
  MODEL_LIST_REQUEST,
  MODEL_LIST_SUCCESS,
  MODEL_LIST_FAIL,
  //
  MODEL_DELETE_REQUEST,
  MODEL_DELETE_SUCCESS,
  MODEL_DELETE_FAIL,
  //
  MODEL_ADD_REQUEST,
  MODEL_ADD_SUCCESS,
  MODEL_ADD_FAIL,
  //
} from "../constants/modelContants";

// add new modele
export const addNewModele =
  (marqueCar, modeleCar) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MODEL_ADD_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.post(
        `/models/add/`,
        { marque_car: marqueCar, model_car: modeleCar },
        config
      );

      dispatch({
        type: MODEL_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: MODEL_ADD_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// delete model
export const deleteModele = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MODEL_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(`/models/${id}/delete/`, config);
    dispatch({
      type: MODEL_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: MODEL_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list client
export const getModelList = (marque) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MODEL_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/models/?marque=${marque}`, config);

    dispatch({
      type: MODEL_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: MODEL_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
