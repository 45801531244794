import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../../layouts/DefaultLayout";
import {
  getDetailDepenseEntretien,
  getListEntretiens,
  updateDepenseEntretien,
} from "../../../redux/actions/designationActions";
import LayoutSection from "../../../components/LayoutSection";
import InputModel from "../../../components/InputModel";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { toast } from "react-toastify";
import { getListCars } from "../../../redux/actions/carActions";

function EditDepenseEntretienScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [designationEntretien, setDesignationEntretien] = useState("");
  const [designationEntretienError, setDesignationEntretienError] =
    useState("");

  const [ItemsCharge, setItemCharge] = useState([]);
  const [selectItemsCharge, setSelectItemCharge] = useState([]);
  const [selectItemsChargeError, setSelectItemChargeError] = useState("");

  const [designationDate, setDesignationDate] = useState("");
  const [designationDateError, setDesignationDateError] = useState("");

  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState("");

  const [avanceType, setAvanceType] = useState("");
  const [avanceTypeError, setAvanceTypeError] = useState("");

  const [numberReglement, setNumberReglement] = useState("");
  const [numberReglementError, setNumberReglementError] = useState("");

  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const [carSelect, setCarSelect] = useState("");
  const [carSelectError, setCarSelectError] = useState("");

  const [eventType, setEventType] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listEntretien = useSelector((state) => state.entretienList);
  const { entretiens, loadingEntretien, errorEntretien, successEntretien } =
    listEntretien;

  const addDepenseEntretien = useSelector(
    (state) => state.createNewDepenseEntretien
  );

  const listCar = useSelector((state) => state.carList);
  const { cars } = listCar;

  const detailDepenseEntretien = useSelector(
    (state) => state.getDetailDepenseEntretien
  );
  const {
    loadingDepenseEntretienDetail,
    errorDepenseEntretienDetail,
    successDepenseEntretienDetail,
    depenseEntretien,
  } = detailDepenseEntretien;

  const depenseEntretienUpdate = useSelector(
    (state) => state.updateDepenseEntretien
  );
  const {
    loadingDepenseEntretienUpdate,
    errorDepenseEntretienUpdate,
    successDepenseEntretienUpdate,
  } = depenseEntretienUpdate;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListCars("0"));
      dispatch(getListEntretiens());
      dispatch(getDetailDepenseEntretien(id));
    }
  }, [navigate, userInfo]);

  useEffect(() => {
    if (depenseEntretien !== undefined && depenseEntretien !== null) {
      setCarSelect(depenseEntretien.car?.id);
      setDesignationEntretien(depenseEntretien.entretien?.id);

      setDesignationDate(depenseEntretien.date);

      setAmount(depenseEntretien.total_amount);

      setAvanceType(depenseEntretien.type_payment);

      setNumberReglement(depenseEntretien.number_reglement);

      setNote(depenseEntretien.note);
    }
  }, [depenseEntretien]);

  useEffect(() => {
    if (successDepenseEntretienUpdate) {
      setCarSelect("");
      setCarSelectError("");

      setDesignationEntretien("");
      setDesignationEntretienError("");

      setItemCharge([]);
      setSelectItemCharge([]);
      setSelectItemChargeError("");

      setDesignationDate("");
      setDesignationDateError("");

      setAmount(0);
      setAmountError("");

      setAvanceType("");
      setAvanceTypeError("");

      setNumberReglement("");
      setNumberReglementError("");

      setNote("");
      setNoteError("");
      dispatch(getDetailDepenseEntretien(id));
      dispatch(getListEntretiens());
      dispatch(getListCars("0"));

      setIsAdd(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successDepenseEntretienUpdate]);
  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/depenses/entretiens/">
            <div className="">Entretiens</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Modifié</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Modifié l'Entretien
            </h4>
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col ">
            <div className=" w-full px-1 py-1">
              <LayoutSection title="Informations de entretien">
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Voiture"
                    type="select"
                    placeholder="Voiture"
                    value={carSelect}
                    onChange={(v) => {
                      setCarSelect(v.target.value);
                    }}
                    error={carSelectError}
                    options={cars?.map((car) => ({
                      value: car.id,
                      label:
                        (car.marque.marque_car ?? "---") +
                        " - " +
                        car.matricule +
                        " " +
                        (car.agence ? " (" + car.agence.name + ") " : ""),
                    }))}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Type d'entretien"
                    type="select"
                    placeholder=""
                    value={designationEntretien}
                    onChange={(v) => setDesignationEntretien(v.target.value)}
                    error={designationEntretienError}
                    options={entretiens?.map((entretien) => ({
                      value: entretien.id,
                      label: entretien.entretien_name,
                    }))}
                  />
                  <InputModel
                    label="Sous Charge"
                    type="select"
                    ismultiple={true}
                    placeholder=""
                    disabled={designationEntretien === ""}
                    value={selectItemsCharge}
                    onChange={(v) => {
                      const selectedOptions = Array.from(
                        v.target.selectedOptions,
                        (option) => option.value
                      );
                      setSelectItemCharge(selectedOptions);
                    }}
                    error={selectItemsChargeError}
                    options={[]}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Montant"
                    type="number"
                    isPrice={true}
                    placeholder=""
                    value={amount}
                    onChange={(v) => setAmount(v.target.value)}
                    error={amountError}
                  />
                  <InputModel
                    label="date"
                    type="date"
                    placeholder=""
                    value={designationDate}
                    onChange={(v) => setDesignationDate(v.target.value)}
                    error={designationDateError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Type réglement"
                    type="select"
                    placeholder=""
                    value={avanceType}
                    onChange={(v) => setAvanceType(v.target.value)}
                    error={avanceTypeError}
                    options={[
                      { value: "Espece", label: "Espece" },
                      { value: "Cheque", label: "Cheque" },
                      { value: "Carte de credit", label: "Carte de credit" },
                      { value: "Virement", label: "Virement" },
                      {
                        value: "Paiement international",
                        label: "Paiement international",
                      },
                    ]}
                  />
                  <InputModel
                    label="Numéro réglement"
                    type="text"
                    placeholder=""
                    value={numberReglement}
                    onChange={(v) => setNumberReglement(v.target.value)}
                    error={numberReglementError}
                  />
                </div>
                <div className="md:py-2 md:flex">
                  <InputModel
                    label="Remarque"
                    type="textarea"
                    placeholder=""
                    value={note}
                    onChange={(v) => {
                      setNote(v.target.value);
                    }}
                    error={noteError}
                  />
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventType("cancel");
                setIsAdd(true);
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Annuler
            </button>
            <button
              onClick={async () => {
                var check = true;
                setCarSelectError("");
                setDesignationEntretienError("");
                setSelectItemChargeError("");
                setDesignationDateError("");
                setAmountError("");
                setAvanceTypeError("");
                setNumberReglementError("");
                setNoteError("");
                if (carSelect === "") {
                  setCarSelectError("Ce champ est requis.");
                  check = false;
                }
                if (designationEntretien === "") {
                  setDesignationEntretienError("Ce champ est requis.");
                  check = false;
                }
                if (designationDate === "") {
                  setDesignationDateError("Ce champ est requis.");
                  check = false;
                }
                if (amount === "" || amount === 0) {
                  setAmountError("Ce champ est requis.");
                  check = false;
                }
                if (avanceType === "") {
                  setAvanceTypeError("Ce champ est requis.");
                  check = false;
                }
                if (numberReglement === "") {
                  setNumberReglementError("Ce champ est requis.");
                  check = false;
                }

                if (check) {
                  setEventType("update");
                  setIsAdd(true);
                } else {
                  toast.error(
                    "Certains champs sont obligatoires veuillez vérifier"
                  );
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                />
              </svg>
              Modifié
            </button>
          </div>
        </div>
        <ConfirmationModal
          isOpen={isAdd}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Êtes-vous sûr de vouloir Modifé cette Entretien ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setCarSelect("");
              setCarSelectError("");

              setDesignationEntretien("");
              setDesignationEntretienError("");

              setItemCharge([]);
              setSelectItemCharge([]);
              setSelectItemChargeError("");

              setDesignationDate("");
              setDesignationDateError("");

              setAmount(0);
              setAmountError("");

              setAvanceType("");
              setAvanceTypeError("");

              setNumberReglement("");
              setNumberReglementError("");

              setNote("");
              setNoteError("");

              dispatch(getListCars("0"));
              dispatch(getListEntretiens());
              dispatch(getDetailDepenseEntretien(id));

              setIsAdd(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                updateDepenseEntretien(id, {
                  car: carSelect,
                  entretien: designationEntretien,
                  total_amount: amount,
                  date: designationDate,
                  type_payment: avanceType,
                  number_reglement: numberReglement,
                  note: note,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAdd(false);
            }
          }}
          onCancel={() => {
            setIsAdd(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />

        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default EditDepenseEntretienScreen;
