import axios from "../../axios";
import {
  EMPLOYE_LIST_REQUEST,
  EMPLOYE_LIST_SUCCESS,
  EMPLOYE_LIST_FAIL,
  //
  EMPLOYE_ADD_REQUEST,
  EMPLOYE_ADD_SUCCESS,
  EMPLOYE_ADD_FAIL,
  //
  EMPLOYE_DETAIL_REQUEST,
  EMPLOYE_DETAIL_SUCCESS,
  EMPLOYE_DETAIL_FAIL,
  //
  EMPLOYE_UPDATE_REQUEST,
  EMPLOYE_UPDATE_SUCCESS,
  EMPLOYE_UPDATE_FAIL,
  //
  EMPLOYE_DELETE_REQUEST,
  EMPLOYE_DELETE_SUCCESS,
  EMPLOYE_DELETE_FAIL,

  //
} from "../constants/employeConstants";

// delete employe
export const deleteEmploye = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EMPLOYE_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(`/employes/delete/${id}/`, config);

    dispatch({
      type: EMPLOYE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: EMPLOYE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// update employe
export const updateEmploye = (id, employe) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EMPLOYE_UPDATE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(
      `/employes/update/${id}/`,
      employe,
      config
    );

    dispatch({
      type: EMPLOYE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: EMPLOYE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// detail employe
export const detailEmploye = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EMPLOYE_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/employes/detail/${id}/`, config);

    dispatch({
      type: EMPLOYE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: EMPLOYE_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// add new employer
export const addNewEmploye = (employe) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EMPLOYE_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(`/employes/add/`, employe, config);

    dispatch({
      type: EMPLOYE_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: EMPLOYE_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list employes
export const getEmployesList = (status, page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EMPLOYE_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(
      `/employes/?status=${status}&page=${page}`,
      config
    );

    dispatch({
      type: EMPLOYE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: EMPLOYE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
