import axios from "../../axios";
import {
  AGENCE_LIST_REQUEST,
  AGENCE_LIST_SUCCESS,
  AGENCE_LIST_FAIL,
  //
  AGENCE_ADD_REQUEST,
  AGENCE_ADD_SUCCESS,
  AGENCE_ADD_FAIL,
  //
  AGENCE_DETAIL_REQUEST,
  AGENCE_DETAIL_SUCCESS,
  AGENCE_DETAIL_FAIL,
  //
  AGENCE_UPDATE_REQUEST,
  AGENCE_UPDATE_SUCCESS,
  AGENCE_UPDATE_FAIL,
  //
  AGENCE_DELETE_REQUEST,
  AGENCE_DELETE_SUCCESS,
  AGENCE_DELETE_FAIL,
  //
} from "../constants/agenceConstants";

// update detail employe
export const deleteAgence = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AGENCE_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(`/agences/delete/${id}/`, config);

    dispatch({
      type: AGENCE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: AGENCE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// update detail employe
export const updateAgence = (id, agence) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AGENCE_UPDATE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(`/agences/update/${id}/`, agence, config);

    dispatch({
      type: AGENCE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: AGENCE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get detail agence
export const getDetailAgence = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AGENCE_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    //
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    //
    const { data } = await axios.get(`/agences/detail/${id}/`, config);

    dispatch({
      type: AGENCE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: AGENCE_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// add new agence
export const addNewAgence = (agence) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AGENCE_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(`/agences/add/`, agence, config);

    dispatch({
      type: AGENCE_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: AGENCE_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list client
export const getListAgences = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AGENCE_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/agences/?page=${page}`, config);

    dispatch({
      type: AGENCE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: AGENCE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
