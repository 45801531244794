import axios from "../../axios";
import {
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_SUCCESS,
  CLIENT_LIST_FAIL,
  //
  CLIENT_ADD_REQUEST,
  CLIENT_ADD_SUCCESS,
  CLIENT_ADD_FAIL,
  //
  CLIENT_DETAIL_REQUEST,
  CLIENT_DETAIL_SUCCESS,
  CLIENT_DETAIL_FAIL,
  //
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_SUCCESS,
  CLIENT_UPDATE_FAIL,
  //
  CLIENT_DELETE_REQUEST,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_FAIL,
  //
} from "../constants/clientConstants";

// delete
export const deleteClient = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(`/clients/delete/${id}/`, config);

    dispatch({
      type: CLIENT_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CLIENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// update client
export const updateClient = (id, client) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_UPDATE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(`/clients/update/${id}/`, client, config);

    dispatch({
      type: CLIENT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CLIENT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// detail client
export const detailClient = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/clients/detail/${id}/`, config);

    dispatch({
      type: CLIENT_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CLIENT_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// add new client
export const addNewClient = (client) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(`/clients/add/`, client, config);

    dispatch({
      type: CLIENT_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CLIENT_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list client
export const clientList =
  (
    page,
    code,
    first_name,
    last_name,
    gsm_phone,
    cin_number,
    permi_number,
    orderby
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CLIENT_LIST_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/clients/?page=${page}&code=${code}&firstName=${first_name}&lastName=${last_name}&gsmPhone=${gsm_phone}&cinNumber=${cin_number}&permiNumber=${permi_number}&orderBy=${orderby}`,
        config
      );

      dispatch({
        type: CLIENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoTayssir");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CLIENT_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };
