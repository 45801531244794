import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import Paginate from "../../components/Paginate";
import {
  getFacturesListContrats,
  getListContrats,
} from "../../redux/actions/contratActions";
import { baseURLFile } from "../../constants";

function FactureScreen() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listContrat = useSelector((state) => state.facturesContratList);
  const { factureContrats, loadingFactureContrat, errorFactureContrat, pages } =
    listContrat;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getFacturesListContrats(page));
    }
  }, [navigate, userInfo, dispatch, page]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Factures</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default  dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black  text-xs w-max">
              Gestion des Factures
            </h4>
          </div>

          {/* list */}
          {loadingFactureContrat ? (
            <Loader />
          ) : errorFactureContrat ? (
            <Alert type="error" message={errorFactureContrat} />
          ) : (
            <div className="max-w-full overflow-x-auto mt-3">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left ">
                    <th className="min-w-[30px] py-4 px-4 font-bold text-black  text-xs w-max">
                      NF
                    </th>
                    <th className="min-w-[30px] py-4 px-4 font-bold text-black  text-xs w-max">
                      NC
                    </th>

                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Client
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Voiture
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Matricule
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Début
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Fin
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      NJ
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Prix/jour
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Montant
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Avance
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Reste
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Actions
                    </th>
                  </tr>
                </thead>
                {/*  */}
                <tbody>
                  {factureContrats?.map((contrat, id) => (
                    <tr>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {contrat.id}
                        </p>
                      </td>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {contrat.id}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.client?.first_name ?? "---"}{" "}
                          {contrat.client?.last_name ?? ""}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.is_withcar
                            ? (contrat.car?.marque.marque_car ?? "---") +
                                " " +
                                contrat.car?.model.model_car ?? ""
                            : (contrat.model_car ?? "---") + " (Sans voiture)"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.car?.matricule ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.start_date ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.end_date ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.nbr_day ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(contrat.price_day).toFixed(2) ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(contrat.price_total).toFixed(2) ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(contrat.price_avance).toFixed(2) ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(
                            parseFloat(contrat.price_total) -
                              parseFloat(contrat.price_avance)
                          ).toFixed(2) ?? "---"}
                        </p>
                      </td>

                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max flex flex-row">
                          {/* pdf */}
                          <Link
                            className="mx-1 imprimer-class"
                            rel="noopener"
                            target="_blank"
                            to={
                              baseURLFile +
                              "/api/contrats/print_pdf/" +
                              contrat.id +
                              "/"
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-warning rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                              />
                            </svg>
                          </Link>
                          {/* facture */}
                          <Link
                            className="mx-1 factures-class"
                            to={
                              baseURLFile +
                              "/api/contrats/facture_pdf/" +
                              contrat.id +
                              "/"
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
                              />
                            </svg>
                          </Link>
                        </p>
                      </td>
                    </tr>
                  ))}
                  <tr className="h-11"></tr>
                </tbody>
              </table>
              <div className="">
                <Paginate
                  route={"/factures?"}
                  search={""}
                  page={page}
                  pages={pages}
                />
              </div>
            </div>
          )}
        </div>
        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default FactureScreen;
