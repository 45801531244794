import { toast } from "react-toastify";
import {
  CAR_LIST_REQUEST,
  CAR_LIST_SUCCESS,
  CAR_LIST_FAIL,
  //
  CAR_ADD_REQUEST,
  CAR_ADD_SUCCESS,
  CAR_ADD_FAIL,
  //
  CAR_DETAIL_REQUEST,
  CAR_DETAIL_SUCCESS,
  CAR_DETAIL_FAIL,
  //
  CAR_UPDATE_REQUEST,
  CAR_UPDATE_SUCCESS,
  CAR_UPDATE_FAIL,
  //
  CAR_DELETE_REQUEST,
  CAR_DELETE_SUCCESS,
  CAR_DELETE_FAIL,
  //
} from "../constants/carConstants";

export const deleteCarReducer = (state = {}, action) => {
  switch (action.type) {
    case CAR_DELETE_REQUEST:
      return { loadingCarDelete: true };
    case CAR_DELETE_SUCCESS:
      toast.success("Cette voiture a été supprimer avec succès");
      return {
        loadingCarDelete: false,
        successCarDelete: true,
      };
    case CAR_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingCarDelete: false,
        successCarDelete: false,
        errorCarDelete: action.payload,
      };
    default:
      return state;
  }
};

export const updateCarReducer = (state = {}, action) => {
  switch (action.type) {
    case CAR_UPDATE_REQUEST:
      return { loadingCarUpdate: true };
    case CAR_UPDATE_SUCCESS:
      toast.success("Cette voiture a été mis à jour avec succès");
      return {
        loadingCarUpdate: false,
        successCarUpdate: true,
      };
    case CAR_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingCarUpdate: false,
        successCarUpdate: false,
        errorCarUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const detailCarReducer = (state = { car: {} }, action) => {
  switch (action.type) {
    case CAR_DETAIL_REQUEST:
      return { loading: true };
    case CAR_DETAIL_SUCCESS:
      return {
        loading: false,
        success: true,
        car: action.payload,
      };
    case CAR_DETAIL_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createNewCarReducer = (state = {}, action) => {
  switch (action.type) {
    case CAR_ADD_REQUEST:
      return { loadingCarAdd: true };
    case CAR_ADD_SUCCESS:
      toast.success("Cette Voiture a été ajouté avec succès");
      return {
        loadingCarAdd: false,
        successCarAdd: true,
      };
    case CAR_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingCarAdd: false,
        successCarAdd: false,
        errorCarAdd: action.payload,
      };
    default:
      return state;
  }
};

export const carListReducer = (state = { cars: [] }, action) => {
  switch (action.type) {
    case CAR_LIST_REQUEST:
      return { loading: true, cars: [] };
    case CAR_LIST_SUCCESS:
      return {
        loading: false,
        cars: action.payload.cars,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case CAR_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
