import axios from "../../axios";
import {
  MARQUE_LIST_REQUEST,
  MARQUE_LIST_SUCCESS,
  MARQUE_LIST_FAIL,
  //
  MARQUE_ADD_REQUEST,
  MARQUE_ADD_SUCCESS,
  MARQUE_ADD_FAIL,
  //
  MARQUE_DELETE_REQUEST,
  MARQUE_DELETE_SUCCESS,
  MARQUE_DELETE_FAIL,
  //
} from "../constants/marqueConstants";

// delete marque
export const deleteMarque = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MARQUE_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(`/marques/${id}/delete/`, config);
    dispatch({
      type: MARQUE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: MARQUE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// add new Marque
export const addNewMarque = (marqueCar) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MARQUE_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(
      `/marques/add/`,
      { marque_car: marqueCar },
      config
    );

    dispatch({
      type: MARQUE_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: MARQUE_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list client
export const getMarqueList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: MARQUE_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/marques/`, config);

    dispatch({
      type: MARQUE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoTayssir");
        document.location.href = "/";
      }
    }
    dispatch({
      type: MARQUE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
