import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { detailClient, updateClient } from "../../redux/actions/clientActions";
import CountrySelector from "../../components/Selector";
import { COUNTRIES, baseURLFile } from "../../constants";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import InputModel from "../../components/InputModel";
import ConfirmationModal from "../../components/ConfirmationModal";

const LayoutClientSection = (props) => {
  return (
    <div className=" border border-gray rounded-md rounded-t-xl shadow-2 my-2">
      <div className="p-2 rounded-t-xl bg-gray ">{props.title}</div>
      <div className="p-4 bg-white rounded-b-xl">{props.children}</div>
    </div>
  );
};

function EditClientScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();
  //
  const [firstName, setFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [dateNaissance, setDateNaissance] = useState("");
  const [errorDateNaissance, setErrorDateNaissance] = useState("");
  const [country, setCountry] = useState("MA");
  const [errorCountry, setErrorCountry] = useState("");
  const [address, setAddress] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [note, setNote] = useState("");
  const [errorNote, setErrorNote] = useState("");
  const [cinNumber, setCinNumber] = useState("");
  const [errorCinNumber, setErrorCinNumber] = useState("");
  const [cinValidate, setCinValidate] = useState("");
  const [errorCinValidate, setErrorCinValidate] = useState("");
  const [cinRecto, setCinRecto] = useState("");
  const [errorCinRecto, setErrorCinRecto] = useState("");
  const [cinVerso, setCinVerso] = useState("");

  const [errorCinVerso, setErrorCinVerso] = useState("");
  const [permiNumber, setPermiNumber] = useState("");
  const [errorPermiNumber, setErrorPermiNumber] = useState("");
  const [permiValidate, setPermiValidate] = useState("");
  const [errorPermiValidate, setErrorPermiValidate] = useState("");
  const [permiRecto, setPermiRecto] = useState("");
  const [errorPermiRecto, setErrorPermiRecto] = useState("");
  const [permiVerso, setPermiVerso] = useState("");
  const [errorPermiVerso, setErrorPermiVerso] = useState("");
  const [permiRectoImage, setPermiRectoImage] = useState("");
  const [permiVersoImage, setPermiVersoImage] = useState("");
  const [cinRectoImage, setCinRectoImage] = useState("");
  const [cinVersoImage, setCinVersoImage] = useState("");

  const [isUpdate, setIsUpdate] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const clientDetail = useSelector((state) => state.detailClient);
  const { loading, error, success, client } = clientDetail;

  const clientUpdate = useSelector((state) => state.updateClient);
  const { loadingClientUpdate, errorClientUpdate, successClientUpdate } =
    clientUpdate;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(detailClient(id));
    }
  }, [navigate, userInfo, dispatch, id]);

  useEffect(() => {
    if (client !== undefined && client !== null) {
      setFirstName(client.first_name === null ? "" : client.first_name);
      setLastName(client.last_name === null ? "" : client.last_name);
      setDateNaissance(client.date_birth === null ? "" : client.date_birth);

      setCountry("MM");
      setCountry(client.country);
      console.log(country);
      setAddress(client.address === null ? "" : client.address);
      setEmail(client.email === null ? "" : client.email);
      setPhone(client.phone === null ? "" : client.phone);
      setNote(client.note === null ? "" : client.note);
      setCinNumber(client.cin_number === null ? "" : client.cin_number);
      setCinValidate(client.cin_validate === null ? "" : client.cin_validate);
      if (client.cin_recto) {
        setCinRectoImage(client.cin_recto);
      }
      if (client.cin_verso) {
        setCinVersoImage(client.cin_verso);
      }
      setPermiNumber(client.permi_number === null ? "" : client.permi_number);
      setPermiValidate(
        client.permi_validate === null ? "" : client.permi_validate
      );
      if (client.permi_recto !== null) {
        setPermiRectoImage(client.permi_recto);
      }
      if (client.permi_verso !== null) {
        setPermiVersoImage(client.permi_verso);
      }
    }
  }, [client]);

  useEffect(() => {
    if (successClientUpdate) {
      dispatch(detailClient(id));
    }
  }, [successClientUpdate]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/clients/">
            <div className="">Clients</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Modification</div>
        </div>

        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Ajouter un nouveau client
            </h4>
          </div>
          {/*  */}

          {loading ? (
            <Loader />
          ) : error ? (
            <Alert type="error" message={error} />
          ) : client ? (
            <>
              <div className="flex md:flex-row flex-col ">
                <div className="md:w-1/2 w-full px-1 py-1">
                  <LayoutClientSection title="Informations personnelles">
                    {/* fisrt name & last name */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Nom"
                        type="text"
                        placeholder=""
                        value={firstName}
                        onChange={(v) => setFirstName(v.target.value)}
                        error={errorFirstName}
                      />

                      <InputModel
                        label="Prénom"
                        type="text"
                        placeholder=""
                        value={lastName}
                        onChange={(v) => setLastName(v.target.value)}
                        error={errorLastName}
                      />
                    </div>
                    {/* date and nation */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Date de naissance"
                        type="date"
                        placeholder=""
                        value={dateNaissance}
                        onChange={(v) => {
                          if (v.target.value !== "") {
                            const parsedDate = new Date(v.target.value);
                            setDateNaissance(
                              parsedDate.toISOString().split("T")[0]
                            );
                          }
                        }}
                        error={errorDateNaissance}
                      />

                      <InputModel
                        label="Pays"
                        type="select"
                        placeholder=""
                        value={country}
                        onChange={(v) => {
                          setCountry(v.target.value);
                        }}
                        error={errorCountry}
                        options={COUNTRIES?.map((country) => ({
                          value: country.value,
                          label: country.title,
                        }))}
                      />
                    </div>
                    {/* address */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Adressse"
                        type="textarea"
                        placeholder=""
                        value={address}
                        onChange={(v) => setAddress(v.target.value)}
                        error={errorAddress}
                      />
                    </div>
                    {/* gsm and mail */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Numéro de téléphone"
                        type="text"
                        placeholder=""
                        value={phone}
                        onChange={(v) => setPhone(v.target.value)}
                        error={errorPhone}
                      />
                      <InputModel
                        label="Email"
                        type="email"
                        placeholder=""
                        value={email}
                        onChange={(v) => setEmail(v.target.value)}
                        error={errorEmail}
                      />
                    </div>

                    {/* remarque */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Remarque"
                        type="textarea"
                        placeholder=""
                        value={note}
                        onChange={(v) => setNote(v.target.value)}
                        error={errorNote}
                      />
                    </div>
                  </LayoutClientSection>
                </div>
                <div className="md:w-1/2 w-full px-1 py-1">
                  <LayoutClientSection title="Pièces d'identité">
                    <div className="mt-2 mb-2">
                      <label>CIN Information</label>
                      <hr />
                    </div>

                    {/* cin and date */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="CIN"
                        type="text"
                        placeholder=""
                        value={cinNumber}
                        onChange={(v) => setCinNumber(v.target.value)}
                        error={errorCinNumber}
                      />
                      <InputModel
                        label="Date Validé"
                        type="date"
                        placeholder=""
                        value={cinValidate}
                        onChange={(v) => {
                          if (v.target.value !== "") {
                            const parsedDate = new Date(v.target.value);
                            setCinValidate(
                              parsedDate.toISOString().split("T")[0]
                            );
                          }
                        }}
                        error={errorCinValidate}
                      />
                    </div>
                    {/* recto and verso */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Photo de face"
                        type="file"
                        placeholder=""
                        onChange={(v) => setCinRecto(v.target.files[0])}
                        error={errorCinRecto}
                      />
                      <InputModel
                        label="Photo de fond"
                        type="file"
                        placeholder=""
                        onChange={(v) => setCinVerso(v.target.files[0])}
                        error={errorCinVerso}
                      />
                    </div>

                    {/*  */}
                    <div className="mt-2 mb-2">
                      <label>PERMI de Conduire</label>
                      <hr />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="PERMIS"
                        type="text"
                        placeholder=""
                        value={permiNumber}
                        onChange={(v) => setPermiNumber(v.target.value)}
                        error={errorPermiNumber}
                      />
                      <InputModel
                        label="Date Validé"
                        type="date"
                        placeholder=""
                        value={permiValidate}
                        onChange={(v) => {
                          if (v.target.value !== "") {
                            const parsedDate = new Date(v.target.value);
                            setPermiValidate(
                              parsedDate.toISOString().split("T")[0]
                            );
                          }
                        }}
                        error={errorPermiValidate}
                      />
                    </div>
                    {/* recto and verso */}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Photo de face"
                        type="file"
                        placeholder=""
                        onChange={(v) => setPermiRecto(v.target.files[0])}
                        error={errorPermiRecto}
                      />
                      <InputModel
                        label="Photo de fond"
                        type="file"
                        placeholder=""
                        onChange={(v) => setPermiVerso(v.target.files[0])}
                        error={errorPermiVerso}
                      />
                    </div>
                  </LayoutClientSection>
                </div>
              </div>
              <div className="my-2 flex flex-row items-center justify-end">
                <button
                  onClick={() => {
                    setEventType("cancel");
                    setIsUpdate(true);
                  }}
                  className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
                >
                  Annuler
                </button>
                <button
                  onClick={async () => {
                    var check = true;
                    setErrorFirstName("");
                    setErrorLastName("");
                    setErrorDateNaissance("");
                    setErrorCountry("");
                    setErrorAddress("");

                    setErrorEmail("");
                    setErrorPhone("");

                    setErrorNote("");
                    setErrorCinNumber("");
                    setErrorCinValidate("");
                    setErrorCinRecto("");
                    setErrorCinVerso("");
                    setErrorPermiNumber("");
                    setErrorPermiValidate("");
                    setErrorPermiRecto("");
                    setErrorPermiVerso("");
                    if (firstName === "") {
                      setErrorFirstName("Ce champ est requis.");
                      check = false;
                    }
                    if (lastName === "") {
                      setErrorLastName("Ce champ est requis.");
                      check = false;
                    }
                    if (dateNaissance === "") {
                      setErrorDateNaissance("Ce champ est requis.");
                      check = false;
                    }
                    if (country === "") {
                      setErrorCountry("Ce champ est requis.");
                      check = false;
                    }
                    if (phone === "") {
                      setErrorPhone("Ce champ est requis.");
                      check = false;
                    }

                    if (check) {
                      setEventType("add");
                      setIsUpdate(true);
                    } else {
                      toast.error(
                        "Certains champs sont obligatoires veuillez vérifier"
                      );
                    }
                  }}
                  className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                    />
                  </svg>
                  Modifié
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <ConfirmationModal
          isOpen={isUpdate}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Êtes-vous sûr de vouloir ajouter ce Client ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setErrorFirstName("");
              setErrorLastName("");
              setErrorDateNaissance("");
              setErrorCountry("");
              setErrorAddress("");

              setErrorEmail("");
              setErrorPhone("");

              setErrorNote("");
              setErrorCinNumber("");
              setErrorCinValidate("");
              setErrorCinRecto("");
              setErrorCinVerso("");
              setErrorPermiNumber("");
              setErrorPermiValidate("");
              setErrorPermiRecto("");
              setErrorPermiVerso("");
              dispatch(detailClient(id));

              setIsUpdate(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);

              await dispatch(
                updateClient(id, {
                  first_name: firstName,
                  last_name: lastName,
                  date_birth: dateNaissance,
                  country: country,
                  address: address,
                  phone: phone,
                  email: email,
                  note: note,
                  cin_number: cinNumber,
                  cin_validate: cinValidate,
                  cin_recto: cinRecto,
                  cin_verso: cinVerso,
                  permi_number: permiNumber,
                  permi_validate: permiValidate,
                  permi_recto: permiRecto,
                  permi_verso: permiVerso,
                })
              ).then(() => {});
              setLoadEvent(false);

              setIsUpdate(false);
              setEventType("");
              setLoadEvent(false);
            }
          }}
          onCancel={() => {
            setIsUpdate(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />

        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default EditClientScreen;
