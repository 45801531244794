import { toast } from "react-toastify";
import {
  CONTRAT_LIST_REQUEST,
  CONTRAT_LIST_SUCCESS,
  CONTRAT_LIST_FAIL,
  //
  CONTRAT_ADD_REQUEST,
  CONTRAT_ADD_SUCCESS,
  CONTRAT_ADD_FAIL,
  //
  CONTRAT_DETAIL_REQUEST,
  CONTRAT_DETAIL_SUCCESS,
  CONTRAT_DETAIL_FAIL,
  //
  CONTRAT_UPDATE_REQUEST,
  CONTRAT_UPDATE_SUCCESS,
  CONTRAT_UPDATE_FAIL,
  //
  CONTRAT_CLIENT_LIST_REQUEST,
  CONTRAT_CLIENT_LIST_SUCCESS,
  CONTRAT_CLIENT_LIST_FAIL,
  //
  CONTRAT_PAYMENT_LIST_REQUEST,
  CONTRAT_PAYMENT_LIST_SUCCESS,
  CONTRAT_PAYMENT_LIST_FAIL,
  //
  CONTRAT_PAYMENT_ADD_REQUEST,
  CONTRAT_PAYMENT_ADD_SUCCESS,
  CONTRAT_PAYMENT_ADD_FAIL,
  //
  CONTRAT_PAYMENT_DETAIL_REQUEST,
  CONTRAT_PAYMENT_DETAIL_SUCCESS,
  CONTRAT_PAYMENT_DETAIL_FAIL,
  //
  CONTRAT_PAYMENT_UPDATE_REQUEST,
  CONTRAT_PAYMENT_UPDATE_SUCCESS,
  CONTRAT_PAYMENT_UPDATE_FAIL,
  //
  CONTRAT_PAYMENT_DELETE_REQUEST,
  CONTRAT_PAYMENT_DELETE_SUCCESS,
  CONTRAT_PAYMENT_DELETE_FAIL,
  //
  CONTRAT_RETURN_ADD_REQUEST,
  CONTRAT_RETURN_ADD_SUCCESS,
  CONTRAT_RETURN_ADD_FAIL,
  //
  CONTRAT_BACK_LIST_REQUEST,
  CONTRAT_BACK_LIST_SUCCESS,
  CONTRAT_BACK_LIST_FAIL,
  //
  CONTRAT_FACTURES_LIST_REQUEST,
  CONTRAT_FACTURES_LIST_SUCCESS,
  CONTRAT_FACTURES_LIST_FAIL,
  //
  SEARCH_CONTRAT_LIST_REQUEST,
  SEARCH_CONTRAT_LIST_SUCCESS,
  SEARCH_CONTRAT_LIST_FAIL,
  //
  CONTRAT_RETURN_VALID_REQUEST,
  CONTRAT_RETURN_VALID_SUCCESS,
  CONTRAT_RETURN_VALID_FAIL,
  //
  CONTRAT_DELETE_REQUEST,
  CONTRAT_DELETE_SUCCESS,
  CONTRAT_DELETE_FAIL,
  //
} from "../constants/contratConstant";

export const deleteContratReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTRAT_DELETE_REQUEST:
      return { loadingContratDelete: true };
    case CONTRAT_DELETE_SUCCESS:
      toast.success("Cette Contrat a été supprimer avec succès");
      return {
        loadingContratDelete: false,
        successContratDelete: true,
      };
    case CONTRAT_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingContratDelete: false,
        successContratDelete: false,
        errorContratDelete: action.payload,
      };
    default:
      return state;
  }
};

export const validReturnContratReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTRAT_RETURN_VALID_REQUEST:
      return { loadingContratValidReturn: true };
    case CONTRAT_RETURN_VALID_SUCCESS:
      toast.success("Cette Retour a été validé avec succès");
      return {
        loadingContratValidReturn: false,
        successContratValidReturn: true,
      };
    case CONTRAT_RETURN_VALID_FAIL:
      toast.error(action.payload);
      return {
        loadingContratValidReturn: false,
        errorContratValidReturn: action.payload,
        successContratValidReturn: false,
      };
    default:
      return state;
  }
};

export const searchContratListReducer = (
  state = { searchContrats: [] },
  action
) => {
  switch (action.type) {
    case SEARCH_CONTRAT_LIST_REQUEST:
      return { loadingSearchContrat: true, searchContrats: [] };
    case SEARCH_CONTRAT_LIST_SUCCESS:
      return {
        loadingSearchContrat: false,
        searchContrats: action.payload.contrats,
      };
    case SEARCH_CONTRAT_LIST_FAIL:
      return {
        loadingSearchContrat: false,
        errorSearchContrat: action.payload,
      };
    default:
      return state;
  }
};

export const facturesContratListReducer = (
  state = { factureContrats: [] },
  action
) => {
  switch (action.type) {
    case CONTRAT_FACTURES_LIST_REQUEST:
      return { loadingFactureContrat: true, factureContrats: [] };
    case CONTRAT_FACTURES_LIST_SUCCESS:
      return {
        loadingFactureContrat: false,
        factureContrats: action.payload.contrats,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case CONTRAT_FACTURES_LIST_FAIL:
      return {
        loadingFactureContrat: false,
        errorFactureContrat: action.payload,
      };
    default:
      return state;
  }
};

export const backContratListReducer = (
  state = { backContrats: [] },
  action
) => {
  switch (action.type) {
    case CONTRAT_BACK_LIST_REQUEST:
      return { loadingBackContrat: true, backContrats: [] };
    case CONTRAT_BACK_LIST_SUCCESS:
      return {
        loadingBackContrat: false,
        backContrats: action.payload.contrats,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case CONTRAT_BACK_LIST_FAIL:
      return { loadingBackContrat: false, errorBackContrat: action.payload };
    default:
      return state;
  }
};

export const addReturnContratReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTRAT_RETURN_ADD_REQUEST:
      return { loadingContratAddReturn: true };
    case CONTRAT_RETURN_ADD_SUCCESS:
      toast.success("Cette Retour a été ajouter avec succès");
      return {
        loadingContratAddReturn: false,
        successContratAddReturn: true,
      };
    case CONTRAT_RETURN_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingContratAddReturn: false,
        errorContratAddReturn: action.payload,
        successContratAddReturn: false,
      };
    default:
      return state;
  }
};

export const deleteContratPaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTRAT_PAYMENT_DELETE_REQUEST:
      return { loadingContratPaymentDelete: true };
    case CONTRAT_PAYMENT_DELETE_SUCCESS:
      toast.success("Cette Paiement a été supprimer avec succès");
      return {
        loadingContratPaymentDelete: false,
        successContratPaymentDelete: true,
      };
    case CONTRAT_PAYMENT_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingContratPaymentDelete: false,
        errorContratPaymentDelete: action.payload,
        successContratPaymentDelete: false,
      };
    default:
      return state;
  }
};

export const updateDetailContratPaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTRAT_PAYMENT_UPDATE_REQUEST:
      return { loadingContratPaymentUpdate: true };
    case CONTRAT_PAYMENT_UPDATE_SUCCESS:
      toast.success("Cette Paiement a été mis à jour avec succès");
      return {
        loadingContratPaymentUpdate: false,
        successContratPaymentUpdate: true,
      };
    case CONTRAT_PAYMENT_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingContratPaymentUpdate: false,
        errorContratPaymentUpdate: action.payload,
        successContratPaymentUpdate: false,
      };
    default:
      return state;
  }
};

export const getDetailContratPaymentReducer = (
  state = { paymentDetail: {} },
  action
) => {
  switch (action.type) {
    case CONTRAT_PAYMENT_DETAIL_REQUEST:
      return { loadingContratPaymentDetail: true };
    case CONTRAT_PAYMENT_DETAIL_SUCCESS:
      return {
        loadingContratPaymentDetail: false,
        paymentDetail: action.payload,
        successContratPaymentDetail: true,
      };
    case CONTRAT_PAYMENT_DETAIL_FAIL:
      return {
        loadingContratPaymentDetail: false,
        errorContratPaymentDetail: action.payload,
        successContratPaymentDetail: false,
      };
    default:
      return state;
  }
};

export const addContratPaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTRAT_PAYMENT_ADD_REQUEST:
      return { loadingContratPaymentAdd: true };
    case CONTRAT_PAYMENT_ADD_SUCCESS:
      toast.success("Cette Paiement a été ajouter avec succès");
      return {
        loadingContratPaymentAdd: false,
        successContratPaymentAdd: true,
      };
    case CONTRAT_PAYMENT_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingContratPaymentAdd: false,
        errorContratPaymentAdd: action.payload,
        successContratPaymentAdd: false,
      };
    default:
      return state;
  }
};

export const contratPaymentListReducer = (
  state = { contratPayments: [] },
  action
) => {
  switch (action.type) {
    case CONTRAT_PAYMENT_LIST_REQUEST:
      return { loadingContratPayment: true, contratPayments: [] };
    case CONTRAT_PAYMENT_LIST_SUCCESS:
      return {
        loadingContratPayment: false,
        contratPayments: action.payload.payments,
        successContratPayment: true,
      };
    case CONTRAT_PAYMENT_LIST_FAIL:
      return {
        loadingContratPayment: false,
        errorContratPayment: action.payload,
        successContratPayment: false,
      };
    default:
      return state;
  }
};

export const contratClientListReducer = (
  state = { contratClients: [] },
  action
) => {
  switch (action.type) {
    case CONTRAT_CLIENT_LIST_REQUEST:
      return { loadingContratClient: true, contratClients: [] };
    case CONTRAT_CLIENT_LIST_SUCCESS:
      return {
        loadingContratClient: false,
        contratClients: action.payload.contrats,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case CONTRAT_CLIENT_LIST_FAIL:
      return {
        loadingContratClient: false,
        errorContratClient: action.payload,
      };
    default:
      return state;
  }
};

export const updateContratReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTRAT_UPDATE_REQUEST:
      return { loadingContratUpdate: true };
    case CONTRAT_UPDATE_SUCCESS:
      toast.success("Cette Contrat a été mis à jour avec succès");
      return {
        loadingContratUpdate: false,
        successContratUpdate: true,
      };
    case CONTRAT_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingContratUpdate: false,
        successContratUpdate: false,
        errorContratUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const detailContratReducer = (state = { contrat: {} }, action) => {
  switch (action.type) {
    case CONTRAT_DETAIL_REQUEST:
      return { loading: true };
    case CONTRAT_DETAIL_SUCCESS:
      return {
        loading: false,
        success: true,
        contrat: action.payload,
      };
    case CONTRAT_DETAIL_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createNewContratReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTRAT_ADD_REQUEST:
      return { loadingContratAdd: true };
    case CONTRAT_ADD_SUCCESS:
      toast.success("Cette Contrat a été ajouté avec succès");
      return {
        loadingContratAdd: false,
        successContratAdd: true,
      };
    case CONTRAT_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingContratAdd: false,
        successContratAdd: false,
        errorContratAdd: action.payload,
      };
    default:
      return state;
  }
};

export const contratListReducer = (state = { contrats: [] }, action) => {
  switch (action.type) {
    case CONTRAT_LIST_REQUEST:
      return { loading: true, contrats: [] };
    case CONTRAT_LIST_SUCCESS:
      return {
        loading: false,
        contrats: action.payload.contrats,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case CONTRAT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
