import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import Paginate from "../../components/Paginate";
import {
  getListContratClients,
  getListContrats,
} from "../../redux/actions/contratActions";
import { baseURLFile } from "../../constants";

function ContratClientScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const page = location.search.split("&")[1]
    ? location.search.split("&")[1].split("=")[1]
    : 1;

  const { id } = useParams();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listContratClient = useSelector((state) => state.contratClientList);
  const { loadingContratClient, contratClients, errorContratClient, pages } =
    listContratClient;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListContratClients(id, page));
    }
  }, [navigate, userInfo, dispatch, page, id]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Contrat</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default  dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black  text-xs w-max">
              Liste des contrats de client{" "}
              {contratClients?.lenght !== 0 &&
              contratClients[0]?.client?.first_name !== undefined
                ? contratClients[0]?.client?.first_name +
                  " " +
                  contratClients[0]?.client?.last_name
                : ""}
            </h4>
          </div>

          {/* list */}
          {loadingContratClient ? (
            <Loader />
          ) : errorContratClient ? (
            <Alert type="error" message={errorContratClient} />
          ) : (
            <div className="max-w-full overflow-x-auto mt-3">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left ">
                    <th className="min-w-[30px] py-4 px-4 font-bold text-black  text-xs w-max">
                      NC
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Client
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Voiture
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Matricule
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Début
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Fin
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      NJ
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Prix/jour
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Montant
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Avance
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Reste
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Actions
                    </th>
                  </tr>
                </thead>
                {/*  */}
                <tbody>
                  {contratClients?.map((contrat, id) => (
                    <tr>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {contrat.id}
                        </p>
                      </td>

                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.client.first_name ?? "---"}{" "}
                          {contrat.client.last_name ?? ""}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.car?.marque?.marque_car ?? "---"}{" "}
                          {contrat.car?.model?.model_car ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.car?.matricule ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.start_date ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.end_date ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {contrat.nbr_day ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(contrat.price_day).toFixed(2) ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(contrat.price_total).toFixed(2) ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(contrat.price_avance).toFixed(2) ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max">
                          {parseFloat(contrat.price_rest).toFixed(2) ?? "---"}
                        </p>
                      </td>

                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max flex flex-row">
                          {/* edit */}
                          <Link
                            className="mx-1 update-class"
                            to={"/contrats/edit/" + contrat.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </Link>
                          {/* pdf */}
                          <Link
                            className="mx-1 imprimer-class"
                            rel="noopener"
                            target="_blank"
                            to={
                              baseURLFile +
                              "/api/contrats/print_pdf/" +
                              contrat.id +
                              "/"
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-warning rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                              />
                            </svg>
                          </Link>
                          {/* payment */}
                          <Link
                            className="mx-1 paiement-class"
                            to={"/contrats/payments/" + contrat.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                              />
                            </svg>
                          </Link>
                        </p>
                      </td>
                    </tr>
                  ))}
                  <tr className="h-11"></tr>
                </tbody>
              </table>
              <div className="">
                <Paginate
                  route={"/contrats?"}
                  search={""}
                  page={page}
                  pages={pages}
                />
              </div>
            </div>
          )}
        </div>
        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default ContratClientScreen;
